const reportCoverPageEnum = Object.freeze({
  COMPANY: {
    LABEL: 'Company',
    NAME: 'company',
  },
  LOCATION: {
    LABEL: 'Location',
    NAME: 'site_id',
  },
  REPORT_NAME: {
    LABEL: 'Report Name',
    NAME: 'report_name',
  },
  CREATED_ON: {
    LABEL: 'Created On',
    NAME: 'created_on',
  },
  CREATED_BY: {
    LABEL: 'Created By',
    NAME: 'created_by',
  },
  PERFORMED_BY: {
    LABEL: 'Performed By',
    NAME: 'performed_by',
  },
  PERFORMED_ON: {
    LABEL: 'Performed On',
    NAME: 'performed_on',
  },
  DESCRIPTION: {
    LABEL: 'Description',
    NAME: 'description',
  },
});

export default reportCoverPageEnum;
