import React from 'react';
import { DataTable, SectionDiv, Typography } from '@fluke/predator-report-lib/lib/components';
import { reportSectionKeys } from '../../enums';

export default function InfoTable({ info = [] }) {
  const config = {
    header: [],
    rows: info,
  };

  return (
    <SectionDiv>
      <Typography variant="section">{reportSectionKeys.DETAILS}</Typography>
      <DataTable data={config} />
    </SectionDiv>
  );
}
