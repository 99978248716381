import React from 'react';
import { Typography } from '@common-ui/ui';
import { useAppDispatch } from '../../../store/configureStore';
import { clearSelectedImages } from '../../../store/slices/imagesSlice';
import styled from '@emotion/styled';
import { useSelector } from 'react-redux';

/**
 * Clear the selected images
 *
 * @param {None} None
 * @returns {JSX.Element}
 */
export default function Clear() {
  const dispatch = useAppDispatch();
  const { selectedImageIds } = useSelector((state) => state.images);

  const handleClick = () => {
    dispatch(clearSelectedImages());
  };

  const Text = styled(Typography)`
    color: white;
    z-index: 100;
    position: absolute;
    right: 4em;
    top: 9em;
    &:hover {
      cursor: pointer;
      color: ${({ theme }) => theme.palette.secondary.light};
      transition: 0.3s;
    }
  `;

  return selectedImageIds.length !== 0 && <Text onClick={handleClick}>Clear Selection</Text>;
}
