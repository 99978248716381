import React, { useState } from 'react';
// mui -->
import { Typography, Select, MenuItem, FormControl, InputLabel } from '@common-ui/ui';

/**
 * This component is used to display the filter
 * @returns {JSX.Element}
 */
export default function Filter({ onChange = () => {} }) {
  // statuses is a placeholder for the statuses not yet defined
  const statuses = [
    {
      value: true,
      label: 'Completed',
    },
    {
      value: false,
      label: 'In Progress',
    },
  ];

  // status state will be used to define the dropdown value
  const [status, setStatus] = useState('');

  // handleChange will be used to update the status state
  const handleChange = (event) => {
    setStatus(event.target.value);
    onChange(event.target.value);
  };

  return (
    <div style={styles.container}>
      <div>
        <Typography variant="body1">Filter:</Typography>
      </div>
      <FormControl sx={styles.form} size="small">
        <InputLabel id="asset-status-label">Status</InputLabel>
        <Select
          labelId="asset-status-label"
          id="asset-status"
          value={status}
          label="Status"
          onChange={handleChange}
        >
          {statuses.map((option) => (
            <MenuItem key={option.value} value={option.value} onChange={handleChange}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
  form: { width: 200 },
};
