class TrieNode {
  constructor() {
    this.children = new Map();
    this.isEndOfWord = false;
  }
}

export default class MultiWayTrie {
  constructor() {
    this.root = new TrieNode();
  }

  insert(word) {
    let node = this.root;
    // Traverse the trie based on the characters of the word
    for (let char of word) {
      if (!node.children.has(char)) {
        // If the current node doesn't have a child with the current character, create one
        node.children.set(char, new TrieNode());
      }
      // Move to the child node corresponding to the current character
      node = node.children.get(char);
    }
    node.isEndOfWord = true;
  }

  search(prefix) {
    let node = this.root;
    // Traverse the trie based on the characters of the prefix
    for (let char of prefix) {
      // If the current node doesn't have a child with the current character, return an empty array
      if (!node.children.has(char)) {
        return [];
      }
      // Move to the child node corresponding to the current character
      node = node.children.get(char);
    }
    return this.findAllWords(node, prefix);
  }

  findAllWords(node, prefix) {
    let results = [];
    if (node.isEndOfWord) {
      results.push(prefix);
    }
    // Recursively find all words starting from the current node
    for (let [char, childNode] of node.children) {
      results = results.concat(this.findAllWords(childNode, prefix + char));
    }
    return results;
  }
}
