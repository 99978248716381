import { getDateTimeFromMillisecondsTZ } from '../../../../utils/getDateFormat';

/**
 * This function takes an imageData object and unpacks it to a simpler format.
 * The imageData object is expected to contain detailed information about an image,
 * including its ID, path, source URL, status code, and the date it was taken.
 * If some properties are missing, default values are provided.
 *
 * @param {Object} [imageData={}] - An object containing detailed information about an image.
 * @returns {Object} An object containing the unpacked image data including id, name, source URL, status code, and timestamp.
 * If any of these properties are missing in the original imageData object, the function will return default values for them.
 */
export default function unpackThumbnail(imageData = {}) {
  const { DATA = {}, sort_info = {} } = imageData;

  const unpacked = {
    id: DATA?.id,
    name: sort_info?.origin_path ?? 'Image',
    src: DATA?.img?.src,
    status: Math.floor(DATA?.json?.IRVIEWER?.status?.code / 100),
    timestamp:
      getDateTimeFromMillisecondsTZ(sort_info?.date_taken, sort_info?.user_timezone) ??
      'Date Taken',
  };

  return unpacked;
}
