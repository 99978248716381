import React, { useMemo } from 'react';

import { TextField, Select, Typography, InputAdornment, MenuItem } from '@common-ui/ui';
import { emissivityCommonValues } from '../../../../enums';

const CHAR_EPSILON = 'ε';

function StringToDecimal(value, precision = 2) {
  return parseFloat(parseFloat(value).toFixed(precision));
}

function constrainToRange(value, min = 0, max = 1) {
  if (isNaN(value)) {
    value = max;
  }

  return Math.max(min, Math.min(max, value));
}

export default function IRImageEmissivity({ value = 1, onChange = null }) {
  const handleChange = (evt) => {
    const value = evt.target.value;

    // convert to Decimal and constrain [0, 1]
    let newClientEmissivity = StringToDecimal(value, 2);
    newClientEmissivity = constrainToRange(newClientEmissivity, 0, 1);

    onChange?.({ capturedEmissivity: newClientEmissivity });
  };

  const commonEmissivityValues = useMemo(() =>
    Object.entries(emissivityCommonValues)
      .map(([label, value]) => ({ label, value }))
      .sort((a, b) => b.value - a.value),
  );

  // const emissivityTooltip = (
  //   <>
  //     Range between
  //     <br />
  //     {`0 < ${CHAR_EPSILON} < 1`}
  //   </>
  // );

  return (
    <TextField
      inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', step: 0.01 }}
      type="number"
      id="emissivity_input"
      label="Emissivity"
      value={value}
      onChange={handleChange}
      sx={styles.input}
      InputProps={{
        startAdornment: <InputAdornment position="start">{`${CHAR_EPSILON} =`}</InputAdornment>,
        endAdornment: (
          <InputAdornment position="end">
            <Select
              value=""
              onChange={handleChange}
              variant="standard"
              renderValue={() => null}
              sx={styles.dropdown}
              MenuProps={styles.menu}
            >
              {commonEmissivityValues.map(({ label, value }) => (
                <MenuItem key={label} value={value}>
                  <div style={styles.menuItem}>
                    <Typography fontWeight="bold">{label}</Typography>
                    <Typography>{value}</Typography>
                  </div>
                </MenuItem>
              ))}
            </Select>
          </InputAdornment>
        ),
      }}
    />
  );
}

const styles = {
  input: {
    '& .MuiInputBase-root': {
      justifyContent: 'space-between',
    },
  },
  dropdown: {
    borderLeft: '1px dashed grey',
    paddingLeft: 2,
    '& .MuiSelect-select': {
      backgroundColor: 'transparent',
      padding: '0',
    },
    '&:hover, &.Mui-focused': {
      border: '1px solid white',
      borderRadius: '4px',
    },
    '&:before, &:after': {
      display: 'none',
    },
  },

  menu: {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
    getContentAnchorEl: null,
    PaperProps: {
      style: {
        width: '200px',
      },
    },
  },
  menuItem: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
};
