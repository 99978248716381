import React from 'react';
import { RotateLeftOutlined, RotateRightOutlined } from '@mui/icons-material';
import ToolButton from '../../../../../components/buttons/ToolButton';

export default function ToolRotate({ value = 0, onChange }) {
  const handleOnClick = (rotateBy) => {
    onChange?.({ irOrientation: (value + rotateBy) % 360 });
  };

  const rotateControls = [
    {
      id: 'rotate-left',
      value: 'left',
      tooltip: 'Rotate Left 90º',
      label: '-90º',
      icon: (props) => <RotateLeftOutlined {...props} />,
      disabled: false,
      onClick: () => handleOnClick(-90),
    },
    {
      id: 'rotate-right',
      value: 'right',
      tooltip: 'Rotate Right 90º',
      label: '+90º',
      icon: (props) => <RotateRightOutlined {...props} />,
      disabled: false,
      onClick: () => handleOnClick(90),
    },
  ];

  const styles = {
    row: {
      display: 'flex',
      flexDirection: 'row',
    },
    column: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  };

  return (
    <div style={styles.row}>
      {rotateControls.map((rotate, index) => (
        <div key={`${index}_${rotate.id}`} style={styles.column}>
          <ToolButton
            id={rotate.id}
            tooltip={rotate.tooltip}
            label={rotate.label}
            onClick={rotate.onClick}
            disabled={rotate.disabled}
            Icon={rotate.icon}
          />
        </div>
      ))}
    </div>
  );
}
