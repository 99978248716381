import React from 'react';
import { Snackbar, Typography, Alert } from '@common-ui/ui';
import AlertEnum from '../enums/AlertEnum';

/**
 * This is the Snackbar alert that will be used throughout the application
 * The ReportModal now manges the state of the Alert rather than managing it from a child component.
 *
 * @param {*} alert
 * @param {*} setAlert
 *
 * @returns JSX.Element - The modal alert component
 *
 *
 */
export default function ModalAlert({ alert, setAlert }) {
  return (
    <Snackbar
      open={alert?.visible}
      autoHideDuration={5000}
      onClose={() => setAlert(AlertEnum.DEFAULT)}
    >
      <Alert
        onClose={() => setAlert(AlertEnum.DEFAULT)}
        severity={alert?.severity}
        variant="filled"
        sx={{ color: 'white' }}
      >
        <Typography>{alert?.text}</Typography>
      </Alert>
    </Snackbar>
  );
}
