export const userKeyStoreEnum = Object.freeze({
  CURRENT_ORGANIZATION: 'currentOrganization',
  SHOW_ORGANIZATION: 'showOrganization',
  TEMPERATURE_UNITS: 'temperatureUnits',
  PREVIEW_PAGE_SIZE: 'previewPageSize',
});

export const userValueInitTable = new Object({
  currentOrganization: '',
  showOrganization: 'false',
  temperatureUnits: 'F',
  previewPageSize: 10,
});
