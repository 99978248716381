/**
 * These are the objects for the Alert component
 *
 * @typedef {Object} AlertEnum
 * @property {string} text - The text to be displayed in the alert
 * @property {boolean} visible - Whether or not the alert is visible
 * @property {string} severity - The severity of the alert
 */

export const AlertEnum = Object.freeze({
  REPORT_CREATED: {
    text: 'Report Created Successfully',
    visible: true,
    severity: 'success',
  },
  IMAGE_DELETED: {
    text: 'Image Deleted From Report',
    visible: true,
    severity: 'success',
  },
  REPORT_DELETED: {
    text: 'Report Deleted Successfully',
    visible: true,
    severity: 'success',
  },
  REPORT_UPDATED: {
    text: 'Report Updated Successfully',
    visible: true,
    severity: 'success',
  },
  REPORT_ERROR: {
    text: 'Unable to Create Report, Please Try Again',
    visible: true,
    severity: 'error',
  },
  DEFAULT: {
    text: '',
    visible: false,
    severity: 'success',
  },
});

export default AlertEnum;
