import { AssetType } from './AssetTypeEnum';

export const AssetFields = Object.freeze({
  ASSET_ID: {
    label: 'Asset ID',
    id: AssetType.ASSET_ID,
  },
  LOCATION: {
    label: 'Asset Location',
    id: AssetType.LOCATION,
  },
  ASSIGNED_ROUTES: {
    label: 'Assigned Routes',
    id: AssetType.ASSIGNED_ROUTES,
  },
  STATUS: {
    label: 'Status',
    id: AssetType.Status,
  },
  DATE_CREATED: {
    label: 'Created Date',
    id: AssetType.DATE_CREATED,
  },
  LAST_INSPECTION: {
    label: 'Last Inspection',
    id: AssetType.LAST_INSPECTION,
  },
  DESCRIPTION: {
    label: 'Asset Description',
    id: AssetType.DESCRIPTION,
  },

  // additional fields (options) -->
  MANUFACTURER: {
    label: 'Manufacturer',
    id: AssetType.MANUFACTURER,
  },
  MODEL: {
    label: 'Model',
    id: AssetType.MODEL,
  },
  TYPE: {
    label: 'Type',
    id: AssetType.TYPE,
  },
  SERIAL_NUMBER: {
    label: 'Serial Number',
    id: AssetType.SERIAL_NUMBER,
  },
  ASSET_TAG_ID: {
    label: 'Asset Tag ID',
    id: AssetType.ASSET_TAG_ID,
    placeholder: 'Enter Asset Tag ID',
    key: 'asset_field',
  },
  NSN: {
    label: 'NSN',
    id: AssetType.NSN,
  },
  TESTPOINTS: {
    label: 'Test Points',
    id: AssetType.TESTPOINTS,
  },
});
