import React, { useState } from 'react';
// mui -->
import { Tabs, Tab, Typography } from '@common-ui/ui';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
// components -->
import ProfileInfo from './ProfileInfo';

/**
 * AssetPage component renders a specific asset's detail page based on the provided asset payload.
 *
 * The AssetPage component maintains the state of the current tab being viewed by the user.
 * The handleChange function updates this state whenever the user switches tabs.
 * The current tab's corresponding component is then rendered.
 *
 * @param {Object} assetPayload - object relating to asset record
 *
 * @returns {JSX.Element} A JSX component rendering the asset page for the specified asset id.
 */
export default function AssetPage({ assetPayload = {} }) {
  const [currentTab, setCurrentTab] = useState('profile-info');

  const pageTabs = [
    { label: 'Profile Info', value: 'profile-info' },
    { label: 'Images', value: 'images' },
    { label: 'Analysis', value: 'analysis' },
    { label: 'More Info', value: 'more-info' },
  ];

  const handleChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  return (
    <div style={styles.container}>
      <div>
        <Typography padding="16px 0px 0px 16px" variant="h4">
          {assetPayload.name}
        </Typography>
      </div>
      <TabContext value={currentTab}>
        <div style={styles.tabsContainer}>
          <Tabs
            value={currentTab}
            onChange={handleChange}
            textColor="secondary"
            TabIndicatorProps={{
              sx: {
                backgroundColor: 'secondary.main',
              },
            }}
          >
            {pageTabs.map(({ label, value }) => (
              <Tab key={value} value={value} label={label} />
            ))}
          </Tabs>
        </div>
        <TabPanel sx={styles.panel} value="profile-info">
          <ProfileInfo payload={assetPayload} />
        </TabPanel>
        <TabPanel sx={styles.panel} value="images">
          <div style={styles.panelContent}>Images</div>
        </TabPanel>
        <TabPanel sx={styles.panel} value="analysis">
          <div style={styles.panelContent}>Analysis</div>
        </TabPanel>
        <TabPanel sx={styles.panel} value="more-info">
          <div style={styles.panelContent}>More Info</div>
        </TabPanel>
      </TabContext>
    </div>
  );
}

const styles = {
  container: {
    overflow: 'auto',
    height: '100%',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  tabsContainer: {
    backgroundColor: 'white',
  },
  panel: {
    overflow: 'auto',
    height: '100%',
    padding: '16px',
    backgroundColor: '#F5F5F5',
  },
  panelContent: {
    display: 'flex',
    padding: '16px',
    backgroundColor: 'white',
  },
};
