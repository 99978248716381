import React from 'react';
import Dates from './Dates';
import styled from '@emotion/styled';

/**
 * Sidebar component for the Image Management screen
 * @returns {JSX.Element}
 */
export default function SideBar() {
  const Container = styled.div`
    grid-area: sidebar;
    min-height: 100%;
    overflow-y: auto;
  `;

  return (
    <Container>
      <Dates />
    </Container>
  );
}
