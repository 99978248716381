import React, { useCallback, useMemo } from 'react';

// utils
import {
  ConvertToFahrenheit,
  ConvertToCelsiusMultiDecimal,
} from '../../../../utilities/temperatureUtils';

// enums
import temperatureUnitsEnums from '../../../../../../enums/temperatureUnitsEnum';

// components
import { InputAdornment, TextField, Tooltip } from '@common-ui/ui';

function StringToDecimal(value, precision = 2) {
  return parseFloat(parseFloat(value).toFixed(precision));
}

function constrainToRange(value, min, max) {
  if (isNaN(value)) {
    value = parseFloat(parseFloat(value));
  }

  return Math.max(min, Math.min(max, value));
}

const { FAHRENHEIT } = temperatureUnitsEnums;
const CHAR_TEMPERATURE = 'T';

export default function IRImageBackgroundTemperature({
  value = 22,
  min,
  max,
  temperatureUnit = 'C',
  onChange = null,
}) {
  const convetToClientTempScale = useCallback(
    (temperature, convertBack = false) => {
      const isFahrenheit = temperatureUnit === FAHRENHEIT;

      if (isFahrenheit && !convertBack) {
        // convert to Fahrenheit if local units are in Fahrenheit
        return ConvertToFahrenheit(temperature);
      } else if (isFahrenheit && convertBack) {
        // convert back to Celsius if local units are in Fahrenheit
        return ConvertToCelsiusMultiDecimal(temperature);
      } else {
        // do not convert if local units are in Celsius
        return temperature;
      }
    },
    [temperatureUnit],
  );

  const rangeValMin = useMemo(() => convetToClientTempScale(min), [min]);
  const rangeValMax = useMemo(() => convetToClientTempScale(max), [max]);

  const displayUnits =
    temperatureUnit === FAHRENHEIT
      ? `°${temperatureUnitsEnums.FAHRENHEIT}`
      : `°${temperatureUnitsEnums.CELSIUS}`;

  const handleChange = (event) => {
    const nextValue = event.target.value;

    // convert to Decimal, single decimal precision
    let newClientTemperature = StringToDecimal(nextValue);
    newClientTemperature = constrainToRange(newClientTemperature, rangeValMin, rangeValMax);

    // update the irviewer canvas with new temperature
    const nextBackgroundTemp = convetToClientTempScale(newClientTemperature, true);

    onChange?.({ capturedBackgroundTempC: nextBackgroundTemp });
  };

  const temperatureTooltip = (
    <>
      Range between
      <br />
      {`${rangeValMin} and ${rangeValMax} ${displayUnits}`}
    </>
  );

  return (
    <div className="input-group overlayTriggerParent">
      <Tooltip title={temperatureTooltip}>
        <TextField
          type="number"
          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', step: 0.1 }}
          value={convetToClientTempScale(value)}
          min={rangeValMin}
          max={rangeValMax}
          onChange={handleChange}
          style={{ width: '100%' }}
          label="Background Temperature"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">{`${CHAR_TEMPERATURE} =`}</InputAdornment>
            ),
            endAdornment: <InputAdornment position="end">{displayUnits}</InputAdornment>,
          }}
        />
      </Tooltip>
    </div>
  );
}
