import React, { useState } from 'react';
import beginUpload from '../../../../utils/beginUpload';
import { AddCircle } from '@mui/icons-material';
import ResetSessionModal from '../../../modals/ResetSessionModal';
import ButtonTextIcon from '../../../buttons/ButtonTextIcon';
import routerPathEnum from '../../../../enums/routerPathEnum';
import { useLocation } from 'react-router-dom';

/**
 * This component is used to display the upload images button in the primary navigation bar
 * When the button is clicked, it will trigger the beginUpload function and open a modal to reset
 * the data after uploading the images
 *
 * @returns {JSX.Element} - Upload Images button
 */

export default function UploadImages() {
  let location = useLocation();
  const [showModal, setShowModal] = useState(false);
  const checkImageManagementPage = () => location.pathname === routerPathEnum.IMAGES;

  const handleModal = (event) => {
    setShowModal(true);
    beginUpload(event);
  };
  return (
    checkImageManagementPage() && (
      <div>
        <ResetSessionModal showModal={showModal} setShowModal={setShowModal} />
        <ButtonTextIcon
          variant="contained"
          onClick={handleModal}
          icon={<AddCircle />}
          tooltip={'Add Images'}
        >
          Add Images
        </ButtonTextIcon>
      </div>
    )
  );
}
