import React from 'react';
import { Typography } from '@common-ui/ui';
import styled from '@emotion/styled';
import { useSelector } from 'react-redux';

const CountsEnum = Object.freeze({
  IMAGE: 'Image',
  IMAGES: 'Images',
  SELECTED: 'Selected',
});

const Count = styled(Typography)`
  grid-area: counts;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: ${({ theme }) => theme.palette.text.primary};
  background: ${({ theme }) => theme.palette.grey.A700};
`;

/**
 * Counts components for images selected
 *
 * @returns {JSX.Element}
 */

export default function Counts() {
  const { selectedImageIds = [] } = useSelector((state) => state.images);
  const imageCount = selectedImageIds.length;
  if (!imageCount) return <Count />;

  const response = imageCount === 1 ? CountsEnum.IMAGE : CountsEnum.IMAGES;

  return <Count>{`${imageCount} ${response} ${CountsEnum.SELECTED}`}</Count>;
}
