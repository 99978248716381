import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
// redux
import { useAppDispatch } from '../../../store/configureStore';
import {
  addImagesToReport,
  clearReportInfo,
  storeReportInfo,
} from '../../../store/slices/reportSlice';
// components
import { ModalContext } from '../../../context';

import AlertEnum from '../Alert/enums/AlertEnum';
// mui
import { Button, FormHelperText, TextField } from '@common-ui/ui';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { createReport } from '../../../utils/reports';

const initial = {
  report_name: '',
  company: '',
  site_id: '',
  performed_by: '',
  performed_on: '',
};

/**
 * This is the form for creating a new report
 *
 * @param {*} imageList - the list of images to be added to the report
 *
 * @returns {JSX.Element} - The form of the report modal
 */

export default function CreateNewReportForm({ imageList }) {
  const navigate = useNavigate();
  const { closeModal, handleAlert } = useContext(ModalContext);
  const dispatch = useAppDispatch();
  const [data, setData] = useState(initial);
  const [error, setError] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const addMultipleImages = async () => {
    if (data.report_name.length === 0) {
      setError(true);
      return;
    }
    setDisabled(true);
    let reportInfo = await createReport(data);

    if (imageList.length > 0) {
      const newReportInfo = await dispatch(addImagesToReport(reportInfo, imageList));
      await dispatch(storeReportInfo(newReportInfo));
    } else {
      await dispatch(storeReportInfo(reportInfo));
    }
    closeModal();
    handleAlert(AlertEnum.REPORT_CREATED);

    await dispatch(clearReportInfo());
    navigate(`/report/${reportInfo.options.id}`);
  };

  const handleUpdate = ({ target }) => setData({ ...data, [target.name]: target.value });

  return (
    <div>
      {error && (
        <FormHelperText error id="component-error-text">
          Report Name Required *
        </FormHelperText>
      )}
      <TextField
        error={error}
        label="Report Name"
        variant="outlined"
        onChange={handleUpdate}
        name="report_name"
        value={data.report_name ?? ''}
        sx={styles.formInput}
      />
      <div style={styles.formRow}>
        <TextField
          sx={styles.formInput}
          label="Company"
          variant="outlined"
          onChange={handleUpdate}
          name="company"
          value={data.company ?? ''}
        />
        <TextField
          sx={styles.formInput}
          label="Location"
          variant="outlined"
          name="site_id"
          onChange={handleUpdate}
          value={data.site_id ?? ''}
        />
      </div>
      <div style={styles.formRow}>
        <TextField
          sx={styles.formInput}
          label="Performed By"
          variant="outlined"
          name="performed_by"
          onChange={handleUpdate}
          value={data.performed_by ?? ''}
        />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            sx={styles.formInput}
            label="Performed On"
            onChange={(date) => setData({ ...data, performed_on: date.toString() })}
          />
        </LocalizationProvider>
      </div>
      <Button
        color="secondary"
        variant="contained"
        sx={styles.button}
        onClick={addMultipleImages}
        disabled={disabled}
      >
        Create Report
      </Button>
    </div>
  );
}

const styles = {
  formRow: {
    display: 'flex',
    flexGrow: 1,
    gap: '0.5em',
  },
  formInput: {
    width: 1,
    marginTop: '0.5em',
  },
  button: {
    marginY: 2,
    float: 'right',
  },
};
