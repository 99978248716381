import React, { useCallback } from 'react';

// utilities
import { handleImageExport } from '@fluke/irviewer';

// components
import EditorTools from './EditorTools';
import { FieldAssetTagId, FieldImageName } from './EditorTools/Fields';
import DoneEditing from '../Buttons/DoneEditing';
import { AccordionGroup, AccordionItem } from './Accordion';
import { ExportImages, AddToReport, ImageRevert } from '../Buttons';

/**
 *
 * @param settings - the settings for the image editor
 * @param batchMode - whether the image editor is in batch mode
 * @param id - the ID of the image
 * @param groupImageData - the group image data
 * @param setGroupImageData - the function to set the group image data
 * @param paletteData - the palette display data
 * @param onChange - the function to change the image
 * @param onNameChange - the function to change the name of the image
 * @param assetTagId - the asset tag ID
 * @param setAssetTagId - the function to set the asset tag ID
 *
 * @returns
 */
export default function SidebarEditor({
  settings = {},
  batchMode = false,
  imageId = '',
  order = [],
  paletteData = {},
  groupImageData = {},
  onChange,
  onNameChange,
  updateAssetTagId,
}) {
  const { sort_info = {}, asset_info = {} } = groupImageData?.[imageId] ?? {};
  const imageName = sort_info.origin_path ?? '';
  const assetTagId = asset_info.assetTagId ?? '';

  const handleExport = useCallback(() => {
    handleImageExport(groupImageData);
  }, [groupImageData]);

  const handleUpdate = {
    settings: (...args) => {
      onChange?.(...args);
    },
    name: (...args) => {
      onNameChange?.(...args);
    },
    assetId: (...args) => {
      updateAssetTagId?.(...args);
    },
  };

  return (
    <>
      <div style={styles.reporting}>
        <div style={styles.column}>
          <div style={styles.DoneRevertButtonRow}>
            <ImageRevert disabled={true} style={styles.button} />
            <DoneEditing groupImageData={groupImageData} style={styles.button} />
          </div>
          <AddToReport order={order} groupImageData={groupImageData} />
          <ExportImages onClick={handleExport} />
        </div>
      </div>
      <AccordionGroup>
        <AccordionItem label="Image Settings" expanded={true}>
          <div style={styles.column}>
            <FieldImageName value={imageName} onChange={handleUpdate.name} disabled={batchMode} />
            <FieldAssetTagId
              value={assetTagId}
              disabled={batchMode}
              onChange={handleUpdate.assetId}
            />
            <EditorTools
              settings={settings}
              paletteDisplayData={paletteData}
              onChange={handleUpdate.settings}
            />
          </div>
        </AccordionItem>
      </AccordionGroup>
    </>
  );
}

const styles = {
  row: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
  },
  DoneRevertButtonRow: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    justifyContent: 'space-between',
  },
  button: {
    flex: 1,
    margin: '0 4px',
  },
  reporting: {
    padding: '18px 8px',
  },
};
