import { assetFormKeys, reportInfoDataKeys } from '.';

const reportInfoKeys = Object.freeze({
  COMPANY_LOGO: 'company_logo',
  IMAGE_OPTIONS: 'image_options',
  IMAGES: 'images',
  IMAGE_URLS: 'image_urls',
  OPTIONS: 'options',
  DATA: 'data',
  ASSET_INFO: 'asset_info',

  ...reportInfoDataKeys,
  ...assetFormKeys,
});

export default reportInfoKeys;
