import React from 'react';
import DownloadIcon from '@mui/icons-material/Download';
import ButtonTextIcon from '../../../buttons/ButtonTextIcon';
import { useLocation } from 'react-router-dom';
import { getLatestUploader } from '../../../../apis/visorAPI';
import routerPathEnum from '../../../../enums/routerPathEnum';

/**
 * This component is used to display the image downloader button in the primary navigation bar
 * When the button is clicked, it will open a new instance of the uploader
 * with a uuid appended to the url
 *
 * @returns {JSX.Element} - Image uploader button
 */

export default function Downloader() {
  const location = useLocation();
  const checkImageManagementPage = () => location.pathname === routerPathEnum.IMAGES;
  const onClick = async () => {
    const { url } = await getLatestUploader();
    global.location.href = url;
  };
  return (
    checkImageManagementPage() && (
      <ButtonTextIcon
        icon={<DownloadIcon />}
        variant="contained"
        tooltip={'Image Uploader'}
        onClick={onClick}
        sx={download}
      />
    )
  );
}

const download = {
  color: 'black',
  background: 'white',
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'none',
    background: 'rgba(255,255,255,0.1)',
    color: 'white',
  },
};
