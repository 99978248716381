import React, { useState } from 'react';
import {
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from '@mui/material';
import { com_fluke_baseline as irrenderer_info } from '@fluke/irrenderer/package.json';
import { com_fluke_baseline as irviewer_info } from '@fluke/irviewer/package.json';
import { com_fluke_baseline as website_info } from '../../../../versioning.json';
import loadUrlToState from '../../../../utils/loadUrlToState';

const ShowDialogContent = ({ title, version }) => (
  <DialogContent sx={{ display: 'flex', justifyContent: 'space-between', p: 1 }}>
    <DialogContentText>{title}</DialogContentText>
    <DialogContentText>{version}</DialogContentText>
  </DialogContent>
);

const VersionSettings = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    acquireServiceVersions();
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  const [versionImageAccess, setVersionImageAccess] = useState(null);
  const [versionReportAccess, setVersionReportAccess] = useState(null);
  const [versionVisorAPI, setVersionVisorAPI] = useState(null);
  const [versionUserStoreAPI, setVersionUserStoreAPI] = useState(null);

  const acquireServiceVersions = () => {
    loadUrlToState(`${process.env.REACT_APP_BASE_API_URL}/version`, setVersionImageAccess);
    loadUrlToState(`${process.env.REACT_APP_REPORT_API_URL}/version`, setVersionReportAccess);
    loadUrlToState(`${process.env.REACT_APP_VISOR_API_BASE_URL}/version`, setVersionVisorAPI);
    loadUrlToState(`${process.env.REACT_APP_USER_STORE_URL}/version`, setVersionUserStoreAPI);
  };

  return (
    <Box>
      <Typography onClick={handleOpen}>Version Settings</Typography>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle>Version Settings</DialogTitle>
        <DialogContent>
          <ShowDialogContent title={'Baseline Website'} version={website_info.website.version} />
          <ShowDialogContent title={'IR Viewer'} version={irviewer_info.irviewer.version} />
          <ShowDialogContent title={'IR Renderer'} version={irrenderer_info.irrenderer.version} />
          <ShowDialogContent title={'Image Access API'} version={versionImageAccess} />
          <ShowDialogContent title={'Report Access API'} version={versionReportAccess} />
          <ShowDialogContent title={'Visor API'} version={versionVisorAPI} />
          <ShowDialogContent title={'User Store API'} version={versionUserStoreAPI} />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default VersionSettings;
