import React, { useState } from 'react';

// enums
import secondaryNavTabsEnum from '../../../../enums/secondaryNavTabsEnum';
import { ImageInfoKeys, ValidSettings, temperatureUnits } from '@fluke/irviewer';

// componenets
import InfoTable from './InfoTable';
import MarkerInfo from './MarkerInfo';
import PanelNotes from './PanelNotes';
import { Tab, Tabs } from '@common-ui/ui';

export default function PanelImageInfo({
  imageData = {},
  settings = {},
  photonotes = [],
  onChange = null,
}) {
  const {
    [ImageInfoKeys.MARKERS]: MARKERS = {},
    [ValidSettings.TEXTNOTES]: TEXTNOTES = [],
    [ValidSettings.OCR_TEXT]: ocrText = [],
    [ValidSettings.REFERENCE_MARKER_ID]: referenceMarkerId = '',
    temperatureUnit = temperatureUnits.CELSIUS,
  } = settings;

  const { DATA: { json: imageInfo = {} } = {} } = imageData || {};

  const [currentTab, setCurrentTab] = useState(secondaryNavTabsEnum.INFO);
  const navTabs = Object.keys(secondaryNavTabsEnum).filter((navTab) => navTab !== 'BATCHEDIT');

  const styles = {
    tabContainer: {
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
  };

  const checkIsTabDisabled = (test = '') => ({ display: currentTab === test ? 'block' : 'none' });

  return (
    <div style={styles.tabContainer}>
      <Tabs activekey={currentTab} value={currentTab}>
        {navTabs.map((navTab, index) => (
          <Tab
            key={index}
            role="tabpanel"
            label={secondaryNavTabsEnum[navTab]}
            onClick={() => setCurrentTab(secondaryNavTabsEnum[navTab])}
            value={secondaryNavTabsEnum[navTab]}
          />
        ))}
      </Tabs>

      <div style={{ width: '100%' }}>
        <div style={checkIsTabDisabled(secondaryNavTabsEnum.INFO)}>
          <InfoTable imageInfo={imageInfo} temperatureUnit={temperatureUnit} />
        </div>
        <div style={checkIsTabDisabled(secondaryNavTabsEnum.MARKERS)}>
          <MarkerInfo
            markers={MARKERS}
            referenceMarkerId={referenceMarkerId}
            onChange={onChange}
            temperatureUnit={temperatureUnit}
          />
        </div>
        <div style={checkIsTabDisabled(secondaryNavTabsEnum.NOTES)}>
          <PanelNotes
            notes={TEXTNOTES}
            photonotes={photonotes}
            ocrText={ocrText}
            imageInfo={imageInfo}
            onChange={onChange}
          />
        </div>
      </div>
    </div>
  );
}
