import React, { useState } from 'react';

// components
import { Collapse } from '@common-ui/ui';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import NavRow from './NavRow';

/**
 * NavSection component.
 *
 * This component represents a navigation item for the report sidebar at the parent level.
 * It can contain subitems that expand/collapse when the parent item is clicked.
 *
 * @component
 * @param {Object} item - Object containing report information, including subitems if any.
 * @param {boolean} active - Boolean indicating if the item is currently active. Defaults to false.
 * @param {Function} onClick - Function to handle click event. Receives the item's `id` as an argument.
 * @param {Function} onDelete - Function to handle delete event. Receives the item's `id` as an argument.
 * @returns {JSX.Element} Rendered NavSection component.
 */
export default function NavSection({ item = {}, active = false, onClick, onDelete }) {
  const [open, setOpen] = useState(true);
  const subitems = item?.subitems || [];

  const handleOnClick = () => {
    if (subitems.length) {
      setOpen((prev) => !prev);
    } else {
      onClick?.(item?.id);
    }
  };

  return (
    <div>
      <NavRow
        active={active}
        Icon={subitems.length ? (open ? ExpandMore : ExpandLess) : null}
        name={item.name}
        indent={1}
        onClick={handleOnClick}
      />

      {subitems && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          {(item.subitems || []).map(({ id, name }, index) => (
            <NavRow
              key={`subitem_${index}_${id}`}
              name={name}
              indent={2}
              onClick={() => onClick?.(id)}
              onDelete={onDelete ? () => onDelete(id) : null}
            />
          ))}
        </Collapse>
      )}
    </div>
  );
}
