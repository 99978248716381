import temperatureUnitsEnums from '../../../enums/temperatureUnitsEnum';

export function getTemperatureValue(value, temperatureUnit) {
  value = parseFloat(value);
  if (temperatureUnit === temperatureUnitsEnums.FAHRENHEIT) {
    return ConvertToFahrenheit(value);
  } else {
    return value.toFixed(1);
  }
}

export function ConvertToFahrenheit(celsius) {
  return FixedToOneDecimal((celsius * 9) / 5 + 32);
}

export function ConvertToCelsiusMultiDecimal(fahrenheit) {
  return ((fahrenheit - 32) * 5) / 9;
}

export function ConvertToCelsius(fahrenheit) {
  FixedToOneDecimal(ConvertToCelsiusMultiDecimal(fahrenheit));
}

export function FixedToOneDecimal(value) {
  // This is a hazard. Not equivalent to Number.parseFloat(x).toFixed(1).
  // Do not modify parens to Number(value).toFixed(1))
  return Number(value.toFixed(1));
}
