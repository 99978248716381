import { ClickAwayListener, Collapse } from '@common-ui/ui';
import React, { useState } from 'react';
import ToolButton from '../../../../components/buttons/ToolButton';

export default function ButtonPopover({ children, label, tooltip, Icon }) {
  const [open, setOpen] = useState(false);

  const handleButtonClick = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  return (
    <div>
      <ToolButton
        id="button-popout"
        tooltip={tooltip}
        label={label}
        onClick={handleButtonClick}
        active={open}
        disabled={false}
        Icon={() => Icon}
      />
      <ClickAwayListener onClickAway={handleClickAway} mouseEvent="onMouseDown">
        <Collapse in={open}>
          <div style={styles.popover}>{children}</div>
        </Collapse>
      </ClickAwayListener>
    </div>
  );
}

const styles = {
  popover: {
    position: 'absolute',
    minWidth: '200px',
    zIndex: 100,
    backgroundColor: 'rgb(18, 18, 18)',
    border: '1px solid grey',
  },
};
