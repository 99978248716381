import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Autocomplete, TextField, Pagination as PaginationComponent } from '@mui/material';

import pageSizeOptionsEnum from '../../../enums/pageSizeOptionsEnum';
import { userKeyStoreEnum } from '../../../enums/userKeyStoreEnum';
import { sessionKeyStoreEnum } from '../../../enums/sessionKeyStoreEnum';
import { UserContext, SessionContext } from '../../../context';
import { InputAdornment } from '@mui/material';
import { Search } from '@mui/icons-material';
/**
 * Pagination component for the Image Management screen
 * Used to navigate between pages of images
 * Also allows the user to change the number of images displayed per page
 *
 * @returns {JSX.Element}
 */
export default function Pagination() {
  const { userSettings = {}, updateUserSettings } = useContext(UserContext);
  const { sessionStore = {}, updateSessionStore } = useContext(SessionContext);

  const { previewPageSize } = useMemo(() => userSettings, [userSettings]);

  const [ocrText, setOcrText] = useState('');

  const { previewPageCount, previewPageIndex } = useMemo(() => sessionStore, [sessionStore]);

  const pageSizeOptionAsString = [...Object.values(pageSizeOptionsEnum)];
  const defaultPageIndex = 0;

  const updatePage = (newPageIndex) => {
    const newIndex = newPageIndex - 1;
    if (previewPageIndex == newIndex) {
      return;
    }
    updateSessionStore({ [sessionKeyStoreEnum.PREVIEW_PAGE_INDEX]: newIndex });
  };

  const updatePageSize = (newPageSize) => {
    if (newPageSize !== null) {
      const newPageSizeNumber = Number(newPageSize);
      if (previewPageSize === newPageSizeNumber) return;
      updateSessionStore({
        [sessionKeyStoreEnum.PREVIEW_PAGE_INDEX]: defaultPageIndex,
        [userKeyStoreEnum.PREVIEW_PAGE_SIZE]: newPageSizeNumber,
      });
      updateUserSettings({ [userKeyStoreEnum.PREVIEW_PAGE_SIZE]: newPageSizeNumber });
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' || e.key === 'Tab') {
      e.target.blur();
      updateOcrText();
    }
  };

  const updateOcrText = () => {
    updateSessionStore({ [sessionKeyStoreEnum.SEARCH_TEXT]: ocrText });
  };

  useEffect(() => {
    setOcrText(sessionStore[sessionKeyStoreEnum.SEARCH_TEXT]);
  }, [sessionStore[sessionKeyStoreEnum.SEARCH_TEXT]]);

  return (
    <div style={styles.content}>
      <TextField
        value={ocrText || ''}
        onChange={(e) => setOcrText(e.target.value)}
        onKeyDown={handleKeyPress}
        placeholder="Keyword Search"
        size="small"
        sx={styles.input}
        color="primary"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" sx={styles.pointer}>
              <Search />
            </InputAdornment>
          ),
        }}
        onBlur={updateOcrText}
      />

      <Autocomplete
        options={pageSizeOptionAsString}
        value={`${previewPageSize}`}
        onChange={(e, value) => updatePageSize(value)}
        renderInput={(params) => (
          <TextField
            {...params}
            color="primary"
            size="small"
            label="Number of Images Displayed"
            sx={styles.input}
          />
        )}
      />

      <PaginationComponent
        showFirstButton
        showLastButton
        boundaryCount={1}
        siblingCount={0}
        count={previewPageCount || 1}
        page={(previewPageIndex || 0) + 1}
        onChange={(e, value) => updatePage(value)}
        color="primary"
        shape="rounded"
        sx={styles.pagination}
      />
    </div>
  );
}

const styles = {
  content: { display: 'flex', alignItems: 'center', gap: '0.75em' },
  pointer: { '& :hover': { cursor: 'pointer' } },
  input: { width: '12em' },
  pagination: { minWidth: '12em' },
};
