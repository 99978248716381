import React from 'react';
// mui -->
import { MenuItem, TextField, Typography } from '@common-ui/ui';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
/**
 * AssetTextField is a customizable input field component that supports standard text input, selection from options, and date picking.
 *
 * If 'select' prop is true, it renders a selection field with options provided in the 'options' prop.
 * If 'date' prop is true, it renders a date picker field.
 * Otherwise, it renders a standard text input field.
 * The 'label' prop defines the label for the input field.
 * The 'style' prop allows custom inline styles to be applied to the text field.
 *
 * The AssetTextField component maintains the state of the selected value for the select field type.
 * The handleChange function updates this state whenever the user makes a selection.
 *
 * @param {Object} props - Properties passed to component
 * @param {string} props.label - Label for the text field
 * @param {Object} props.style - Custom inline styles for the text field
 * @param {boolean} props.date - If true, render a date picker field
 * @param {boolean} props.select - If true, render a select field
 * @param {Array} props.options - Options for select field, each option is an object with 'value' and 'label' properties
 *
 * @returns {JSX.Element} A JSX component rendering the text field with specified properties.
 */
export default function AssetTextField({
  label = '',
  style = {},
  date = false,
  select = false,
  options = [],
  startAdornment = <></>,
  endAdornment = <></>,
  ...props
}) {
  const [value, setValue] = React.useState('');

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <div style={styles.container}>
      <Typography variant="subtitle2">{label || ''}</Typography>
      {!date ? (
        <TextField
          select={select}
          size="small"
          margin="none"
          placeholder={label}
          value={select ? value : props.value}
          onChange={select ? handleChange : props.onChange}
          options={options}
          style={{ ...styles.textfield, ...style }}
          InputProps={{ ...styles.text, startAdornment, endAdornment }}
          {...props}
        >
          {select &&
            options.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
        </TextField>
      ) : (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            value={props.value}
            slotProps={{
              textField: {
                size: 'small',
                margin: 'none',
                style: styles.textfield,
                inputProps: styles.text,
              },
            }}
          />
        </LocalizationProvider>
      )}
    </div>
  );
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  textfield: {
    width: '300px',
    backgroundColor: '#F8F8F8',
  },
  text: {
    style: {
      color: '#7F7F7F',
    },
  },
};
