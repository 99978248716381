import React, { useContext } from 'react';
import { Dialog } from '@mui/material';
import {
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Typography,
} from '@common-ui/ui';
import { useAppDispatch } from '../../store/configureStore';
import { clearSelectedImages, fetchSidebarData } from '../../store/slices/imagesSlice';
import { SessionContext } from '../../context';

export default function ResetSessionModal({ showModal, setShowModal }) {
  const dispatch = useAppDispatch();
  const session = useContext(SessionContext);

  const resetData = () => {
    dispatch(fetchSidebarData(true, session?.updateSessionStore));
    dispatch(clearSelectedImages());
    setShowModal(false);
  };

  return (
    <Dialog open={showModal} onClose={() => setShowModal(false)} fullWidth maxWidth="xs">
      <DialogTitle>Refresh when Upload is Complete</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography>
            Once your images are uploaded, simply click refresh to view the successful uploads.
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" sx={{ m: 1 }} onClick={resetData}>
          Refresh
        </Button>
      </DialogActions>
    </Dialog>
  );
}
