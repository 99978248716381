import React, { useEffect, useState } from 'react';
import { useBlocker } from 'react-router-dom';

// components
import { Modal, Typography, Button, IconButton } from '@common-ui/ui';
import CloseIcon from '@mui/icons-material/Close';

const saveModalEnum = {
  TITLE: 'Save Changes',
  PROMPT: 'Do you want to save all changes before exiting the editor?',
  DECLINE: 'Decline',
  ACCEPT: 'Save',
};

const checkTransition = (transition = {}) => {
  const { currentLocation, nextLocation } = transition;
  // block if redirecting
  return currentLocation.pathname !== nextLocation.pathname;
};

/**
 * This component displays a prompt when the user attempts to leave the Image Editor.
 * The prompt asks the user whether they want to keep their recent changes or not.
 *
 * @param {function} onAccept - A function that is invoked when the user accepts to keep the changes.
 * @returns {JSX.Element} - A prompt that appears when the user attempts to navigate away from the Image Editor.
 */
export default function SavePrompt({ onAccept }) {
  const [showPrompt, setShowPrompt] = useState(false);

  const blocker = useBlocker(checkTransition);

  // only block the router when they choose to move to one of the following paths
  useEffect(() => {
    setShowPrompt(blocker.state === 'blocked');
  }, [blocker.state]);

  const handle = {
    cancel: () => {
      blocker.reset();
    },
    decline: () => {
      blocker.proceed();
    },
    accept: () => {
      onAccept?.();

      setTimeout(() => {
        blocker.proceed();
      }, [2000]);
    },
  };

  return (
    <Modal open={showPrompt} onClose={handle.cancel}>
      <div style={styles.container}>
        <div style={styles.content}>
          <Typography style={styles.title}>{saveModalEnum.TITLE}</Typography>
          <IconButton onClick={handle.cancel}>
            <CloseIcon style={styles.closeIcon} />
          </IconButton>
        </div>
        <div style={styles.accordions}>
          <Typography pt={1}>{saveModalEnum.PROMPT}</Typography>
          <div style={styles.buttons}>
            <Button
              variant="outlined"
              color="secondary"
              style={{ ...styles.button, ...styles.dontSave }}
              onClick={handle.decline}
            >
              {saveModalEnum.DECLINE}
            </Button>
            <Button
              style={styles.button}
              variant="contained"
              color="secondary"
              onClick={handle.accept}
            >
              {saveModalEnum.ACCEPT}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

const styles = {
  title: {
    textAlign: 'left',
    width: '500px',
    fontSize: '22px',
  },

  container: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '30em',
    height: '10em',
    minHeight: '104px',
    maxHeight: '220px',
    boxShadow: 24,
    opacity: 1,
    padding: '24px',
    background: '#232323',
    border: '2px solid #000',
    color: 'white',
  },

  dontSave: { color: 'white', border: '1px solid rgba(255, 255, 255, 0.5)' },

  content: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px 0px',
  },

  closeIcon: {
    justifySelf: 'flex-end',
  },

  accordions: {
    borderTop: '1px solid rgba(255, 255, 255, 0.12)',
    height: 'calc(100% - 60px)',
  },

  button: {
    width: '8rem',
  },

  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'end',
    height: 'calc(100% - 24px)',
    gap: 5,
  },
};
