import React, { useMemo } from 'react';

// utils
import { flatMarkerArray, getMarkerById } from '@fluke/irviewer';

// components
import { TableCell, Paper, Table, TableHead, TableRow, TableBody } from '@mui/material';
import MarkerRow from './MarkerRow';

export default function IRViewerMarkerInfo({
  markers = {},
  referenceMarkerId = '',
  temperatureUnit = 'C',
  onChange,
}) {
  const markerArray = flatMarkerArray(markers);

  const markerTableHeaders = ['Marker Name', 'Max Temperature', 'Delta T', 'Reference Temp'];

  const handleNextReferenceTemp = (id = '') => {
    onChange?.({ referenceMarkerId: id });
  };

  const referenceTemp =
    useMemo(() => {
      const referenceMarker = getMarkerById(referenceMarkerId, markerArray);
      const { data: { characteristics = {} } = {} } = referenceMarker;
      const { markerTempC_Max, markerTempC_Average } = characteristics;
      const temperature = markerTempC_Max ?? markerTempC_Average ?? 0;
      return temperature;
    }, [referenceMarkerId, markerArray]) ?? 0;

  return (
    <Paper>
      <div style={styles.table}>
        <Table size="small">
          <TableHead style={styles.header}>
            <TableRow>
              {markerTableHeaders.map((header, index) => (
                <TableCell key={index}>{header}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {markerArray.map(({ id, data }, index) => (
              <MarkerRow
                key={`table-row-${index}-${id}`}
                marker={data}
                isReference={id === referenceMarkerId}
                referenceTemp={referenceTemp}
                referenceMarkerId={referenceMarkerId}
                onNextReference={() => handleNextReferenceTemp(id)}
                temperatureUnit={temperatureUnit}
              />
            ))}
          </TableBody>
        </Table>
      </div>
    </Paper>
  );
}

const styles = {
  table: {
    height: '400px',
    overflow: 'auto',
  },
  header: {
    position: 'sticky',
    top: '0',
    background: 'rgb(18, 18, 18)',
  },
  buttonDelete: {
    fontSize: '14px',
    color: 'white',
    p: 0,
  },
};
