export const reportsEnum = Object.freeze({
  OVERVIEW: 'Overview',
  REPORT: 'Report',
  SEARCH_BAR_PLACEHOLDER: 'Search Name, Date, or Report ID...',
  DRAFT_REPORTS: 'Draft Reports',
  COMPLETED_REPORTS: 'Completed Reports',
  SORTED_BY_PROPERTY: 'created_on',
  REPORT_NAME_FIELD: 'report_name',
  PERFORMED_ON_FIELD: 'performed_on',
  COMPANY_FIELD: 'company',
  SITE_ID_FIELD: 'site_id',
  PERFORMED_BY_FIELD: 'performed_by',
  ACTIONS_FIELD: 'actions',
  FINALIZED_FIELD: 'finalized',
  REPORT_NAME_HEADER: 'Report Name',
  PERFORMED_ON_HEADER: 'Performed On',
  COMPANY_HEADER: 'Company',
  LOCATION_HEADER: 'Location',
  PERFORMED_BY_HEADER: 'Performed By',
  ACTIONS_HEADER: 'Actions',
  EDIT: 'Edit',
  REVIEW: 'Review',
  DELETE: 'Delete',
});
