import { createSlice } from '@reduxjs/toolkit';

const sessionSlice = createSlice({
  name: 'session',
  initialState: {},
  reducers: {
    setSession: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { setSession } = sessionSlice.actions;
export default sessionSlice.reducer;
