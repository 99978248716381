import React from 'react';
import { Typography, Button } from '@common-ui/ui';
import beginUpload from '../../../../utils/beginUpload';
import { AddCircle, FormatListBulleted } from '@mui/icons-material';
import { NoImagesDialogEnum } from '../../../../enums/NoImagesDialogEnum';
import styled from '@emotion/styled';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.palette.grey[900]};
  align-items: center;
  gap: 0.5em;
  padding: 2em;
`;

const Icon = styled.div`
  background: ${({ theme }) => theme.palette.secondary.main};
  color: white;
  width: 4em;
  height: 4em;
  border-radius: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1em;
`;

/**
 * Display a dialog when there are no images to display
 *
 *  @param {None} None
 *  @returns {JSX.Element}
 */
export default function NoImagesDialog() {
  return (
    <Container>
      <Icon>
        <FormatListBulleted fontSize="large" />
      </Icon>
      <Typography variant="h6" color="white">
        {NoImagesDialogEnum.TITLE}
      </Typography>
      <Typography color="gray">{NoImagesDialogEnum.BODY}</Typography>
      <Button
        variant="contained"
        color="secondary"
        size="large"
        startIcon={<AddCircle />}
        onClick={beginUpload}
      >
        {NoImagesDialogEnum.UPLOAD_BUTTON}
      </Button>
    </Container>
  );
}
