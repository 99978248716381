import React from 'react';
import { Backdrop } from '@common-ui/ui';

export default function Overlay({ children, open = true, style = {}, ...rest }) {
  const styles = {
    overlay: {
      position: 'absolute',
      ...style,
    },
  };

  return (
    <Backdrop id="overlay-absolute" style={styles.overlay} open={open} {...rest}>
      {children}
    </Backdrop>
  );
}
