import React, { useState } from 'react';
// enums -->
import { ROOT } from '../../lib/enum/RootEnum';
import { sortHierarchyItems } from '../../lib/tree';
import { EntryType } from '../../lib/enum/EntryTypeEnum';
import { EntryAction } from '../../lib/enum/EntryActionEnum';
// mui -->
import { Collapse, List, ListItem } from '@common-ui/ui';
// components -->
import { ConfirmDeleteModal } from '../Modal';
import ContextMenu from '../ContextMenu';
import NavHeader from './NavHeader';
import SidebarItem from './SidebarItem';
/**
 * Sidebar is a functional component that renders a hierarchy of items in a sidebar.
 * Each item can be either a file or a folder. Folders can be expanded or collapsed
 * to show or hide their children respectively.
 *
 * The Sidebar component provides context menu functionality for each item. The context
 * menu offers various actions such as renaming or deleting the item. If the rename
 * action is selected, the item enters edit mode, allowing its name to be changed. If
 * the delete action is selected, a confirmation modal is displayed.
 *
 * @param {Object[]} [items=[]] - An array of objects representing the hierarchy of items to be displayed.
 * @param {function} [onClick=null] - Function to be called when an item is clicked. The clicked item's id is passed as an argument.
 * @param {function} [onAction=null] - Function to be called when an action is selected from the context menu.
 *                                     This function is called with two arguments: the id of the item on which the action
 *                                     was selected, and a string representing the action.
 *                                     If the rename action was selected, a third argument representing the new name is also passed.
 *
 * @returns {JSX.Element} A JSX component rendering a sidebar.
 */
export default function Sidebar({ items = [], onClick = () => {}, onAction = () => {} }) {
  const [focusItemId, setFocusItemId] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [isOpen, setIsOpen] = useState({ [ROOT.id]: true });
  const [cursorPosition, setCursorPosition] = useState(null);
  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] = useState(false);

  const handleOpen = (id) => {
    // toggle open on first click. Never close
    setIsOpen((prev) => ({ ...prev, [id]: true }));
    onClick(id);
  };

  const handleOpenToggle = (id) => {
    // toggle open when arrow icon handle is clicked
    setIsOpen((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const onContextMenu = (event, id) => {
    // open context menu on right-click -->
    event.stopPropagation();
    event.preventDefault();
    setFocusItemId(id);
    setCursorPosition({ top: event.clientY, left: event.clientX });
  };

  const onContextMenuClose = (actionId = '') => {
    // close context menu on selection or click-away -->
    setCursorPosition(null);
    if (!actionId) return;

    // check if the rename action was selected
    switch (actionId) {
      case EntryAction.RENAME:
        setIsEdit(true);
        break;
      case EntryAction.DELETE:
        setIsConfirmDeleteModalOpen(true);
        break;
      default:
        onAction(focusItemId, actionId);
        setFocusItemId(null);

        break;
    }
  };

  const handleRename = (newName = '') => {
    // pass new item name referenced by id
    if (newName) {
      onAction(focusItemId, EntryAction.RENAME, newName);
    }

    // reset states
    setIsEdit(false);
    setFocusItemId(null);
  };

  const handleDelete = (isConfirm) => {
    // if isConfirm=TRUE delete item referenced by id
    if (isConfirm) {
      onAction(focusItemId, EntryAction.DELETE);
    }

    // reset states
    setFocusItemId(null);
    setIsConfirmDeleteModalOpen(false);
  };

  const FolderExplorer = ({ items, style, onContextMenu }) => {
    const sortedItems = sortHierarchyItems(items, EntryType.FILE);

    return (
      <List
        dense
        style={style}
        onContextMenu={(event) => onContextMenu(event, null)}
        disablePadding
      >
        {sortedItems.map((item, index) => (
          <React.Fragment key={index}>
            <ListItem
              button
              onContextMenu={(event) => {
                onContextMenu(event, item?.id);
              }}
            >
              <SidebarItem
                name={item.name}
                isFolder={item.tag === EntryType.FOLDER}
                isOpen={isOpen[item.id]}
                isEditActive={item.id === focusItemId && isEdit}
                onOpen={() => handleOpen(item.id)}
                onOpenSecondary={() => handleOpenToggle(item.id)}
                onEditComplete={handleRename}
                onAction={() => onAction(item.id, EntryAction.ADD_FOLDER)}
              />
            </ListItem>
            {item.children && (
              <Collapse in={isOpen[item.id]} timeout="auto" unmountOnExit>
                <FolderExplorer
                  items={item.children}
                  style={styles.child}
                  onContextMenu={onContextMenu}
                />
              </Collapse>
            )}
          </React.Fragment>
        ))}
      </List>
    );
  };

  return (
    <div style={styles.container}>
      <NavHeader onAction={onAction} />
      <div style={styles.fileNav}>
        <FolderExplorer items={items} style={styles.tree} onContextMenu={onContextMenu} />
        <ContextMenu position={cursorPosition} onClose={onContextMenuClose} />

        <ConfirmDeleteModal isOpen={isConfirmDeleteModalOpen} onSelect={handleDelete} />
      </div>
    </div>
  );
}

const styles = {
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    fontSize: 'smaller',
  },
  fileNav: {
    height: '100%',
    overflow: 'auto',
  },
  child: {
    paddingLeft: '24px',
    alignItems: 'center',
  },
  // tree: {
  //   height: '100%', // if context menu should be applied to full height of sidebar
  // },
};
