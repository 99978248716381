import React, { useCallback, useContext } from 'react';
import { AccordionDetails, Typography } from '@mui/material';
import { sessionKeyStoreEnum } from '../../../enums/sessionKeyStoreEnum';
import { SessionContext } from '../../../context';

const {
  SELECTED_MONTH,
  ACTIVE_HEADER,
  ACTIVE_QUERY,
  ACTIVE_CHILD,
  SEARCH_ID,
  PREVIEW_PAGE_COUNT,
  PREVIEW_PAGE_INDEX,
} = sessionKeyStoreEnum;

export default function Month({ index, name, query, count, isSelected = false }) {
  const { updateSessionStore } = useContext(SessionContext);

  const handleNextMonth = useCallback(
    (e) => {
      e.stopPropagation();

      updateSessionStore({
        [SELECTED_MONTH]: name,
        [ACTIVE_HEADER]: name,
        [ACTIVE_QUERY]: query,
        [ACTIVE_CHILD]: count,
        [SEARCH_ID]: Date.now(),
        [PREVIEW_PAGE_COUNT]: 1,
        [PREVIEW_PAGE_INDEX]: 0,
      });
    },
    [name, query, count],
  );

  const styles = {
    accordion: {
      backgroundColor: isSelected ? 'primary.main' : '',
      color: isSelected ? 'black' : '',
      ...monthStyles,
    },
    text: {
      display: 'flex',
      flexGrow: 1,
      justifyContent: 'space-between',
      alignItems: 'center',
      margin: '0.5em',
    },
  };

  return (
    <AccordionDetails key={index} sx={styles.accordion} onClick={handleNextMonth}>
      <div style={styles.text}>
        <Typography>{name}</Typography>
        <Typography>{count}</Typography>
      </div>
    </AccordionDetails>
  );
}

const monthStyles = {
  '&:hover': {
    background: 'var(--fluke-yellow)',
    color: 'black',
    transition: '250ms',
  },
  cursor: 'pointer',
};
