import React from 'react';
import { Button, Tooltip, useMediaQuery } from '@mui/material';

/**
 * ButtonTextIcon is a button with text and an icon
 * This is used across the website as a template button
 * Only an icon will be shown for the button on small screens
 *
 * @param {string} children - The text to be displayed on the button
 * @param {JSX.Element} icon - The icon to be displayed on the button
 * @param {function} onClick - The function to be called when the button is clicked
 * @param {string} tooltip - The text to be displayed on the tooltip
 *
 * @returns
 *   <ButtonTextIcon>
 *    <Button />
 *  </ButtonTextIcon>
 *
 */
export default function ButtonTextIcon({ children, icon, onClick, tooltip = null, ...props }) {
  const isSmallScreen = useMediaQuery('(max-width: 1200px)');

  const handleClick = (event) => {
    if (typeof onClick === 'function') {
      onClick(event);
    }
  };

  return (
    <Tooltip title={tooltip}>
      <span>
        <Button color="secondary" sx={styles.button} onClick={handleClick} {...props}>
          <div style={styles.content}>
            {icon}
            {isSmallScreen ? null : children}
          </div>
        </Button>
      </span>
    </Tooltip>
  );
}

const styles = {
  button: { color: 'white' },
  content: { display: 'flex', gap: '8px' },
};
