import React from 'react';
import { Overlay, DropZoneOverlay } from '../../../../components/Overlays';

export default function FileDragAndDrop({
  children,
  isDragActive,
  setIsDragActive,
  handleDrop = null,
  styleOverlay = null,
  disabled = false,
}) {
  const passToCallback = (newAvatar) => {
    if (handleDrop instanceof Function) handleDrop(newAvatar);
  };

  const drag = {
    preventDefault: (e) => {
      e.preventDefault();
      e.stopPropagation();
    },

    drop: (e) => {
      drag.preventDefault(e);
      if (e.dataTransfer.files && e.dataTransfer.files[0]) {
        passToCallback(e.dataTransfer.files);
      }
      drag.hoverOff(e);
    },

    hoverOn: (e) => {
      drag.preventDefault(e);
      setIsDragActive(true);
    },

    hoverOff: (e) => {
      drag.preventDefault(e);
      setIsDragActive(false);
    },
  };

  return (
    <div onDragEnter={(e) => drag.hoverOn(e)} style={styleDropChildWrapper}>
      {children}

      <Overlay
        id="upload-dropzone"
        style={{ backgroundColor: 'transparent', display: disabled && 'block' }}
      >
        <DropZoneOverlay open={isDragActive} style={styleOverlay} />

        <div
          id="upload-drop-icon-form"
          onDrop={(e) => drag.drop(e)}
          onDragOver={(e) => drag.hoverOn(e)}
          onDragLeave={(e) => drag.hoverOff(e)}
          style={{
            ...styleDropArea,
            zIndex: `${isDragActive ? '20' : ''}`,
          }}
        />
      </Overlay>
    </div>
  );
}

const styleDropChildWrapper = {
  position: 'relative',
  display: 'flex',
};

const styleDropArea = {
  height: '100%',
  width: '100%',
};
