export const AssetType = Object.freeze({
  DATE_CREATED: 'createdOn',
  TAG: 'tag',
  NAME: 'name',
  ASSET_ID: 'assetId',
  LOCATION: 'location',
  ASSIGNED_ROUTES: 'assignedRoutes',
  STATUS: 'status',
  LAST_INSPECTION: 'lastInspection',
  ACTION: 'action',

  // additional -->
  DESCRIPTION: 'description',
  MANUFACTURER: 'manufacturer',
  MODEL: 'assetModel',
  TYPE: 'assetType',
  SERIAL_NUMBER: 'serialNumber',
  ASSET_TAG_ID: 'assetTagId',
  NSN: 'nsn',
  TESTPOINTS: 'testpoints',
});
