import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import logger from '../../utils/logger';
import temperatureUnitsEnums from '../../enums/temperatureUnitsEnum';
import { columbiaAPI } from '@fluke/baseline-client-apis';

export const fetchTemperatureUnits = createAsyncThunk(
  'temperatureUnits/fetchTemperatureUnits',
  async () => {
    const response = await columbiaAPI.user.fetchTemperatureUnits();
    return response;
  },
);

export const saveTemperatureUnits = createAsyncThunk(
  'temperatureUnits/saveTemperatureUnits',
  async () => {
    const response = await columbiaAPI.user.saveTemperatureUnits();
    return response;
  },
);

const editorSlice = createSlice({
  name: 'editorData',
  initialState: {
    currentPalette: null,
    temperatureUnit: temperatureUnitsEnums.FAHRENHEIT,
    temperatureUnitFetching: false,
    temperatureUnitSaving: false,
    temperatureUnitError: {},
  },
  reducers: {
    setPalette: (state, { payload }) => {
      if (state.currentPalette !== payload) {
        state.currentPalette = payload;
      }
    },
    setTemperatureUnit: (state, { payload }) => {
      state.temperatureUnit = payload;
    },
    temperatureUnitToggled: (state) => {
      state.temperatureUnit =
        state.temperatureUnit === temperatureUnitsEnums.FAHRENHEIT
          ? temperatureUnitsEnums.CELSIUS
          : temperatureUnitsEnums.FAHRENHEIT;
    },
  },
  extraReducers: {
    [fetchTemperatureUnits.pending]: (state) => {
      state.temperatureUnitFetching = true;
    },
    [fetchTemperatureUnits.fulfilled]: (state, { payload }) => {
      state.temperatureUnitFetching = false;
      state.temperatureUnit = payload.temperature;
      state.temperatureUnitError = {};
    },
    [fetchTemperatureUnits.rejected]: (state, payload) => {
      state.temperatureUnitFetching = false;
      state.temperatureUnitError = payload.error;
    },
    [saveTemperatureUnits.pending]: (state) => {
      state.temperatureUnitSaving = true;
    },
    [saveTemperatureUnits.fulfilled]: (state) => {
      state.temperatureUnitSaving = false;
      state.temperatureUnitError = {};
    },
    [saveTemperatureUnits.rejected]: (state, payload) => {
      state.temperatureUnitSaving = false;
      state.temperatureUnitError = payload.error;
    },
  },
});

export const { setPalette, temperatureUnitToggled } = editorSlice.actions;
export default editorSlice.reducer;

/**
 * @param  {string} value
 */
export function savePalette(value) {
  return async (dispatch) => {
    try {
      dispatch(setPalette(value));
    } catch (error) {
      logger.error({ error, function: savePalette.name });
    }
  };
}

export function toggleTemperatureUnit() {
  return async (dispatch) => {
    try {
      dispatch(temperatureUnitToggled());
    } catch (error) {
      logger.error({ error, function: toggleTemperatureUnit.name });
    }
  };
}
