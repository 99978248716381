import React, { useCallback, useContext, useEffect, useState } from 'react';

// state management
import { useSelector } from 'react-redux';
import { UserContext } from '../../context/UserProvider';

// utilities
import { IRViewer, IRManager, Colorbar } from '@fluke/irviewer';
import { handleImageLoad, handleImageSave } from './utilities';

// components
import { Carousel, LevelSpanBar, EditorToolbar, PanelImageInfo, SidebarEditor } from './components';
import SavePrompt from '../../components/modals/SaveImagesPrompt';

// styles
import './styles.css';

const DEFAULT_ASSET_INFO = { id: 0, assetTagId: '', name: '', description: '' };

export default function ImageEditor() {
  const [IRViewerManager] = useState(() => new IRManager());

  const { selectedImageIds = [] } = useSelector((state) => state.images);
  const { userSettings: { temperatureUnits = 'F', currentOrganization } = {} } =
    useContext(UserContext);

  // payload
  const [firstImageId, setFirstImageId] = useState('');
  const [groupImageData, setGroupImageData] = useState({});

  // image settings and batch state
  const [sortedImages, setSortedImages] = useState([]);
  const [settings, setSettings] = useState({});
  const [batchMode, setBatchMode] = useState(false);

  // image-specific properties of leader (master) image
  const [photonotes, setPhotonotes] = useState([]);
  const [paletteData, setPaletteData] = useState({
    paletteNames: [],
    paletteAliases: {},
    palettePrettyNamesMap: {},
    paletteGradients: {},
    paletteGradient: null,
  });

  // ui
  const [activeDrawingShape, setActiveDrawingShape] = useState('');
  const [imageScale, setImageScale] = useState(1);
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  const updateSortedImages = () => {
    const nextSort = IRViewerManager.getSortedImages();
    setSortedImages(nextSort);
  };

  const updateSettings = (nextSettings) => {
    IRViewerManager.updateSettings(nextSettings);
    setSettings(IRViewerManager.getSettings());
  };

  const handleUpdateFromIRViewer = (payload) => {
    IRViewerManager.handleIRViewerPayload(payload);

    // update local state
    setSettings(IRViewerManager.getSettings());
    setGroupImageData(IRViewerManager.getGroupImageData());
    setPhotonotes(IRViewerManager.getPhotonotes());
    setPaletteData(IRViewerManager.getPaletteData());
  };

  const handleActiveImages = (activeImages = ['']) => {
    IRViewerManager.setVisibleImages(activeImages);
    setBatchMode(activeImages.length > 1);
    setSettings(IRViewerManager.getSettings());
    updateSortedImages();
    setFirstImageId(activeImages[0]);
  };

  const saveImages = () => {
    handleImageSave(groupImageData, currentOrganization);
  };

  useEffect(() => {
    IRViewerManager.setTemperatureUnits(temperatureUnits);
  }, [temperatureUnits]);

  useEffect(() => {
    // ** LOAD IMAGES **
    const loadImageById = async (id, currentOrganization) => {
      const {
        file,
        sort_info = {},
        asset_field: assetTagId = '',
      } = await handleImageLoad(id, currentOrganization);

      if (file) {
        const nextIndex = selectedImageIds.indexOf(id);
        IRViewerManager.updateGroupImageData(id, {
          sort_info,
          asset_info: { ...DEFAULT_ASSET_INFO, assetTagId },
        });
        IRViewerManager.updateLoadedData(nextIndex, id, file);
        updateSortedImages();
      }
    };

    if (selectedImageIds.length > 0) {
      for (const id of selectedImageIds) {
        loadImageById(id, currentOrganization);
      }
    }
  }, [selectedImageIds]);

  useEffect(() => {
    // screen size listener
    const handleResize = () => {
      if (window.innerWidth < 1500) {
        setIsSmallScreen(true);
      } else {
        setIsSmallScreen(false);
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleImageScale = useCallback((nextValue) => {
    const nextScale = +nextValue.toFixed(2);
    setImageScale(nextScale);
  }, []);

  const updateAssetTagId = useCallback(
    (nextAssetTagId = '') => {
      const { asset_info = {} } = groupImageData[firstImageId] ?? {};

      IRViewerManager.updateGroupImageData(firstImageId, {
        asset_info: {
          ...asset_info,
          assetTagId: nextAssetTagId,
        },
      });

      setGroupImageData(IRViewerManager.getGroupImageData());
    },
    [firstImageId, groupImageData],
  );

  const updateImageName = useCallback(
    (nextName = '') => {
      const { sort_info = {} } = groupImageData[firstImageId] ?? {};
      IRViewerManager.updateGroupImageData(firstImageId, {
        sort_info: {
          ...sort_info,
          origin_path: nextName,
        },
      });

      setGroupImageData(IRViewerManager.getGroupImageData());
    },
    [firstImageId, groupImageData],
  );

  return (
    <div style={styles.page}>
      <SavePrompt onAccept={saveImages} />
      <div id="ireditor" style={styles.ireditor}>
        <Carousel
          order={selectedImageIds}
          groupImageData={groupImageData}
          onChange={handleActiveImages}
        />

        <div style={styles.body}>
          <div style={styles.column}>
            <EditorToolbar
              settings={settings}
              onChange={updateSettings}
              onActiveShapeUpdate={setActiveDrawingShape}
              imageScale={imageScale}
              handleImageScale={handleImageScale}
            />

            <div style={styles.irviewer}>
              <div style={styles.levelspan}>
                <LevelSpanBar
                  settings={settings}
                  onChange={updateSettings}
                  linearGradient={paletteData?.paletteGradient ?? ''}
                />
              </div>
              {/* ACTIVE IMAGES BEING EDITED */}
              <div style={styles.column}>
                <div
                  style={{ ...styles.images, width: isSmallScreen ? 640 : undefined }}
                  id="batchImageEditor"
                >
                  {sortedImages.map(({ id, file = {}, visible }) => (
                    <IRViewer
                      key={`image-${id}`}
                      id={id}
                      file={file}
                      width={640}
                      height={480}
                      config={{
                        scale: imageScale,
                        visible,
                        debounce: true, // used to prevent flash of old settings
                        delay: 100,
                      }}
                      settings={visible ? settings : {}}
                      temperatureUnits={temperatureUnits}
                      activeDrawingShape={activeDrawingShape}
                      onChange={handleUpdateFromIRViewer}
                    />
                  ))}
                </div>
                {batchMode === false && (
                  <div style={{ maxWidth: 640, width: '100%' }}>
                    <PanelImageInfo
                      imageData={groupImageData[firstImageId]}
                      settings={settings}
                      photonotes={photonotes}
                      onChange={updateSettings}
                    />
                  </div>
                )}
              </div>
              <div style={styles.colorbar}>
                <Colorbar
                  min={settings?.paletteRangeMinTempC}
                  max={settings?.paletteRangeMaxTempC}
                  linearGradient={paletteData?.paletteGradient ?? ''}
                  temperatureUnit={settings?.temperatureUnit}
                  height={480 + 4} // Had to do with the border thickness
                  width={15}
                  color="white"
                  fontWeight="normal"
                />
              </div>
            </div>
          </div>
        </div>
        <div style={styles.sidebar}>
          <SidebarEditor
            settings={settings}
            batchMode={batchMode}
            imageId={firstImageId}
            order={selectedImageIds}
            groupImageData={groupImageData}
            paletteData={paletteData}
            onChange={updateSettings}
            onNameChange={updateImageName}
            updateAssetTagId={updateAssetTagId}
          />
        </div>
      </div>
    </div>
  );
}

const styles = {
  row: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  page: {
    height: '100vh',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    color: '#fff',
    backgroundColor: 'rgb(18, 18, 18)',
  },

  ireditor: {
    // irviewer + sidebars
    height: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'row',
  },
  body: {
    // canvas area, slider, colorbar,
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    padding: '18px 0',
  },
  irviewer: {
    // canvas area, slider, colorbar,
    overflowY: 'auto',
    overflowX: 'hidden',
    display: 'flex',
    flexDirection: 'row',
    padding: '18px 0',
    margin: '0 18px',
    justifyContent: 'center',
  },
  colorbar: {
    paddingRight: 10,
    font: 'normal 12px/1.5 "Helvetica Neue", Arial, sans-serif',
  },
  images: {
    // grid style
    minWidth: 640,
    minHeight: 480,
    maxHeight: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
    display: 'flex',
    flexWrap: 'wrap',
    gap: 18,
    padding: '0 8px',
    justifyContent: 'center',
  },

  sidebar: {
    overflow: 'auto',
    minWidth: '250px',
    maxWidth: '300px',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'rgba(255, 255, 255,0.05)',
  },
  levelspan: {
    maxHeight: 480,
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    alignItems: 'flex-end',
  },
};
