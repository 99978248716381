import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Dialog,
  DialogActions,
  Divider,
  DialogTitle,
  Box,
  Checkbox,
  Typography,
  FormControlLabel,
  RadioGroup,
  Radio,
  useTheme,
} from '@mui/material';

import { columbiaAPI } from '@fluke/baseline-client-apis';

import { UserContext } from '../../../../context';
import { Button } from '@common-ui/ui';
import { InfoOutlined } from '@mui/icons-material';

const DialogPanel = ({ header, children }) => (
  <div>
    <Typography>{header}</Typography>
    <Divider style={styles.divider} />
    <DialogActions sx={{ justifyContent: 'start' }}>{children}</DialogActions>
  </div>
);

export function ManageProfile() {
  const theme = useTheme();

  const navigate = useNavigate();
  const { userSettings = {}, updateUserSettings = null } = useContext(UserContext);

  const { temperatureUnits = 'C', showOrganization = false } = userSettings;

  const [open, setOpen] = useState(false);
  const [enableOrganizations, setEnableOrganizations] = useState(false);

  const handleTemperatureUnit = (e) => {
    updateUserSettings({ temperatureUnits: e.target.value });
  };

  useEffect(() => {
    const fetchData = async () => {
      if (open) {
        try {
          // TODO: this should not live here -->
          const data = await columbiaAPI?.user?.getOrganizations();

          if (data && data[0]) {
            updateUserSettings({ currentOrganization: data[0].id });
            setEnableOrganizations(true);
          }
        } catch (error) {
          console.error('Failed to fetch user data: ', error);
        }
      }
    };

    fetchData();
  }, [open]);

  const handleChecked = () => {
    // toggle
    const nextValue = !showOrganization;
    updateUserSettings({ showOrganization: nextValue });
  };

  const handleSyncAccount = () => {
    navigate('/legacy-login', {
      state: { sync: true }, // passing 'sync' prop
    });
  };

  // MUI theme-specific styles -->
  const colorTheme = {
    color: theme.palette.mode === 'light' ? 'black' : 'white',
  };

  const checkmarkThemeStyle = {
    ...styles.nopadding,
    ...colorTheme,
  };

  const radioThemeStyle = {
    ...styles.radio,
    ...colorTheme,
  };

  return (
    <Box>
      {/* menu button --> */}
      <Typography onClick={() => setOpen(true)}>Manage Profile</Typography>
      {/* show modal --> */}
      <Dialog open={open} onClose={() => setOpen(false)}>
        <div style={styles.container}>
          <DialogTitle style={styles.nopadding}>Manage Profile</DialogTitle>
          <DialogPanel header="Sync Data from Legacy Account">
            <div style={styles.orgPanel}>
              {false && (
                <FormControlLabel
                  sx={styles.nopadding}
                  control={
                    <Checkbox
                      style={checkmarkThemeStyle}
                      disabled={!enableOrganizations}
                      checked={Boolean(showOrganization)}
                      onChange={(e) => handleChecked(e.target.checked)}
                    />
                  }
                  label="Include All Images from Organization Members"
                />
              )}
              <Button
                onClick={handleSyncAccount}
                variant="contained"
                color="secondary"
                sx={styles.buttonSync}
              >
                Sync Accounts
              </Button>
              <div style={styles.info}>
                <InfoOutlined />
                <Typography variant="body2">
                  If you have an existing Baseline account, transfer previous data to this account.
                  To proceed, simply click on the 'Sync Accounts' button.
                </Typography>
              </div>
            </div>
          </DialogPanel>

          <DialogPanel header="Choose Temperature Unit">
            <RadioGroup value={temperatureUnits} onChange={handleTemperatureUnit}>
              <FormControlLabel
                value="C"
                label="Celsius"
                control={<Radio style={radioThemeStyle} />}
                style={styles.radio}
              />
              <FormControlLabel
                value="F"
                label="Fahrenheit"
                control={<Radio style={radioThemeStyle} />}
                style={styles.radio}
              />
            </RadioGroup>
          </DialogPanel>
        </div>
      </Dialog>
    </Box>
  );
}

const styles = {
  container: {
    padding: '32px',
    display: 'flex',
    flexDirection: 'column',
    gap: '18px',
  },
  nopadding: {
    margin: 0,
    paddingLeft: 0,
    paddingTop: 0,
    paddingBottom: 0,
  },
  orgPanel: {
    display: 'flex',
    flexDirection: 'column',
    gap: '18px',
  },
  buttonSync: {
    height: '32px',
    width: '150px',
    fontSize: 14,
    fontWeight: 'normal',
  },
  info: {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
  },
  radio: {
    marginBottom: '0px',
  },
  divider: {
    marginBottom: '8px',
    borderWidth: '1px',
    borderColor: '#E0E0E0',
  },
};
