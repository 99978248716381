import React, { useEffect, useState } from 'react';

import { getAllReports } from '../../../../store/slices/reportSlice';
import { useAppDispatch } from '../../../../store/configureStore';

// utilities
import { handleImageSave } from '../../utilities';

// components
import { InsertDriveFile } from '@mui/icons-material';
import ButtonTextIcon from '../../../../components/buttons/ButtonTextIcon';
import AddImagesToReport from '../Modals/AddImagesToReport';

export default function ButtonAddToReport({ order = [], groupImageData = {} }) {
  const [modalOpen, setModalOpen] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getAllReports());
  }, []);

  const handleOnClick = () => {
    handleImageSave(groupImageData);
    setModalOpen(true);
  };

  return (
    <>
      <AddImagesToReport
        open={modalOpen}
        setOpen={setModalOpen}
        order={order}
        groupImageData={groupImageData}
      />
      <ButtonTextIcon tooltip="Report" icon={<InsertDriveFile />} onClick={handleOnClick}>
        Add To Report
      </ButtonTextIcon>
    </>
  );
}
