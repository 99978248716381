import React from 'react';
import { Check, ErrorOutline, Close, SkipNext } from '@mui/icons-material';
import { IconButton, Typography } from '@common-ui/ui';

const COLOR_COMPLETE = '#28a745';
const COLOR_WATCH = '#ffc107';
const COLOR_PROBLEM = '#dc3545';
const COLOR_SKIP = '#007bff';

export function StatusButton({ children, label, style: customStyles = {}, onClick, ...rest }) {
  const handleClick = () => {
    if (typeof onClick === 'function') {
      onClick();
    }
  };

  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '8px',
    },
    defaultIcon: {
      width: '50px',
      height: '50px',
      border: '1px solid white',
      borderRadius: '50%',
    },
  };

  return (
    <div style={styles.container}>
      <IconButton
        style={{ ...styles.defaultIcon, ...customStyles }}
        onClick={handleClick}
        disableRipple
        {...rest}
      >
        {children}
      </IconButton>
      <Typography variant="body2" textAlign={'center'}>
        {label}
      </Typography>
    </div>
  );
}

export function Complete({ label, active = false, onClick }) {
  const styles = {
    iconButton: {
      border: !active && '1px solid white',
    },

    selectedButton: {
      backgroundColor: active && COLOR_COMPLETE,
    },
  };

  return (
    <StatusButton
      label={label}
      style={{ ...styles.iconButton, ...styles.selectedButton }}
      onClick={onClick}
    >
      <Check />
    </StatusButton>
  );
}

export function Watch({ label, active = false, onClick }) {
  const styles = {
    iconButton: {
      border: !active && '1px solid white',
    },

    selectedButton: {
      backgroundColor: active && COLOR_WATCH,
    },
  };

  return (
    <StatusButton
      label={label}
      style={{ ...styles.iconButton, ...styles.selectedButton }}
      onClick={onClick}
    >
      <ErrorOutline />
    </StatusButton>
  );
}

export function Problem({ label, active = false, onClick }) {
  const styles = {
    iconButton: {
      border: !active && '1px solid white',
    },

    selectedButton: {
      backgroundColor: active && COLOR_PROBLEM,
    },
  };

  return (
    <StatusButton
      label={label}
      style={{ ...styles.iconButton, ...styles.selectedButton }}
      onClick={onClick}
    >
      <Close />
    </StatusButton>
  );
}

export function Skip({ label, active = false, onClick }) {
  const styles = {
    iconButton: {
      border: !active && '1px solid white',
    },

    selectedButton: {
      backgroundColor: active && COLOR_SKIP,
    },
  };

  return (
    <StatusButton
      label={label}
      style={{ ...styles.iconButton, ...styles.selectedButton }}
      onClick={onClick}
    >
      <SkipNext />
    </StatusButton>
  );
}
