import React, { useContext } from 'react';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import ButtonTextIcon from '../../components/buttons/ButtonTextIcon';
import { ModalContext } from '../../context';
import ModalEnum from '../../components/modals/enums/ModalEnum';

/**
 * This component is the button to create a new report
 *
 * @returns {JSX.Element}
 */
export default function CreateReport() {
  const { handleModal } = useContext(ModalContext);

  return (
    <ButtonTextIcon
      variant="contained"
      color="primary"
      disableElevation
      icon={<InsertDriveFileIcon />}
      onClick={() => handleModal(ModalEnum.REPORTS_CREATE_NEW_REPORT_MODAL)}
    >
      Create Report
    </ButtonTextIcon>
  );
}
