import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// redux
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../store/configureStore';

import { clearReportInfo, getAllReports } from '../../store/slices/reportSlice';

// enums
import { reportsEnum } from '../../enums/reportsEnum';

// components
import TitleAndSearch from './TitleAndSearch';
import ReportsList from './DataGrid/ReportsList';
import GridColumns from './DataGrid/GridColumns';
import { ThemeProvider, createTheme } from '@common-ui/ui';
import { Tabs, Tab } from '@common-ui/ui';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';

const lightTheme = createTheme({
  palette: {
    mode: 'light',
  },
});

/**
 * This is the main component for the reports screen.
 * @param {*} props - The pathname of the current location
 *
 * @returns {JSX.Element}
 */
export default function Reports() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { draftReports, completedReports } = useSelector((state) => state.reportData);

  // fetch all reports if user performs a hard refresh
  useEffect(() => {
    dispatch(getAllReports());
  }, []);

  const [value, setValue] = useState(reportsEnum.OVERVIEW);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const goToReport = ({ id }) => {
    if (!id) return;
    // Clear the report info before adding new info
    // This is to prevent the previous report info from being displayed
    dispatch(clearReportInfo());

    navigate({ pathname: `/report/${id}` });
  };

  return (
    <ThemeProvider theme={lightTheme}>
      <div style={styles.container}>
        <TitleAndSearch />
        <div style={styles.main}>
          <TabContext value={value}>
            <div style={styles.tabs}>
              <Tabs value={value} onChange={handleChange}>
                <Tab value={reportsEnum.OVERVIEW} label={reportsEnum.OVERVIEW} />
                <Tab value={reportsEnum.DRAFT_REPORTS} label={reportsEnum.DRAFT_REPORTS} />
                <Tab value={reportsEnum.COMPLETED_REPORTS} label={reportsEnum.COMPLETED_REPORTS} />
              </Tabs>
            </div>
            <TabPanel sx={styles.panel} value={reportsEnum.OVERVIEW}>
              <div style={styles.overview}>
                <ReportsList
                  rows={draftReports}
                  title={reportsEnum.DRAFT_REPORTS}
                  onRowDoubleClick={goToReport}
                />
                <ReportsList
                  rows={completedReports}
                  title={reportsEnum.COMPLETED_REPORTS}
                  onRowDoubleClick={goToReport}
                />
              </div>
            </TabPanel>
            <TabPanel sx={styles.panel} value={reportsEnum.DRAFT_REPORTS}>
              <ReportsList
                rows={draftReports}
                columns={GridColumns}
                title={reportsEnum.DRAFT_REPORTS}
                onRowDoubleClick={goToReport}
              />
            </TabPanel>
            <TabPanel sx={styles.panel} value={reportsEnum.COMPLETED_REPORTS}>
              <ReportsList
                rows={completedReports}
                columns={GridColumns}
                title={reportsEnum.COMPLETED_REPORTS}
                onRowDoubleClick={goToReport}
              />
            </TabPanel>
          </TabContext>
        </div>
      </div>
    </ThemeProvider>
  );
}

const styles = {
  container: {
    backgroundColor: '#f0f0f0',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    height: '100%',
  },
  main: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  tabs: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: 'white',
  },
  panel: {
    flex: 1,
    padding: '1em 0',
  },
  overview: {
    height: '100%',
    display: 'grid',
    gridAutoRows: '1fr',
    gap: '1em',
  },
};
