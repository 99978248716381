import React from 'react';
//enums
import { reportSectionKeys } from '../../enums';
// components
import { Typography } from '@fluke/predator-report-lib/lib/components';

export default function Photonotes({ images = [], section = 0 }) {
  if (images.length === 0) return null;
  const styles = {
    container: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      gap: 0,
    },
    grid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
      gap: '10px',
    },
    image: {
      maxWidth: '100%',
      maxHeight: '100%',
      objectFit: 'cover',
    },
  };

  return (
    <div style={styles.container}>
      <Typography variant="section">{reportSectionKeys.PHOTONOTES}</Typography>

      <div style={styles.grid}>
        {images.map((image, index) => (
          <img
            src={image.src}
            alt={`Image ${index}`}
            key={`photonotes_${section}_${index}`}
            style={styles.image}
          />
        ))}
      </div>
    </div>
  );
}
