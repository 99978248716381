import React from 'react';
// mui -->
import {
  ArrowDropDown,
  ArrowDropUp,
  FolderOpenTwoTone,
  HdrAutoOutlined,
} from '@mui/icons-material';

/**
 * HierarchyIcon is a functional component that renders an icon for a file or a folder.
 * If the item is a folder, the icon includes an arrow that indicates whether the folder
 * is open or closed, and a folder icon. If the item is a file, the icon is a file icon.
 *
 * @param {boolean} [isFolder=true] - A flag indicating whether the item is a folder. If `true`, the item is a folder; otherwise, it's a file.
 * @param {boolean} [isOpen=false] - A flag indicating whether the folder is open. Only effective when `isFolder` is `true`.
 * @param {function} [onClick=null] - A function to be called when the icon is clicked. Only effective when `isFolder` is `true`.
 *
 * @returns {JSX.Element} A JSX component rendering an icon for a file or a folder.
 */
export default function HierarchyIcon({ isFolder = true, isOpen = false, onClick = () => {} }) {
  if (isFolder) {
    return (
      <div style={styles.icon} onClick={onClick}>
        {isOpen ? <ArrowDropUp /> : <ArrowDropDown />}
        <FolderOpenTwoTone sx={{ color: 'secondary.main' }} />
      </div>
    );
  }

  return <HdrAutoOutlined />;
}

const styles = {
  icon: {
    width: '48px',
    minWidth: '48px',
    maxWidth: '48px',
  },
};
