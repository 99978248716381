import React, { useContext } from 'react';
import ButtonTextIcon from '../../../components/buttons/ButtonTextIcon';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSelector } from 'react-redux';
import deleteModalEnum from '../../../enums/deleteModalEnum';
import DeleteModal from '../../../components/modals/DeleteModal';
import handleImageDeletion from '../../../utils/handleImageDeletion';
import { SessionContext, UserContext } from '../../../context';

/**
 * Delete the selected images
 * @returns {JSX.Element}
 */
export default function Delete() {
  const { selectedImageIds } = useSelector((state) => state.images);
  const { userSettings: { currentOrganization = '' } = {} } = useContext(UserContext);
  const { updateSessionStore } = useContext(SessionContext);
  return (
    <span style={{ pointerEvents: selectedImageIds.length ? true : 'none' }}>
      <DeleteModal
        header={deleteModalEnum.IMAGES_HEADER}
        body={deleteModalEnum.IMAGES_BODY}
        notification={deleteModalEnum.IMAGES_NOTIFICATION}
        apiHandler={(data) => handleImageDeletion(data, updateSessionStore, currentOrganization)}
        data={selectedImageIds}
      >
        <ButtonTextIcon icon={<DeleteIcon />} disabled={!selectedImageIds.length} tooltip="Delete">
          Delete
        </ButtonTextIcon>
      </DeleteModal>
    </span>
  );
}
