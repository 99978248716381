import React, { useContext, useEffect } from 'react';
import { ActionBar } from './ActionBar';
import SideBar from './SideBar/index';
import './styles.css';
import { useAppDispatch } from '../../store/configureStore';
import {
  setImageList,
  buildImagePreviewsObject,
  setIsLoading,
  setSelectedImageIds,
  setImagePreviews,
} from '../../store/slices/imagesSlice';

import initializePendoSession from '../../utils/analytics/pendoAnalytics';
import { SessionContext, UserContext } from '../../context';

import { imageAPI } from '@fluke/baseline-client-apis';
import { getAllReports } from '../../store/slices/reportSlice';
import styled from '@emotion/styled';
import Counts from '../ImageManagement/ActionBar/Counts/Counts';
import ImagePreviews from './ImagePreviews/ImagePreviews';

/**
 * Image Management screen
 * @returns {JSX.Element}
 */
export default function ImageManagement() {
  const dispatch = useAppDispatch();

  const { userSettings } = useContext(UserContext);
  const { sessionStore, updateSessionStore } = useContext(SessionContext);

  const { currentOrganization, previewPageSize } = userSettings;

  const {
    activeQuery = '',
    previewPageIndex,
    sorted,
    activeHeader,
    selectedYear,
    selectedMonth,
    activeChild,
    searchId,
    sorted_by,
    searchText,
  } = sessionStore;

  useEffect(() => {
    dispatch(getAllReports());

    // clear selected images on mount
    dispatch(setSelectedImageIds([]));
  }, []);

  const getSearchString = (activeQuery, searchText) => {
    if (!activeQuery) return;

    let searchString = `(${activeQuery})`;
    if (searchText != null && searchText.length > 0) {
      const searchTexts = searchText.trim().split(' ');
      const searchTextConditions = searchTexts
        .map((text) => `(search_text = ${text})`)
        .join(' or ');
      searchString = `${searchString} and (${searchTextConditions})`;
    }
    return searchString;
  };

  const fetchImagePreviews = async () => {
    let data = [];
    const searchParams = {
      search_string: getSearchString(activeQuery, searchText),
      sort_field: sorted_by,
      sort_direction: sorted,
      search_id: searchId,
      page_size: previewPageSize,
      page: previewPageIndex,
    };

    try {
      if (searchParams.search_string) {
        dispatch(setIsLoading(true));
        const { page_count, results } = await imageAPI.fetchBySearch(
          searchParams,
          currentOrganization,
        );
        data = await imageAPI.fetchPreviews(results, currentOrganization);

        // update provider page count
        updateSessionStore({ previewPageCount: page_count });
        // redux images
        dispatch(setImageList(data));
        await dispatch(buildImagePreviewsObject(data));
        dispatch(setIsLoading(false));
      }
    } catch (error) {
      console.error('Error fetching image previews:', error);
      // bad search string with no images, we subtract the page index here
      // by one or set it back to the default. this was the temp solution for now
      // but in the future it would be a good idea to revisit a better solution
      updateSessionStore({ previewPageIndex: Math.max(previewPageIndex - 1, 0) });

      dispatch(setImageList([]));
      dispatch(setImagePreviews({}));
      dispatch(setIsLoading(false));
    }
  };

  useEffect(() => {
    // pendo analytics are initialized here and
    // can be validated using pendo.validateInstall()
    // from the console
    initializePendoSession();
  }, []);

  useEffect(() => {
    //  fetch on settings update
    if (searchId) fetchImagePreviews();
    document?.querySelector('#imagePreviews')?.scrollTo(0, 0);
  }, [
    activeQuery,
    previewPageSize,
    previewPageIndex,
    sorted,
    searchId,
    activeHeader,
    selectedYear,
    selectedMonth,
    activeChild,
    searchText,
  ]);

  const Container = styled.div`
    display: grid;
    grid-template-columns: 245px 1fr;
    grid-template-rows: auto 1fr;
    height: 100vh;
    grid-template-areas:
      'counts action'
      'sidebar content';
    overflow: hidden;
    background: ${({ theme }) => theme.palette.background.default};
  `;

  return (
    <Container>
      <Counts />
      <ActionBar />
      <SideBar />
      <ImagePreviews />
    </Container>
  );
}
