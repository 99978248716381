const reportSectionKeys = Object.freeze({
  COVER: 'Cover Page',
  TABLE_OF_CONTENTS: 'Table of Contents',
  ASSET_FIELDS: 'Asset Fields',
  IMAGE: 'Image',
  IMAGE_TITLE: 'Thermal Image',
  MARKERS: 'MarkerInfo',
  DETAILS: 'Image Info',
  NOTES: 'Notes',
  PHOTONOTES: 'Photonotes',
});

export default reportSectionKeys;
