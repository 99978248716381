import React from 'react';
import routerPathEnum from '../../../enums/routerPathEnum';
import { useLocation } from 'react-router-dom';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@common-ui/ui';
import { ExpandMore } from '@mui/icons-material';
import CreateNewReportForm from './CreateNewReportForm';

/**
 *
 * The AddToNewReport component is the first step of the report modal
 * It allows a new report to be created. The state of the modal and the function to update the state are passed down from the parent component.
 * The selectedImageIds are used to create a new report.
 * @param {string} modalState - The current state of the modal, default is 'CreateNewReport'.
 * @param {Function} setModalState - Function to update the state of the modal.
 * @param {Array} selectedImageIds - Array of selected image ids.
 *
 * @returns {JSX.Element} - The first step of the report modal, wrapped in an Accordion component.
 */
export default function AddToNewReport({
  modalState = 'CreateNewReport',
  setModalState,
  selectedImageIds = [],
}) {
  const location = useLocation();
  const page = location.pathname;

  const imageList = () => {
    if (page === routerPathEnum.IMAGE_EDITOR || page === routerPathEnum.IMAGES) {
      return selectedImageIds;
    } else {
      return [];
    }
  };

  const handleSetOpen = () => {
    setModalState?.('CreateNewReport');
  };

  const formEnum = {
    TITLE: 'Create New Report',
  };

  return (
    <Accordion
      sx={accordion}
      expanded={modalState === 'CreateNewReport'}
      onChange={handleSetOpen}
      square
    >
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography variant="h6">{formEnum.TITLE}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <CreateNewReportForm imageList={imageList()} />
      </AccordionDetails>
    </Accordion>
  );
}

const accordion = {
  boxShadow: 'none',
};
