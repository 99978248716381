import React from 'react';
import { ListSubheader } from '@mui/material';
import ImageItem from './ImageItem';
import { useSelector } from 'react-redux';
import Clear from '../ActionBar/Clear';
import styled from '@emotion/styled';
import RelativeOverlay from '../../../components/Overlays/RelativeOverlay';
import NoImagesOverlay from './NoImagesOverlay';

/**
 * Display a list of image previews
 *
 * @param {None} None
 * @returns {JSX.Element}
 */
export default function ImagePreviews() {
  const Container = styled.div`
    grid-area: content;
    display: grid;
    overflow-y: scroll;
    background: ${({ theme }) => theme.palette.background.default};
  `;

  return (
    <Container id="imagePreviews">
      <Clear />
      <ImagesByMonth />
    </Container>
  );
}

/**
 * Display images grouped by month
 * @returns {JSX.Element}
 */
function ImagesByMonth() {
  const Header = styled(ListSubheader)`
    height: 62px;
    align-items: center;
    font-size: 1.25rem;
    font-weight: 300;
  `;

  const ImageContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    gap: 0.5em;
    padding: 1em;

    @media only screen and (max-width: 1436px) {
      grid-template-columns: repeat(auto-fill, minmax(197px, 1fr));
    }

    @media only screen and (max-width: 900px) {
      grid-template-columns: 1fr;
    }
  `;

  const { imagePreviews, isLoading } = useSelector((state) => state.images);

  if (isLoading) return <RelativeOverlay />;

  return Object.keys(imagePreviews).length ? (
    Object.keys(imagePreviews).map((image, index) => (
      <span key={index}>
        <Header>{image}</Header>
        <ImageContainer>
          {imagePreviews[image].map((imageItem, index) => (
            <ImageItem imageItem={imageItem} key={index} />
          ))}
        </ImageContainer>
      </span>
    ))
  ) : (
    <NoImagesOverlay />
  );
}
