import React from 'react';
import { VisibilityOutlined, VisibilityOffOutlined } from '@mui/icons-material';
import ToolButton from '../../../../../components/buttons/ToolButton';

export default function MarkerVisible({ value = true, onChange }) {
  const handleOnClick = () => {
    const nextValue = !value;
    onChange?.({ globalMarkerVisible: nextValue });
  };

  return (
    <ToolButton
      id={'tool-marker-visible'}
      tooltip={`Markers ${(value && 'Visible') || 'Hidden'}`}
      label="Visibility"
      onClick={handleOnClick}
      active={!value}
      Icon={() => (value ? <VisibilityOutlined /> : <VisibilityOffOutlined />)}
    />
  );
}
