import React, { useEffect, useState } from 'react';

// utilities
import { DateFormats, getDate } from '../../../../utils/getDateFormat';

// enums
import imageStatusNamesEnum from '../../../../enums/imageStatusNamesEnum';
import { reportInfoKeys, assetFormKeys, tableAssetKeys, tableAssetStateKeys } from '../../enums';

// components
import { FieldInput, FieldTextArea, FormTable } from '@fluke/predator-report-lib/lib/components';

// mui
import {
  CheckCircle,
  ErrorOutline,
  CancelOutlined,
  SkipNextOutlined,
  RadioButtonUnchecked,
} from '@mui/icons-material';

const getStatus = (status) => {
  const response = Object.keys(imageStatusNamesEnum)[status];
  if (response === tableAssetStateKeys.NO_STATE) return tableAssetStateKeys.DESCRIPTION;
  else return response;
};

const StatusIcon = ({ status }) => {
  let color = getStatus(status);

  switch (color) {
    case '1':
      return <CheckCircle style={{ color: '#28a745' }} />;
    case '2':
      return <ErrorOutline style={{ color: '#ffc107' }} />;
    case '3':
      return <CancelOutlined style={{ color: '#dc3545' }} />;
    case '4':
      return <SkipNextOutlined style={{ color: '#007bff' }} />;
    default:
      return <RadioButtonUnchecked style={{ color: 'gray' }} />;
  }
};

const DEFAULT_VALUES = {
  [assetFormKeys.IMAGE_ID]: '',
  [assetFormKeys.NAME]: '',
  [assetFormKeys.DATE_TAKEN]: '',
  [assetFormKeys.SITE_ID]: '',
  [assetFormKeys.EQUIPMENT]: '',
  [assetFormKeys.RECOMMENDATION]: '',
  [assetFormKeys.STATUS_CODE]: 0,
  [assetFormKeys.STATUS_TEXT]: '',
  [assetFormKeys.REASON_TEXT]: '',
};

export default function AssetFields({
  imageId,
  value = { ...DEFAULT_VALUES },
  disabled = false,
  onFieldUpdate = () => {},
}) {
  const [localValue, setLocalValue] = useState(value);

  useEffect(() => {
    // when parent passes new details
    setLocalValue(value);
  }, [value]);

  const handleChange = (e) => {
    const { id = '', value = '' } = e.target || {};
    setLocalValue((prev) => ({ ...prev, [id]: value }));
  };

  const handleSave = () => {
    const { ASSET_INFO } = reportInfoKeys;

    onFieldUpdate(imageId, ASSET_INFO, localValue);
  };

  const header = [
    tableAssetKeys.DATE,
    tableAssetKeys.LOCATION,
    tableAssetKeys.EQUIPMENT,
    tableAssetKeys.ASSET_ID,
    tableAssetKeys.STATUS,
    tableAssetKeys.REASON,
  ];

  const tableData = {
    header,
    rows: [
      // row 1
      [
        {
          span: 1,
          element: <span id="imageDate">{getDate(DateFormats.LOCAL, localValue.date_taken)}</span>,
        },
        {
          span: 1,
          element: (
            <FieldInput
              id={assetFormKeys.SITE_ID}
              value={localValue[assetFormKeys.SITE_ID]}
              onChange={handleChange}
              onBlur={handleSave}
              disabled={disabled}
            />
          ),
        },
        {
          span: 1,
          element: (
            <FieldInput
              id={assetFormKeys.EQUIPMENT}
              value={localValue[assetFormKeys.EQUIPMENT]}
              onChange={handleChange}
              onBlur={handleSave}
              disabled={disabled}
            />
          ),
        },
        {
          span: 1,
          element: (
            <FieldInput
              id={assetFormKeys.ASSET_TAG_ID}
              value={localValue[assetFormKeys.ASSET_TAG_ID]}
              disabled={disabled}
            />
          ),
        },
        {
          span: 1,
          element: (
            <span title={localValue[assetFormKeys.STATUS_TEXT]}>
              <div style={styles.status}>
                <StatusIcon status={localValue[assetFormKeys.STATUS_CODE]} />
              </div>
            </span>
          ),
        },
        {
          span: 1,
          element: (
            <span id={assetFormKeys.REASON_TEXT}>{localValue[assetFormKeys.REASON_TEXT]}</span>
          ),
        },
      ],
      // row 2
      [
        {
          span: header.length,
          element: (
            <div style={styles.spanContainer}>
              <span id="label-recommendation">Recommendation</span>
              <FieldTextArea
                id={assetFormKeys.RECOMMENDATION}
                value={localValue[assetFormKeys.RECOMMENDATION]}
                onChange={handleChange}
                onBlur={handleSave}
                disabled={disabled}
                rows={3}
              />
            </div>
          ),
        },
      ],
    ],
  };

  return (
    <div id={`reportDetail-${localValue[assetFormKeys.IMAGE_ID]}`}>
      <FormTable data={tableData} />
    </div>
  );
}

const styles = {
  title: {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
  },
  spanContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '4px',
  },
  status: {
    display: 'flex',
    justifyContent: 'center',
  },
};
