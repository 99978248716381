import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Typography } from '@common-ui/ui';
import GridColumns from './GridColumns';

/**
 * This component is the base template for all the grids on the reports screen.
 * @param {*} rows - The row data for the grid
 * @param {*} columns - The column headers for the grid
 * @param {*} title - The title of the grid
 * @param {*} pageSize - The number of rows to display per page
 *
 * @returns {JSX.Element}
 */
export default function ReportsList({ rows, title, onRowDoubleClick = () => {} }) {
  return (
    <div style={styles.container}>
      <Typography sx={styles.title}>
        {title} ({rows.length})
      </Typography>

      <DataGrid
        getRowId={(row) => row.report_id}
        rows={rows}
        columns={GridColumns}
        checkboxSelection
        rowHeight={48}
        disableSelectionOnClick
        onRowDoubleClick={onRowDoubleClick}
        sx={styles.grid}
      />
    </div>
  );
}

const styles = {
  title: {
    margin: 2,
    border: 0,
  },
  container: {
    height: '100%',
    background: 'white',
    display: 'flex',
    flexDirection: 'column',
  },
  grid: {
    border: 0,
    '& .MuiDataGrid-columnHeaders': {
      borderRadius: 0,
      backgroundColor: '#fafafa',
    },
    '& .MuiDataGrid-virtualScrollerContent': {
      background: 'white',
    },
    '& .MuiDataGrid-footerContainer': {
      background: 'white',
      borderTop: '1px solid #f5f5f5',
    },
    '& .MuiDataGrid-cell': {
      borderBottom: '1px solid #f5f5f5',
    },
    '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus': {
      outline: 'none !important',
    },
    '& .MuiTablePagination-displayedRows': {
      margin: 0,
    },
    '& .MuiDataGrid-row:hover': {
      cursor: 'pointer',
    },
  },
};
