import AWS from 'aws-sdk';
import { v4 as uuidv4 } from 'uuid';

AWS.config.update({
  region: process.env.REACT_APP_REGION,
  credentials: new AWS.CognitoIdentityCredentials({
    IdentityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
  }),
});


const logger = {
  info: (data) => {
    if (process.env.REACT_APP_LOGS === 'console') {
      console.info(data);
    } else if (process.env.REACT_APP_LOGS === 'none') {
      return;
    } else {
      const uuid = uuidv4();
      console.info(`info is generated and it has been logged with this id: ${uuid} `);
    }
  },
  error: (data) => {
    if (process.env.REACT_APP_LOGS === 'console') {
      console.error(data);
    } else if (process.env.REACT_APP_LOGS === 'none') {
      return;
    } else {
      window.setImmediate = window.setTimeout;
      const uuid = uuidv4();
      console.info(`error has been found and it has been logged with this id: ${uuid} `);
    }
  },
  debug: (data) => {
    if (process.env.REACT_APP_LOGS === 'console') {
      console.debug(data);
    } else if (process.env.REACT_APP_LOGS === 'none') {
      return;
    } else {
      const uuid = uuidv4();
      console.info(`debug message is generated and it has been logged with this id: ${uuid} `);
    }
  },
  warn: (data) => {
    if (process.env.REACT_APP_LOGS === 'console') {
      console.warn(data);
    } else if (process.env.REACT_APP_LOGS === 'none') {
      return;
    } else {
      const uuid = uuidv4();
      console.info(`warn message is generated and it has been logged with this id: ${uuid}`);
    }
  },
};

export default logger;
