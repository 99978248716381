import React from 'react';
import { useNavigate } from 'react-router-dom';

// utilities
import { printDocument } from '@fluke/predator-report-lib/lib/utils';

// enums
import routerPathEnum from '../../../enums/routerPathEnum';
// components
import MarkAsCompleteModal from '../../../components/modals/MarkAsCompleteModal';
import { Button } from '@common-ui/ui';
import { Add, Download, HomeRounded } from '@mui/icons-material';
import { Typography } from '@mui/material';

export default function SecondaryNavigation({ metadata = {}, onFinalize }) {
  const { report_name, finalized = false } = metadata;

  const navigate = useNavigate();

  const handleReturn = () => {
    navigate(routerPathEnum.REPORTS);
  };

  const handleAddImages = () => {
    navigate({ pathname: routerPathEnum.IMAGES });
  };

  const breadcrumbItems = [
    {
      id: 'breadcrumb-return-icon',
      value: <HomeRounded />,
      onClick: handleReturn,
    },
    {
      id: 'breadcrumb-return',
      value: 'Back to Reports',
      onClick: handleReturn,
    },
    {
      id: 'breadcrumb-divider',
      value: '/',
    },
    {
      id: 'breadcrumb-report-name',
      value: report_name,
    },
  ];

  return (
    <div id="secondaryNavigation" style={styles.container}>
      <div id="breadcrumb-report" style={styles.group}>
        {breadcrumbItems.map(({ id, value, onClick = () => {} }) => (
          <Typography key={id} id={id} onClick={onClick} style={styles.breadcrumb}>
            {value}
          </Typography>
        ))}
      </div>

      <div id="navbar-buttons" style={styles.group}>
        {false && (
          <Button
            variant="contained"
            color="info"
            startIcon={<Add />}
            onClick={handleAddImages}
            disabled={finalized}
          >
            Add Images
          </Button>
        )}
        <MarkAsCompleteModal finalized={finalized} onAccept={() => onFinalize?.()} />
        <Button
          variant="outlined"
          color="info"
          onClick={() => printDocument(report_name)}
          startIcon={<Download />}
        >
          Download PDF
        </Button>
      </div>
    </div>
  );
}

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px',
    borderBottom: '1px solid #E0E0E0',
  },
  group: {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
  },
  breadcrumb: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    color: '#474747',
    fontWeight: 'bold',
  },
};
