import React, { useEffect, useState } from 'react';

// components
import CarouselThumbnail from './CarouselThumbnail';
import SidebarDrawer from '../SidebarDrawer';
import { Typography, Button } from '@common-ui/ui';

export default function Carousel({ order = [], groupImageData = {}, onChange }) {
  const [localOrder, setLocalOrder] = useState([]);
  const [primaryImage, setPrimaryImage] = useState(null);
  const [batchImages, setBatchImages] = useState([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);

  useEffect(() => {
    if (!primaryImage && order.length > 0) {
      if (JSON.stringify(order) !== JSON.stringify(localOrder)) {
        setPrimaryImage(order[0]);
        setLocalOrder(order);
      }
    }
  }, [order]);

  useEffect(() => {
    if (!primaryImage) return;

    const newSelectedImages = [primaryImage, ...batchImages];
    onChange?.(newSelectedImages);
  }, [primaryImage, batchImages]);

  const handlePrimaryImage = ({ id }) => {
    if (primaryImage !== id) {
      // change primary image
      setPrimaryImage(id);
    }
    // set primary as only image
    setBatchImages([]);
  };

  const handleBatchInclude = ({ id }) => {
    setBatchImages((prev) => {
      if (prev.includes(id)) {
        // Remove the ID from the array and remove any null positions
        const updatedData = prev.filter((item) => item !== id).filter(Boolean);
        return updatedData;
      } else {
        // Append the ID to the array
        return [...prev, id];
      }
    });
  };

  const handleSelectAll = () => {
    if (localOrder.length > 0) {
      let nextBatchImages = [];
      for (const id of localOrder) {
        nextBatchImages.push(id);
      }
      setBatchImages(nextBatchImages);
    }
  };

  const handleDrawerToggle = () => {
    setIsDrawerOpen((prev) => !prev);
  };

  return (
    <SidebarDrawer isOpen={isDrawerOpen} onChange={handleDrawerToggle} width={250} closeWidth={100}>
      <div style={styles.sidebar}>
        {/* SELECTED IMAGE COUNT */}
        <div style={{ ...styles.label, display: isDrawerOpen ? 'flex' : 'none' }}>
          <Button onClick={handleSelectAll}>Select All</Button>
          <Typography>{`${batchImages.length || 1}/${order.length} Selected`}</Typography>
        </div>
        <div style={styles.imagesList}>
          {/* IMAGE PREVIEW LIST */}
          {localOrder.map((id, index) => (
            <CarouselThumbnail
              key={`carousel-image-${index}-${id}`}
              imageData={groupImageData?.[id]}
              isPrimary={primaryImage === id}
              isBatch={batchImages.includes(id)}
              onPrimary={() => handlePrimaryImage({ index, id })}
              onSecondary={() => handleBatchInclude({ index, id })}
              disableOverlay={!isDrawerOpen}
            />
          ))}
        </div>
      </div>
    </SidebarDrawer>
  );
}

const styles = {
  sidebar: {
    display: 'flex',
    flexDirection: 'column',
    width: '250px',
    overflow: 'hidden',
    padding: '8px',
  },
  imagesList: {
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    marginTop: '8px',
    flex: '1 1 auto', // This makes the imagesList take up any remaining space
  },
  label: {
    whiteSpace: 'nowrap',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    padding: '5px 10px',
    border: '2px solid white',
    borderRadius: '10px', // Adjust the value to control the roundness
  },
};
