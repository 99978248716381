import React, { useMemo } from 'react';
import { useLocation } from 'react-router';
import PrimaryNavigation from '../components/header/PrimaryNavigation/index';
import useDocumentTitle from '../hooks/useDocumentTitle';
import routerPathEnum from '../enums/routerPathEnum';

const includedUrls = [
  routerPathEnum.IMAGES,
  routerPathEnum.ASSETS,
  routerPathEnum.REPORTS,
  routerPathEnum.REPORT,
  routerPathEnum.IMAGE_EDITOR,
];

export const Layout = ({ children }) => {
  useDocumentTitle();
  const location = useLocation();

  const shouldDisplayNavigation = useMemo(() => {
    return includedUrls.some((url) => location.pathname.startsWith(url));
  }, [location.pathname, includedUrls]);

  return (
    <div style={styles.layout}>
      {shouldDisplayNavigation && <PrimaryNavigation />}
      {children}
    </div>
  );
};

const styles = {
  layout: {
    height: '100vh',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white' || '#333333',
  },
};
