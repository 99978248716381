import React, { useState, useEffect } from 'react';
// enums
import { reportInfoDataKeys } from '../../enums';
import reportCoverPageEnum from '../../enums/reportCoverPageEnum';
// components
import { CompanyLogo } from '../../components/CompanyLogo';
import { Divider, TextFieldWithLabel } from '@fluke/predator-report-lib/lib/components';

// const MAX_REPORT_NAME_LENGTH = 22;
// const MAX_DESCRIPTION_LENGTH = 1500;

const DEFAULT_VALUES = {
  data: {
    [reportInfoDataKeys.COMPANY_LOGO]: null,
    [reportInfoDataKeys.COMPANY]: '',
    [reportInfoDataKeys.SITE_ID]: '',
    [reportInfoDataKeys.REPORT_NAME]: '',
    [reportInfoDataKeys.PERFORMED_BY]: '',
    [reportInfoDataKeys.PERFORMED_ON]: '',
    [reportInfoDataKeys.CREATED_BY]: '',
    [reportInfoDataKeys.CREATED_ON]: '',
    [reportInfoDataKeys.DESCRIPTION]: '',
  },
  options: {
    finalized: false,
  },
};

export default function CoverPage({ data = {}, logo, disabled = false, onFieldUpdate = () => {} }) {
  const [localValue, setLocalValue] = useState({ ...DEFAULT_VALUES });

  const [avatarSrc, setAvatarSrc] = useState('');
  const [isCompanyLogoBusy, setCompanyLogoBusy] = useState(true);

  useEffect(() => {
    // update cover page logo
    setAvatarSrc(logo);
  }, [logo]);

  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      setLocalValue(data);
    }
  }, [data]);

  const handleLocalUpdate = (e) => {
    const { id, value } = e.target;
    setLocalValue((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  const handleSave = () => {
    const { DATA } = reportInfoDataKeys;
    onFieldUpdate(null, DATA, localValue);
  };

  const displayLoadingWhileBusy = async (asyncActionFunc) => {
    setCompanyLogoBusy(true);
    if (asyncActionFunc) {
      await asyncActionFunc();
    }
    setCompanyLogoBusy(false);
  };

  const handleAvatarUpdate = (image_blob = null) => {
    const { COMPANY_LOGO } = reportInfoDataKeys;

    const asyncActionFunc = async () => {
      let newAvatarSrc = '';

      if (image_blob) {
        newAvatarSrc = URL.createObjectURL(image_blob);
      }

      setAvatarSrc(newAvatarSrc ?? '');
      onFieldUpdate(null, COMPANY_LOGO, image_blob);
    };

    displayLoadingWhileBusy(asyncActionFunc);
  };

  useEffect(() => {
    const fetchAvatar = async () => {
      if (localValue != null) {
        const company_logo = localValue?.company_logo;
        if (company_logo) {
          setAvatarSrc(company_logo);
        }
        setCompanyLogoBusy(false);
      }
    };

    fetchAvatar();
  }, [localValue]);

  return (
    <div style={{ ...styles.container, ...styles.flexCol }}>
      <div style={styles.flexRow}>
        <CompanyLogo
          src={avatarSrc}
          onChange={handleAvatarUpdate}
          isLoading={isCompanyLogoBusy}
          disabled={disabled}
        />

        <div style={styles.flexCol}>
          <TextFieldWithLabel
            id={reportInfoDataKeys.COMPANY}
            className="print-h1"
            label={reportCoverPageEnum.COMPANY.LABEL}
            name={reportCoverPageEnum.COMPANY.NAME}
            value={localValue?.[reportInfoDataKeys.COMPANY] ?? ''}
            onChange={handleLocalUpdate}
            onBlur={handleSave}
            disabled={disabled}
          />
          <TextFieldWithLabel
            id={reportInfoDataKeys.SITE_ID}
            className="print-subtitle"
            label={reportCoverPageEnum.LOCATION.LABEL}
            name={reportCoverPageEnum.LOCATION.NAME}
            value={localValue?.[reportInfoDataKeys.SITE_ID] ?? ''}
            onChange={handleLocalUpdate}
            onBlur={handleSave}
            disabled={disabled}
          />
        </div>
      </div>

      <TextFieldWithLabel
        id={reportInfoDataKeys.REPORT_NAME}
        className="print-h2"
        label={reportCoverPageEnum.REPORT_NAME.LABEL}
        name={reportCoverPageEnum.REPORT_NAME.NAME}
        value={localValue?.[reportInfoDataKeys.REPORT_NAME] ?? ''}
        onChange={handleLocalUpdate}
        onBlur={handleSave}
        disabled={disabled}
      />
      <Divider />
      <div style={styles.flexCol}>
        <div style={styles.flexRow}>
          <TextFieldWithLabel
            style={{ flex: 2 }}
            id={reportInfoDataKeys.CREATED_BY}
            label={reportCoverPageEnum.CREATED_BY.LABEL}
            name={reportCoverPageEnum.CREATED_BY.NAME}
            value={localValue?.[reportInfoDataKeys.CREATED_BY] ?? ''}
            onChange={handleLocalUpdate}
            onBlur={handleSave}
            disabled={disabled}
          />
          <TextFieldWithLabel
            style={{ flex: 1 }}
            type="date"
            id={reportInfoDataKeys.CREATED_ON}
            label={reportCoverPageEnum.CREATED_ON.LABEL}
            name={reportCoverPageEnum.CREATED_ON.NAME}
            value={localValue?.[reportInfoDataKeys.CREATED_ON] ?? ''}
            onChange={handleLocalUpdate}
            onBlur={handleSave}
            disabled={disabled}
          />
        </div>
        <div style={styles.flexRow}>
          <TextFieldWithLabel
            style={{ flex: 2 }}
            id={reportInfoDataKeys.PERFORMED_BY}
            label={reportCoverPageEnum.PERFORMED_BY.LABEL}
            name={reportCoverPageEnum.PERFORMED_BY.NAME}
            value={localValue?.[reportInfoDataKeys.PERFORMED_BY] ?? ''}
            onChange={handleLocalUpdate}
            onBlur={handleSave}
            disabled={disabled}
          />
          <TextFieldWithLabel
            style={{ flex: 1 }}
            type="date"
            id={reportInfoDataKeys.PERFORMED_ON}
            label={reportCoverPageEnum.PERFORMED_ON.LABEL}
            name={reportCoverPageEnum.PERFORMED_ON.NAME}
            value={localValue?.[reportInfoDataKeys.PERFORMED_ON] ?? ''}
            onChange={handleLocalUpdate}
            onBlur={handleSave}
            disabled={disabled}
          />
        </div>
      </div>
      <Divider />
      <TextFieldWithLabel
        type="multiline"
        id={reportInfoDataKeys.DESCRIPTION}
        label={reportCoverPageEnum.DESCRIPTION.LABEL}
        name={reportCoverPageEnum.DESCRIPTION.NAME}
        value={localValue?.[reportInfoDataKeys.DESCRIPTION] ?? ''}
        onChange={handleLocalUpdate}
        onBlur={handleSave}
        disabled={disabled}
        showCount
        maxChar={500}
        rows={24}
      />
    </div>
  );
}

const styles = {
  container: {
    padding: '50px 0',
  },
  flexCol: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  flexRow: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    alignItems: 'center',
  },
};
