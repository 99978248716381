import React, { useState } from 'react';
import { MoreHoriz } from '@mui/icons-material';
import { Menu, MenuItem, ListItemText, ListItemIcon, IconButton } from '@common-ui/ui';

export default function MenuDropdown({ menuItems = [] }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (actionHandler) => {
    setAnchorEl(null);
    if (actionHandler instanceof Function) actionHandler();
  };

  return (
    <div className="noprint">
      <IconButton id="dropdown-edit-button" style={styleIconMenuBtn} onClick={handleClick}>
        <MoreHoriz />
      </IconButton>

      <Menu
        id="dropdown-edit-menu"
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        sx={{ minWidth: '96px' }}
        open={open}
        onClose={() => handleClose('')}
      >
        {(menuItems != null || menuItems.length == 0) &&
          menuItems.map((item, index) => (
            <MenuItem
              key={`dropdown-edit-menu-item_${index}`}
              id={`dropdown-edit-menu-item_${index}`}
              style={styleListItem}
              onClick={() => handleClose(item.onClick)}
              disableRipple
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText>{item.label}</ListItemText>
            </MenuItem>
          ))}
      </Menu>
    </div>
  );
}

const styleIconMenuBtn = {
  color: 'white',
  backgroundColor: '#00000020',
};

const styleListItem = {
  display: 'flex',
  alignItems: 'center',
  gap: '4px ',
};
