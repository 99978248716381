const emissivityCommonValues = Object.freeze({
  Aluminum: 0.3,
  Copper: 0.78,
  Iron: 0.7,
  Lead: 0.44,
  Brick: 0.93,
  Concrete: 0.92,
  Wood: 0.95,
  Glass: 0.94,
  Water: 0.96,
  Snow: 0.83,
});

export default emissivityCommonValues;
