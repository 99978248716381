import { imageAPI } from '@fluke/baseline-client-apis';

export default function beginUpload(event) {
  event.preventDefault();
  handleUpload().then((genlink) => {
    location.href = genlink;
  });
}

export const handleUpload = async () => {
  const currentOrganization =
    JSON.parse(localStorage.getItem('userData')).currentOrganization ?? '';

  const session_id = await imageAPI.getSessionID(currentOrganization);
  return `baseline:${process.env.REACT_APP_VISOR_API_BASE_URL}+files+${session_id}`;
};
