import React, { useState } from 'react';

// components
import ReportNavItem from './NavSection';
import { Box, List } from '@common-ui/ui';

export default function Sidebar({ items = [], disabled = false, onClick, onDelete }) {
  const [activeItem, setActiveItem] = useState(0);

  const handleDeleteReportDetailPage = (imageId = '') => {
    onDelete?.(imageId);
  };

  const handleOnClick = (id, index) => {
    setActiveItem(index);
    if (typeof onClick === 'function') {
      onClick?.(id);
    }
  };

  return (
    <Box id="reportSidebar" sx={styles.container}>
      <List>
        {items.map((navItem, index) => (
          <ReportNavItem
            key={index}
            item={navItem}
            navIndex={index}
            onClick={handleOnClick}
            onDelete={disabled ? null : handleDeleteReportDetailPage}
            active={activeItem === index}
          />
        ))}
      </List>
    </Box>
  );
}

const styles = {
  container: (theme) => ({
    overflow: 'auto',
    flex: 1,
    minWidth: theme.spacing(36), // 18em
    maxWidth: theme.spacing(36), // 18em
    zIndex: 10,
    backgroundColor: theme.palette.primary.gray,
    borderRight: '2px solid #e0e0e0',
    boxShadow: '0,0,0,2px',
  }),
};
