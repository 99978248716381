import { useEffect, useState } from 'react';
import { useAppDispatch } from '../store/configureStore';
import { setSession as setReduxSession } from '../store/slices/sessionSlice';

const LOCAL_STORAGE_KEY = 'sessionData';

export default function useLocalStorage(initKeyTable = {}, localStorageKey = LOCAL_STORAGE_KEY) {
  const dispatch = useAppDispatch();
  const [data, setData] = useState({});

  const hasKey = (key) => {
    return key in initKeyTable;
  };

  const updateFromStorage = async () => {
    try {
      const nextSession = JSON.parse(localStorage.getItem(localStorageKey)) ?? {};
      setData((prev) => ({ ...prev, ...nextSession }));
      return true;
    } catch (error) {
      console.error('Error loading store:', error);
    }
    return false;
  };

  const update = (modifiedKeys = {}) => {
    setData((prevData) => {
      const updatedStore = { ...prevData };
      for (const [key, value] of Object.entries(modifiedKeys)) {
        if (hasKey(key)) {
          updatedStore[key] = value;
        } else {
          console.warn(`Key ${key} is not allowed.`);
        }
      }
      return updatedStore;
    });
    return true;
  };

  const remove = (key = '') => {
    try {
      setData((prevData) => {
        if (hasKey(key)) {
          const updatedStore = { ...prevData };
          delete updatedStore[key];
          return updatedStore;
        } else {
          console.warn(`Key ${key} is not allowed.`);
          return prevData;
        }
      });
      return true;
    } catch (error) {
      console.error('Error deleting from store:', error);
    }
    return false;
  };

  const flush = () => {
    try {
      localStorage.clear();
    } catch (error) {
      console.error('Error clearing session store:', error);
    }
  };

  /**
   * Listens to state updates from state store and updates the local storage
   * and Redux session accordingly.
   *
   * If the state is initially empty and there is no existing data in the local storage,
   * initial key table is used.
   *
   * @effect
   * @param {Object} store - The current state of the store.
   */
  useEffect(() => {
    if (Object.keys(data).length === 0) {
      // no keys in current state, use localStorage
      const localStore = JSON.parse(localStorage.getItem(localStorageKey)) || {};

      if (Object.keys(localStore).length > 0) {
        setData(localStore);
      } else {
        setData(initKeyTable);
      }
    } else {
      localStorage.setItem(localStorageKey, JSON.stringify(data));
      dispatch(setReduxSession(data));
    }
  }, [data]);

  useEffect(() => {
    updateFromStorage();
  }, []);

  return {
    data,
    setData,
    update,
    remove,
    flush,
    fetchStorage: updateFromStorage,
  };
}
