export const apiEndpointEnum = Object.freeze({
  FETCH_PREVIEW: 'fetch_preview',
  COLUMBIA_INFO: 'columbia_info',
  TOP_LEVEL_INDEX: 'top_level_index',
  CREATE_SESSION: 'create_session',
  WEBSITE: 'baseline_website',
  SEARCH: 'search',
  FETCH: 'fetch',
  CREATE: 'create',
  USER_STORE: 'userstore',
  UPLOADER_VERSION: 'uploader_version',
});
