import React from 'react';
import { Overlay } from './';
import { Upload } from '@mui/icons-material';

export default function DropZoneOverlay({ open, style }) {
  return (
    <Overlay id="upload-dropzone-icon-displayed" open={open} style={{ ...styleOverlay, ...style }}>
      <Upload sx={{ fontSize: 50, color: 'white' }} />
    </Overlay>
  );
}

const styleOverlay = {
  zIndex: '10',
};
