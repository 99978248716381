import { useState, useEffect } from 'react';
import MultiWayTrie from './MultiWayTrie';
import words from './wordlist';

/**
 * A custom React hook binding the MultiWayTrie class to provide methods for instances to call search.
 *
 * @returns object containing instance methods to the MultiWayTrie
 */
export default function useAutoFill() {
  const [trie, setTrie] = useState(null);

  useEffect(() => {
    const trieInstance = new MultiWayTrie();
    words.forEach((word) => trieInstance.insert(word));
    setTrie(trieInstance);

    // Clean up function to clear trie when component unmounts
    return () => {
      setTrie(null);
    };
  }, []);

  // Return search function once trie is initialized
  const search = (prefix) => {
    if (trie) {
      return trie.search(prefix);
    } else {
      return [];
    }
  };

  return { search };
}
