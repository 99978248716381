import React from 'react';
// components
import { Typography } from '@fluke/predator-report-lib/lib/components';

export default function SectionTitle({ id = '', title }) {
  return (
    <div id={id}>
      <div id="imageName" style={styles.title}>
        <Typography variant="title">{title}</Typography>
      </div>
    </div>
  );
}

const styles = {
  title: {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
  },
};
