import React, { useEffect } from 'react';
import routerPathEnum from '../../enums/routerPathEnum';
import { SignIn } from '@common-ui/ui';
import { getBaselineJWT, getApiConfiguration, Auth } from '@fluke/baseline-client-apis';

import signInEnum from '../../enums/signInEnum';

export default function LegacyLoginScreen() {
  
  useEffect(() => {
    /*
      1. Get the currently logged in BaselineJWT
      2. Logout the user
      3. Display the Legacy User login
    */

    // Grab and store the current BaselineJWT
    const logoutAndSetupLogin = () => {    
      getBaselineJWT().then((jwt_sso)=>{
        // Force the logout of the user
        const store_auth_init = getApiConfiguration();
        
        localStorage.clear();
        sessionStorage.clear();

        localStorage.setItem('jwt_sso', jwt_sso);   
        localStorage.setItem('baselineAPIConfiguration', JSON.stringify(store_auth_init));       
      });
    }

    logoutAndSetupLogin();

  }, []);

  return (
    <SignIn
      product={signInEnum.LEGACY_BASELINE}
      description={signInEnum.LEGACY_DESCRIPTION}
      forgotPasswordPath={routerPathEnum.FORGOT_PASSWORD}
      onSignInRedirect={routerPathEnum.LEGACY_COMPLETION}
      displayLinks={false}
      Auth={Auth}
      Mode="LEGACY"
    />
  );
}

