import { imageAPI } from '@fluke/baseline-client-apis';

export default async function handleImageLoad(id, currentOrganization) {
  let payload = {};

  try {
    const data = await imageAPI.fetchById(id, currentOrganization);

    const { s3_keys = {}, sort_info = {}, asset_field = '' } = data[0];
    const { is2u = '' } = s3_keys;

    const res = await fetch(is2u);
    const file = await res.arrayBuffer();

    payload = { id, file, sort_info, asset_field };
  } catch (error) {
    console.error(`Failed to fetch image for: ${id}`, error);
  }

  return payload;
}
