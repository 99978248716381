import httpRequestMethodEnum from '../enums/httpRequestMethodEnum';
import logger from '../utils/logger';

const loadUrlToState = async (url, setState, timeout = 30000) => {
  return fetch(url, {
    method: httpRequestMethodEnum.GET.toLowerCase(),
    timeout: timeout,
  })
    .then((response) => {
      if (response.ok) {
        return response.text();
      }
      return Promise.reject(response);
    })
    .then((text) => {
      setState(text);
    })
    .catch((error) => logger.error({ error, function: 'loadUrlToState' }));
};

export default loadUrlToState;
