import React from 'react';
// enums
import { reportSectionKeys } from '../../enums';
// components
import { SectionDiv, Typography } from '@fluke/predator-report-lib/lib/components';

export default function Notes({ notes = [], section = 0 }) {
  if (notes.length === 0) return null;

  const notesUserStyle = {
    variant: 'caption',
    sx: {
      fontWeight: 'bold',
    },
  };

  const styles = {
    notes: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  };

  return (
    <SectionDiv>
      <Typography variant="section">{reportSectionKeys.NOTES}</Typography>
      <table style={{ width: '100%' }}>
        <tbody>
          {notes.map((entry, index) => (
            <tr key={`note-${section}-${index}`}>
              <td style={{ padding: '8px', borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
                <div style={styles.notes}>
                  <Typography {...notesUserStyle}>{entry.user}</Typography>
                  <Typography style={{ color: 'rgba(0, 0, 0, 0.54)' }} {...notesUserStyle}>
                    {entry.date}
                  </Typography>
                </div>
                <div>{entry.text}</div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </SectionDiv>
  );
}
