import React, { useCallback, useEffect } from 'react';

// utils
import {
  ValidSettings,
  getPaletteScaleMode,
  isAutoScaleOn,
  getDisplayTemperature,
} from '@fluke/irviewer';

// components
import { Slider, Typography } from '@mui/material';

export default function LevelSpanBar({
  settings = {},
  linearGradient = '',
  disabled = false,
  onChange,
}) {
  const {
    [ValidSettings.PALETTE_RANGE_MIN]: paletteRangeMinTempC,
    [ValidSettings.PALETTE_RANGE_MAX]: paletteRangeMaxTempC,
    [ValidSettings.PALETTE_SCALE_MODE]: paletteScaleMode = '',
    minDisplayableTempC = 0,
    maxDisplayableTempC = 100,
    coldpoint,
    hotpoint,
    temperatureUnit,
  } = settings;

  const handleDisplayTemperature = useCallback(
    (tempCelsius) => {
      return getDisplayTemperature(tempCelsius, temperatureUnit);
    },
    [temperatureUnit],
  );

  const handleOnSpanChange = (nextSetting = {}) => {
    // callback to parent updater
    if (Object.keys(nextSetting).length > 0) {
      onChange?.(nextSetting);
    }
  };

  const handleSliderChange = useCallback(
    (_event, newValues, activeThumb = 0) => {
      // when slider values have changed
      const key = [ValidSettings.PALETTE_RANGE_MIN, ValidSettings.PALETTE_RANGE_MAX][activeThumb];
      let value = +newValues[activeThumb].toFixed(1);
      handleOnSpanChange({
        [key]: value,
        [ValidSettings.PALETTE_SCALE_MODE]: getPaletteScaleMode(false),
      });
    },
    [paletteScaleMode],
  );

  useEffect(() => {
    // turn autoscale off if values are no longer autoscale

    if (
      isAutoScaleOn?.(paletteScaleMode) === true &&
      (paletteRangeMinTempC !== coldpoint || paletteRangeMaxTempC !== hotpoint)
    ) {
      handleOnSpanChange({ [ValidSettings.PALETTE_SCALE_MODE]: getPaletteScaleMode(false) });
    }
  }, [paletteRangeMinTempC, paletteRangeMaxTempC, coldpoint, hotpoint]);

  useEffect(() => {
    if (isAutoScaleOn(paletteScaleMode) === true) {
      handleOnSpanChange({
        [ValidSettings.PALETTE_RANGE_MIN]: coldpoint,
        [ValidSettings.PALETTE_RANGE_MAX]: hotpoint,
      });
    }
  }, [paletteScaleMode]);

  return (
    <div style={styles.container}>
      <div style={styles.slider}>
        <Typography variant="h6" className="rangeMinMax" style={{ whiteSpace: 'nowrap' }}>
          {handleDisplayTemperature(maxDisplayableTempC)}
        </Typography>

        <Slider
          disabled={disabled}
          disableSwap
          orientation="vertical"
          valueLabelDisplay="on"
          valueLabelFormat={(value) => handleDisplayTemperature(value)}
          onChange={handleSliderChange}
          value={[paletteRangeMinTempC, paletteRangeMaxTempC]}
          min={minDisplayableTempC}
          max={maxDisplayableTempC}
          sx={styleDoubleSlider({ background: linearGradient })}
          getAriaLabel={() => 'Always visible'}
        />

        <Typography variant="h6" className="rangeMinMax" style={{ whiteSpace: 'nowrap' }}>
          {handleDisplayTemperature(minDisplayableTempC)}
        </Typography>
      </div>
    </div>
  );
}

const styles = {
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  slider: {
    flex: 1,
    maxHeight: 480,
    paddingLeft: 52,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
};

const styleDoubleSlider = (props = {}) => {
  /**
   * Return: the MUI styles object for double slider
   */

  let {
    isAutoScaleChecked,
    background = '#00f, #f00',
    //  shouldShowCenterHandle, sliderBackgroundColor
  } = props;
  // const visibleIfTrue = (booleanValue) => (booleanValue ? 'visible' : 'hidden');

  return {
    color: '#52af77',
    width: 8,
    '& .MuiSlider-rail': {
      border: 'none',
      backgroundImage: background,
      paddingRight: 0,
    },
    '& .MuiSlider-track': {
      border: 'none',
      backgroundImage: background,
      paddingRight: 0,
    },
    '& .MuiSlider-thumb': {
      height: 24,
      width: 24,
      backgroundColor: `${isAutoScaleChecked ? '#adadad' : '#e8e8e8'}`,
      border: '2px solid #C4C4C4',
      '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
        boxShadow: 'inherit',
      },
      '&:before': {
        display: 'none',
      },
    },

    '& .MuiSlider-valueLabel': {
      width: 35,
      border: '1px solid white',
      backgroundColor: '#343434',
      '&::before': {
        right: '-10px',
        borderRight: '1px solid white',
        borderTop: '1px solid white',
      },
    },
  };
};
