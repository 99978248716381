import React from 'react';
// enums -->
import { ROOT } from '../../lib/enum/RootEnum';
// mui -->
import { Typography } from '@common-ui/ui';
import { ChevronRight, HomeRounded } from '@mui/icons-material';

/**
 * DirectoryPath is a functional component that renders a breadcrumb trail based on an
 * array of path items provided as a prop. Each item in the path is displayed as a string
 * and they are separated by a right arrow icon. If a 'Home' directory is provided, it is
 * represented by a home icon.
 *
 * An optional onClick function can be provided that will be called when a directory is clicked,
 * with the clicked directory's id passed as an argument.
 *
 *
 * @param {Object[]} [path=[]] - An array of directory objects that make up the breadcrumb trail.
 * @param {string} path[].id - The unique identifier of the directory.
 * @param {string} path[].name - The name of the directory to be displayed in the breadcrumb trail.
 * @param {function} [onClick=null] - Optional function to be called when a directory is clicked.
 *                                    If provided, the clicked directory's id is passed as an argument.
 *
 * @returns {JSX.Element} A JSX component rendering a breadcrumb trail.
 */
export default function DirectoryPath({ path = [], onClick = () => {} }) {
  return (
    <div style={styles.container}>
      {path.map((directory = {}, index) => (
        <div key={`directory-${directory.id}`} style={styles.button}>
          <Typography variant="body1" onClick={() => onClick(directory.id)} sx={styles.label}>
            {directory.name === ROOT.name ? (
              <HomeRounded style={styles.iconHome} />
            ) : (
              directory.name
            )}
          </Typography>
          {index < path.length - 1 && <ChevronRight />}
        </div>
      ))}
    </div>
  );
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    padding: '10px',
    alignItems: 'center',
  },
  button: {
    alignItems: 'center',
    color: '#333',
    marginRight: '5px',
    display: 'flex',
    flexDirection: 'row',
  },
  iconHome: {
    display: 'flex',
  },
  label: {
    cursor: 'pointer',
    color: '#007BFF',
    textDecoration: 'none',
  },
};
