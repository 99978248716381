import { EntryAction } from '../../lib/enum/EntryActionEnum';

const menuItems = [
  {
    id: EntryAction.OPEN,
    name: 'Open',
  },
  {
    id: EntryAction.RENAME,
    name: 'Rename',
  },
  {
    id: EntryAction.ADD_FOLDER,
    name: 'Add Folder',
  },
  {
    id: EntryAction.ADD_ASSET,
    name: 'Add Asset',
  },
  {
    id: EntryAction.DELETE,
    name: 'Delete',
  },
];

export default menuItems;
