import React, { useEffect, useContext } from 'react';
import { Box, Typography } from '@common-ui/ui';
import { useNavigate } from 'react-router-dom';
import routerPathEnum from '../../enums/routerPathEnum';

import { getBaselineJWT } from '@fluke/baseline-client-apis';

import { UserContext, SessionContext } from '../../context';
import { useAppDispatch } from '../../store/configureStore';
import { fetchSidebarData } from '../../store/slices/imagesSlice';
import { getAllReports } from '../../store/slices/reportSlice';

export default function LoadingScreen() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { fetchUserSettings } = useContext(UserContext);
  const { updateSessionStore, fetchSessionStore } = useContext(SessionContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getBaselineJWT();
        await fetchUserSettings();
        await fetchSessionStore();
        dispatch(fetchSidebarData(true, updateSessionStore));
        dispatch(getAllReports());
        navigate(routerPathEnum.IMAGES);
      } catch (error) {
        console.error('Error fetching user settings and session:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <Box sx={container}>
      <Typography variant="h5">Loading...</Typography>
    </Box>
  );
}

const container = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  color: 'grey.100',
  backgroundColor: 'background.paper',
};
