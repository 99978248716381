import React from 'react';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import ButtonTextIcon from '../../../buttons/ButtonTextIcon';

/**
 * This component is used to display the settings button in the primary navigation bar
 * However it currently has no functionality
 *
 * @returns {JSX.Element} - Settings button
 */

export default function Settings() {
  return <ButtonTextIcon sx={button} icon={<SettingsOutlinedIcon />} tooltip="Settings" />;
}
const button = { color: 'white' };
