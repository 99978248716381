import { reportAPI } from '@fluke/baseline-client-apis';

// utilities
import { getImageData, getImageSrcByUrl } from './utils';
import { getCurrentDate } from '../../utils/getDateFormat';

// enums
import reportInfoKeys from './enums/reportInfoEnum';
import temperatureUnitsEnums from '../../enums/temperatureUnitsEnum';

const DEFAULT_DATA = { [reportInfoKeys.CREATED_ON]: getCurrentDate() };

const DEFAULT_INFO = {
  [reportInfoKeys.DATA]: DEFAULT_DATA,
  [reportInfoKeys.IMAGES]: [],
  [reportInfoKeys.IMAGE_OPTIONS]: {},
};

export default class ReportManager {
  constructor() {
    this.currentOrganization = '';
    this.info = { ...DEFAULT_INFO };
    this.images = [];
    this.sections = [];
    this.temperatureUnits = temperatureUnitsEnums.CELSIUS;
  }

  reset() {
    this.info = { ...DEFAULT_INFO };
    this.images = [];
  }

  setOrganization(currentOrganization = '') {
    this.currentOrganization = currentOrganization;
  }

  setTemperatureUnits(temperatureUnits = temperatureUnitsEnums.CELSIUS) {
    this.temperatureUnits = temperatureUnits;
  }

  buildSections() {
    if (!this.info || !this.images) return [];

    const imageOrder = this.getImageList();

    // Process each image to get its data
    const sections = imageOrder
      .map((imageId, index) => {
        if (!this.images || !imageId) return [];

        const image = this.images[index];
        return getImageData(imageId, image, this.info, this.temperatureUnits);
      })
      .filter(Boolean); // Filter out null values

    this.sections = sections.slice();
    return sections;
  }

  syncImageNames() {
    if (!this.info || !this.images) return [];

    const { images = [], image_options = {} } = this.info;
    let reportInfo = { ...this.info };

    for (const [index, imageId] of images.entries()) {
      const prevName = image_options[imageId]?.name;
      const nextName = this.images[index]?.name;

      if (nextName != null && prevName !== nextName) {
        // name has updated
        reportInfo[reportInfoKeys.IMAGE_OPTIONS] = {
          ...image_options,
          [imageId]: {
            ...image_options[imageId],
            name: nextName,
          },
        };
      }
    }

    this.info = reportInfo;
  }

  async fetchImages() {
    const { [reportInfoKeys.IMAGE_URLS]: urls = [] } = this.info || {};

    // If there are no URLs, return early
    if (!urls.length) return [];

    // Use Promise.allSettled instead of Promise.all to handle potential rejections
    const imagesPayload = await Promise.all(
      urls.map(async (url) => {
        try {
          // Fetch image using provided URL
          const reportImage = await getImageSrcByUrl(url);

          // If the image was retrieved successfully and has an imageId, return it
          if (reportImage && reportImage.imageId) {
            return reportImage;
          }

          // If the image lacks imageId or failed to retrieve, return null
          return null;
        } catch (error) {
          // Handle errors during image retrieval
          console.error(`Error fetching image: ${url}`, error);
          return null;
        }
      }),
    );

    this.images = imagesPayload;
    this.syncImageNames();

    return imagesPayload;
  }

  set(reportInfo = {}) {
    this.info = reportInfo;
  }

  async loadReport(reportInfo = {}) {
    this.set(reportInfo);
    await this.fetchImages();

    this.buildSections();
  }

  updateInfoData(next = {}) {
    const { DATA } = reportInfoKeys;

    const prev = this.getInfoData();
    this.info[DATA] = { ...prev, ...next };
  }

  updateImageOptions(id, next = {}) {
    const { IMAGE_OPTIONS } = reportInfoKeys;

    const imageOptions = this.getImageOptions();
    const prev = this.getImageOptionsById(id);

    this.info[IMAGE_OPTIONS] = {
      ...imageOptions,
      [id]: { ...prev, ...next },
    };
  }

  updateOptions(next = {}) {
    const { OPTIONS } = reportInfoKeys;

    const prev = this.getOptions();

    this.info[OPTIONS] = {
      ...prev,
      ...next,
    };
  }

  updateAssetInfo(id, next = {}) {
    if (!id) return;
    const prev = this.getImageOptionsById(id);
    this.updateImageOptions(id, { ...prev, ...next });
  }

  setFinalized(finalized = true) {
    this.updateOptions({ finalized });
  }

  deleteImageById(imageId) {
    if (!this.info || !imageId) return false;

    const { IMAGE_OPTIONS, IMAGES } = reportInfoKeys;

    const reportImages = this.info[IMAGES].filter((id) => id !== imageId);
    const payloadImages = this.images.filter(({ imageId: id }) => id !== imageId);

    const imageOptions = { ...this.info[IMAGE_OPTIONS] };
    delete imageOptions[imageId];

    if (
      reportImages.length === this.info[IMAGES].length &&
      Object.keys(imageOptions).length === Object.keys(this.info[IMAGE_OPTIONS]).length
    ) {
      return false; // Deletion failed
    }

    this.images = payloadImages;
    this.info = {
      ...this.info,
      [IMAGES]: reportImages,
      [IMAGE_OPTIONS]: imageOptions,
    };

    return true; // Deletion successful
  }

  async saveLogo(imageBlob) {
    try {
      const reportId = this.info?.options?.id;

      if (!this.info || !reportId) return;

      const blobFile = imageBlob || new File([''], 'null');
      const key_val = { company_logo: blobFile };

      const response = await reportAPI.store(reportId, key_val, this.currentOrganization);

      let logo = null;

      if (imageBlob != null) {
        logo = URL.createObjectURL(blobFile);
      }

      this.info = {
        ...this.info,
        company_logo: logo,
      };

      return response;
    } catch (error) {
      console.error('Failed to save logo', error);
      return error;
    }
  }

  save(currentOrganization) {
    if (!this.info) return;

    currentOrganization = currentOrganization || this.currentOrganization;

    reportAPI.saveReportInfo(this.info, currentOrganization);
  }

  async fetchById(reportId = '', currentOrganization) {
    if (this.info) return;

    currentOrganization = currentOrganization || this.currentOrganization;

    const reportInfo = await reportAPI.fetchReportById(reportId, currentOrganization);
    this.set(reportInfo);
  }

  getInfo() {
    return { ...this.info };
  }

  getInfoData() {
    const { DATA } = reportInfoKeys;

    return { ...this.info[DATA] };
  }

  getImageList() {
    const { IMAGES } = reportInfoKeys;

    return this.getInfo()?.[IMAGES] || [];
  }

  getSections() {
    return this.sections.slice();
  }

  getImageOptions() {
    const { IMAGE_OPTIONS } = reportInfoKeys;

    return { ...(this.info[IMAGE_OPTIONS] || {}) };
  }

  getOptions() {
    const { OPTIONS } = reportInfoKeys;

    return { ...(this.info[OPTIONS] || {}) };
  }

  getImageOptionsById(id) {
    const imageOptions = this.getImageOptions()?.[id] || {};
    return { ...imageOptions };
  }
}
