import React from 'react';

// components
import ButtonTextIcon from '../../../../components/buttons/ButtonTextIcon';
import { GetApp } from '@mui/icons-material';

export default function ButtonExportImages({ onClick = () => {} }) {
  return (
    <ButtonTextIcon tooltip="Export Images" icon={<GetApp />} onClick={onClick}>
      Export Images
    </ButtonTextIcon>
  );
}
