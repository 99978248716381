import { imageAPI } from '@fluke/baseline-client-apis';
import { useAppDispatch } from '../store/configureStore';

import { clearSelectedImages, fetchSidebarData } from '../store/slices/imagesSlice';
import logger from '../utils/logger';

export default function handleImageDeletion(
  selectedImageIds,
  updateSessionStore,
  currentOrganization,
) {
  const fetchRequests = selectedImageIds.map((id) => imageAPI.deleteById(id, currentOrganization));

  const dispatch = useAppDispatch();
  Promise.all(fetchRequests).then((values) => {
    logger.info({ data: values, message: 'success', function: handleImageDeletion.name });
    dispatch(fetchSidebarData(true, updateSessionStore));
    dispatch(clearSelectedImages());
  });
}
