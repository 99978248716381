import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './store/configureStore';

const MOUNT_NODE = document.getElementById('root');
const root = createRoot(MOUNT_NODE);

window.BASELINE = {
  REACT_APP_ENV: process.env.REACT_APP_ENV,
  REACT_APP_PENDO_API_KEY: process.env.REACT_APP_PENDO_API_KEY,
};

root.render(
  <Provider store={store}>
    <App />
  </Provider>,
);

reportWebVitals();

export { default as Logout } from './screens/AuthScreens/Logout';
export { default as Loading } from './screens/AuthScreens/Loading';
export { default as LegacyLogin } from './screens/AuthScreens/LegacyLogin';
export { default as LegacyCompletion } from './screens/AuthScreens/LegacyCompletion';
export { default as ImageManagement } from './screens/ImageManagement/index';
export { default as ImageEditor } from './screens/ImageEditor';
export { default as Assets } from './screens/Assets';
export { default as Reports } from './screens/Reports';
export { default as ReportView } from './screens/ReportView';
export { default as Error } from './screens/Error';
export { default as ErrorBoundary } from './screens/Error/ErrorBoundary';
