const snackBarStatusEnum = Object.freeze({
  CREATED: {
    text: 'Report Created Successfully',
    visible: true,
    severity: 'success',
  },
  IMAGE_DELETED: {
    text: 'Image Deleted From Report',
    visible: true,
    severity: 'success',
  },
  REPORT_DELETED: {
    text: 'Report Deleted Successfully',
    visible: true,
    severity: 'success',
  },
  UPDATED: {
    text: 'Report Updated Successfully',
    visible: true,
    severity: 'success',
  },
  ERROR: {
    text: 'Unable to Create Report, Please Try Again',
    visible: true,
    severity: 'error',
  },
  DEFAULT: {
    text: '',
    visible: false,
    severity: 'success',
  },
});

export default snackBarStatusEnum;
