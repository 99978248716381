import React from 'react';
import { Button, Tooltip } from '@mui/material';

export default function ButtonRevert({
  disabled = false,
  style = {},
  onClick = () => {},
  ...props
}) {
  const styles = {
    container: {
      display: 'flex',
      flex: 1,
    },
    button: {
      color: 'white',
      borderColor: 'white',
      '&:hover': {
        borderColor: 'white',
      },
      ...style,
    },
  };

  return (
    <Tooltip title="Revert Changes">
      <div style={styles.container}>
        <Button
          variant="outlined"
          onClick={onClick}
          sx={styles.button}
          disabled={disabled}
          {...props}
        >
          Revert
        </Button>
      </div>
    </Tooltip>
  );
}
