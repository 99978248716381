import { createTheme } from '@common-ui/ui';

// Please refer to this object:
// https://mui.com/customization/default-theme/?expand-path=$.palette
// any stylings that will be added need to overwrite the data from this object

const DarkTheme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: '#232323',
    },
    text: {
      default: 'white',
    },
    primary: {
      main: '#ffc20e',
    },
    secondary: {
      main: '#2B7CD3',
    },
  },
});

export default DarkTheme;
