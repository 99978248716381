const tableAssetKeys = Object.freeze({
  DATE: 'Date',
  LOCATION: 'Location',
  EQUIPMENT: 'Equipment',
  ASSET_ID: 'Asset Tag ID',
  STATUS: 'Status',
  REASON: 'Reason',
});

export default tableAssetKeys;
