import React, { useState } from 'react';

// components
import ImagesGrid from './ImagesGrid';
import ModalStepper from '../../../../../components/modals/ModalSteps';
import AddToReportMix from '../../../../../components/modals/AddToReport/AddToReportMix';

/**
 * This component will show the images that can be selected in the Image Editor screen
 *
 * @param {Array} selectedImageIds - The selected image ids to be added to a report
 * @param {Array} setSelectedImageIds - The hook to set the selected image ids state
 * @returns
 */
export default function AddImagesToReport({
  open = false,
  setOpen,
  order = [],
  groupImageData = {},
}) {
  const [selectedImageIds, setSelectedImageIds] = useState([]);

  const handleImageSelection = (nextImages = []) => {
    setSelectedImageIds(nextImages);
  };

  const stepContent = {
    labels: ['Select Images', 'Build A Report'],
    content: [
      <ImagesGrid
        key={'modal-step-imagesGrid'}
        id={'modal-step-imagesGrid'}
        order={order}
        groupImageData={groupImageData}
        onChange={handleImageSelection}
      />,
      <AddToReportMix
        key={'modal-step-addToReport'}
        id={'modal-step-addToReport'}
        selectedImageIds={selectedImageIds}
      />,
    ],
  };

  return <ModalStepper open={open} setOpen={setOpen} stepContent={stepContent} />;
}
