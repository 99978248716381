// utilities
import { ValidSettings } from '@fluke/irviewer';

const areArraysEqual = (array1, array2) => {
  return JSON.stringify(array1) === JSON.stringify(array2);
};

/**
 * Utility function that compares the previous and next properties of the EditorToolbar component to determine if they are equal.
 *
 * It checks specific settings properties (ALPHA_LEVEL, PIP_MODE, PALETTE_SCALE_MODE, IR_ORIENTATION, GLOBAL_MARKER_VISIBLE),
 * state properties (onActiveMarkerTool, imageScale), function properties (onChange, handleImageScale), and whether the
 * standardMarkers array within settings is equal between the previous and next properties.
 *
 * @param {Object} prevProps - The previous properties.
 * @param {Object} nextProps - The next properties.
 * @returns {boolean} Returns a boolean value, with `true` indicating that the previous and next properties are equal
 * (no rerender necessary), and `false` indicating that they are not equal (rerender necessary).
 */
export default function arePropsEqual(prevProps, nextProps) {
  const prevSettings = prevProps.settings;
  const nextSettings = nextProps.settings;

  const keysToCheck = [
    ValidSettings.ALPHA_LEVEL,
    ValidSettings.PIP_MODE,
    ValidSettings.PALETTE_SCALE_MODE,
    ValidSettings.IR_ORIENTATION,
    // ValidSettings.MARKERS_STANDARD,
    ValidSettings.GLOBAL_MARKER_VISIBLE,
  ];

  for (const key of keysToCheck) {
    if (prevSettings[key] !== nextSettings[key]) {
      // Change detected, return false to trigger rerender
      return false;
    }
  }

  return (
    // states
    prevProps.onActiveMarkerTool === nextProps.onActiveMarkerTool &&
    prevProps.imageScale === nextProps.imageScale &&
    // functions
    prevProps.onChange === nextProps.onChange &&
    prevProps.handleImageScale === nextProps.handleImageScale &&
    // settings props
    areArraysEqual(prevSettings.standardMarkers, nextSettings.standardMarkers)
  );
}
