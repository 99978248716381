/**
 * These are the objects for the ReportModal component
 *
 * @typedef {Object} ModalEnum
 * @property {string} id - The id for the modal
 * @property {string} title - The text for the title of the modal
 */
export const ModalEnum = Object.freeze({
  IMAGE_MANAGEMENT_REPORT_MODAL: {
    id: 'ImageManagementReportModal',
    title: 'Quick Report',
  },
  IMAGE_EDITOR_REPORT_MODAL: {
    id: 'ImageEditorReportModal',
    title: 'Report',
  },
  REPORTS_CREATE_NEW_REPORT_MODAL: {
    id: 'ReportsCreateNewReportModal',
    title: 'Report',
  },
  DEFAULT: {
    id: '',
  },
});

export default ModalEnum;
