export default function buildSidebar(reportInfo = {}) {
  const { images = [], image_options = {}, options: { finalized } = {} } = reportInfo;

  const listOfImages = images.map((imageId) => ({
    id: imageId,
    imageId,
    name: image_options[imageId]?.name,
  }));

  const sidebarItems = [
    {
      name: 'Cover Page',
      id: 'cover',
    },
    {
      name: 'Table of Contents',
      id: 'toc',
    },
    {
      name: 'List of Images',
      id: 'listOfImages',
      editable: finalized === false,
      subitems: listOfImages,
    },
  ];

  return sidebarItems;
}
