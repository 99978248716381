import React, { createContext, useCallback, useMemo } from 'react';
import { columbiaAPI, userStoreAPI } from '@fluke/baseline-client-apis';
import { userValueInitTable } from '../enums/userKeyStoreEnum';

import useLocalStorage from './useLocalStorage';

const LOCAL_STORAGE_KEY = 'userData';

// Create the UserStoreContext
export const UserContext = createContext();

// Create the UserStoreProvider component
export default function UserProvider({ children }) {
  const { data, setData, update, remove } = useLocalStorage(userValueInitTable, LOCAL_STORAGE_KEY);

  const getCurrentOrganization = useCallback(async () => {
    try {
      const data = await columbiaAPI.user.getOrganizations();
      const currentOrganization = data[0]?.id ?? '';

      return currentOrganization;
    } catch (error) {
      console.error('Error fetching current organization:', error);
      return '';
    }
  }, []);

  const updateFromAPI = useCallback(async () => {
    try {
      const nextUser = (await userStoreAPI.get()) ?? userValueInitTable;
      const currentOrganization = await getCurrentOrganization();
      const nextStore = { ...nextUser, currentOrganization };

      setData((prev) => ({ ...prev, ...nextStore }));
    } catch (error) {
      console.error('Error initializing user store:', error);
    }
  }, [setData, getCurrentOrganization]);

  const handleUpdate = useCallback(
    async (modifiedKeys = {}) => {
      const hasUpdated = update(modifiedKeys);
      try {
        if (hasUpdated) {
          Object.entries(modifiedKeys).forEach(([key, value]) => {
            userStoreAPI.set(key, value);
          });
        }
      } catch (error) {
        console.error('Error updating user store:', error);
      }
    },
    [update],
  );

  const handleDelete = useCallback(
    async (key = '') => {
      const hasUpdated = remove(key);
      try {
        if (hasUpdated) {
          userStoreAPI.delete(key);
        }
      } catch (error) {
        console.error('Error deleting from store:', error);
      }
    },
    [remove],
  );

  const values = useMemo(
    () => ({
      fetchUserSettings: updateFromAPI,
      userSettings: data,
      updateUserSettings: handleUpdate,
      deleteFromUserStore: handleDelete,
    }),
    [updateFromAPI, data, handleUpdate, handleDelete],
  );

  return <UserContext.Provider value={values}>{children}</UserContext.Provider>;
}
