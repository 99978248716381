import React from 'react';
// enums -->
import { AssetColumnHeaders } from '../../lib/enum/AssetListColumnsEnum';
import { EntryType } from '../../lib/enum/EntryTypeEnum';
// mui -->
import { GridActionsCellItem } from '@mui/x-data-grid';
import { FolderOpenTwoTone, HdrAutoOutlined, MoreHoriz, QuestionMark } from '@mui/icons-material';
// components -->
import EditableCell from '../EditTableCell';
import useAutoFill from '../../lib/autofill';

export default function getColumns(isEdit = false, focusItemId = '', handleRename = () => {}) {
  const { search } = useAutoFill();

  return [
    {
      field: AssetColumnHeaders.TAG.id,
      headerName: AssetColumnHeaders.TAG.label,
      width: 24,
      renderCell: (params) => {
        if (params.value === EntryType.FOLDER) {
          return <FolderOpenTwoTone sx={{ color: 'secondary.main' }} />;
        } else if (params.value === EntryType.FILE) {
          return <HdrAutoOutlined />;
        } else {
          return <QuestionMark />;
        }
      },
    },
    {
      field: AssetColumnHeaders.NAME.id,
      headerName: AssetColumnHeaders.NAME.label,
      flex: 1,
      minWidth: 150,
      editable: false,
      renderCell: (params) => (
        <EditableCell
          params={params}
          isEditActive={isEdit && focusItemId === params.row.id}
          handleRename={handleRename}
          searchEngine={search}
        />
      ),
    },
    {
      field: AssetColumnHeaders.LOCATION.id,
      headerName: AssetColumnHeaders.LOCATION.label,
      flex: 1,
      minWidth: 150,
      editable: false,
    },
    {
      field: AssetColumnHeaders.ASSIGNED_ROUTES.id,
      headerName: AssetColumnHeaders.ASSIGNED_ROUTES.label,
      flex: 1,
      minWidth: 150,
      editable: false,
    },
    {
      field: AssetColumnHeaders.STATUS.id,
      headerName: AssetColumnHeaders.STATUS.label,
      flex: 1,
      minWidth: 150,
      editable: false,
    },
    {
      field: AssetColumnHeaders.LAST_INSPECTION.id,
      headerName: AssetColumnHeaders.LAST_INSPECTION.label,
      flex: 1,
      width: 150,
      editable: false,
    },
    {
      field: AssetColumnHeaders.ACTION.id,
      headerName: AssetColumnHeaders.ACTION.label,
      flex: 1,
      width: 150,
      editable: false,
      valueGetter: (params) => `${params.row.firstName || ''} ${params.row.lastName || ''}`,
      renderCell: () => <GridActionsCellItem icon={<MoreHoriz />} label="Edit" />,
    },
  ];
}
