// const order = [
//   'c6f15564-972d-4bd9-a06b-37bad39f5430',
//   'b021f8b0-4213-48f3-b456-a11c4f29ff95',
//   'b65773bd-d445-4aa5-aff3-97cd4d97fdee',
//   'b187bed7-1835-4190-81e7-1e625fcedde0',
//   'c23c656b-a59a-4953-89f2-87ae38eff572',
//   'ed51d54e-5a25-4731-a357-bca4b7124d51',
//   '9f724b0f-7343-4a2e-89b7-b9ea4393ef03',
//   'fd046267-12e7-49ab-96de-39c8b12087f7',
//   '98ab6a90-faaf-424a-9a7b-8372e54c6288',
//   '377a0762-11d3-4dde-a0aa-86557bd53777',
//   '59351390-591f-4552-9603-a07ac9cd0b07',
//   '1595ed74-a349-4e43-962e-d9ac40c73796',
//   'd967851f-291b-4755-a2e2-9910f4253a15',
//   '3632e262-85b1-4cf1-8fc6-85c902daccc5',
//   '45e8a6e3-654b-4ae8-9f6f-7ed392e344ae',
//   'a22baa25-d279-4bd8-a339-5de07ddfa554',
//   'ed56bad3-014b-4918-ba3f-00e0dc642eea',
//   'fc407265-7a1b-43f4-959d-f4259351b0ed',
//   'fa26f017-e8f9-4130-8520-2eef31decc26',
//   'a7a35507-b7f8-4249-94d8-438622153f31',
//   '0e123cf1-9bea-4875-85e9-d191b6e92b64',
//   '7da6c904-598c-4d9e-a72e-295509350f55',
//   '2a6ab342-d794-4716-8c30-6198867cde4c',
//   '22b9ee3b-bcfb-4b17-b650-b7ced3f3a62a',
//   'e8ddef50-31e9-48eb-a25c-fcba0e97d3be',
//   '10fe7570-5a31-4511-8ca7-e665233a49b2',
//   '460a4620-1307-495e-a4d2-2b2d5fd0c66b',
//   '1106a8dd-0698-4b7f-bb4c-5ce7568b4e3e',
//   '4d284638-036a-4165-bf9a-c671510bd31a',
//   'f249d3b2-ec02-4731-ac64-b364b88dc20c',
//   'f82275b2-616d-4c0a-a307-1b2930f54516',
//   'a25d993d-e3af-4f73-a9d4-1b56d55329ef',
//   'fde05f30-de0a-40e0-b7b9-9b3ef7346bbb',
//   '3f9d97e2-15fa-42ff-9af4-6f5c690f4a71',
//   '3a102ed7-3c3c-42be-9d55-b2cdfd8493b7',
//   '803fa6e3-b5c6-4353-8d16-cf10fbf3f233',
//   'd7b6e007-aad6-4a26-8733-3006503c211d',
//   'cfd28402-48fd-4c79-aec7-63e810c3e7c0',
//   '3721c3ac-5502-4dd0-9398-af83ff014ef8',
//   '6f4b3589-a58a-4ba5-9aee-bccb5ce5a0d7',
//   '5c649512-0e79-4bc2-b88b-aeb627f49511',
//   '14720bc7-9976-401b-94a8-595fce45977d',
//   'f48b1c34-3c8d-4846-a269-5668677378cf',
//   '83b87a8b-1c00-4f8c-90db-0bce81b75b62',
//   '86e75a46-cf9d-4c9b-b80c-c34d06f99417',
//   '89c5fb76-a2c2-4a00-8410-75a8d1d8c752',
//   '17ec92ce-e9c9-4052-b8f4-97b4e2c644b2',
//   'f8276fda-560d-4484-bfaf-878789d10e75',
//   'f50b62df-7774-4ed2-b0e2-1819ea98a3b3',
// ];

/**
 * assets is an object that represents a collection of assets. Each key is a unique identifier for an asset, and each value is an object representing the asset's details.
 *
 * @typedef {Object} Asset
 * @property {string} name - The name of the asset.
 * @property {string} location - The location of the asset.
 * @property {string[]} assignedRoutes - An array of unique identifiers representing the routes assigned to the asset.
 * @property {boolean} status - The operational status of the asset. True if the asset is operational, false otherwise.
 * @property {string} lastInspection - The date and time of the last inspection of the asset, represented as an ISO 8601 string.
 *
 * @type {Object.<string, Asset>}
 */
const assets = {
  'c6f15564-972d-4bd9-a06b-37bad39f5430': {
    name: 'Inverter',
    location: '5294 Prairieview Drive',
    assignedRoutes: ['0a6850d8-7ee1-4916-b05f-011fc19c2405'],
    status: true,
    lastInspection: '2023-05-08T19:03:20Z',
  },
  '0939e3f5-3e86-4985-9a63-d76c9c4d6415': {
    name: 'Solar Tracker',
    location: '6 Bunting Street',
    assignedRoutes: ['78c78d65-476a-4a8e-a024-a14c9b535170'],
    status: false,
    lastInspection: '2023-07-19T03:50:08Z',
  },
  'b021f8b0-4213-48f3-b456-a11c4f29ff95': {
    name: 'Combiner Box',
    location: '866 Lakewood Drive',
    assignedRoutes: ['8c103ccd-624c-4551-a1d9-97e4ff6aabf4'],
    status: true,
    lastInspection: '2023-08-18T19:26:50Z',
  },
  'b65773bd-d445-4aa5-aff3-97cd4d97fdee': {
    name: 'Disconnect Switch',
    location: '987 Bartillon Center',
    assignedRoutes: ['cfbbef2e-e33e-4165-93d0-0c0d57d108d1'],
    status: true,
    lastInspection: '2023-06-23T16:47:07Z',
  },
  'b187bed7-1835-4190-81e7-1e625fcedde0': {
    name: 'Breaker',
    location: '206 Buhler Center',
    assignedRoutes: ['5add7ea3-c01f-455e-80c6-a247860c8889'],
    status: false,
    lastInspection: '2023-11-11T03:30:02Z',
  },
  'c23c656b-a59a-4953-89f2-87ae38eff572': {
    name: 'Charge Controller',
    location: '1537 Rigney Avenue',
    assignedRoutes: ['2d183fa1-74d2-4ae8-bf58-9f7716295d45'],
    status: true,
    lastInspection: '2023-08-30T20:52:37Z',
  },
  'ed51d54e-5a25-4731-a357-bca4b7124d51': {
    name: 'Battery Pack',
    location: '872 Spohn Center',
    assignedRoutes: ['3b4d1fa6-3b9b-4a71-99a9-6cad1c16d1b2'],
    status: false,
    lastInspection: '2023-05-14T10:10:24Z',
  },
  '9f724b0f-7343-4a2e-89b7-b9ea4393ef03': {
    name: 'DC/DC Converter',
    location: '0 4th Circle',
    assignedRoutes: ['6a9bbbf9-c373-4c4d-8954-29a3ea805e1d'],
    status: true,
    lastInspection: '2023-07-17T05:57:12Z',
  },
  'fd046267-12e7-49ab-96de-39c8b12087f7': {
    name: 'Inverter',
    location: '5281 Laurel Court',
    assignedRoutes: ['75982e50-9c51-420d-a529-4c2a5de39266'],
    status: false,
    lastInspection: '2023-07-18T04:21:24Z',
  },
  '98ab6a90-faaf-424a-9a7b-8372e54c6288': {
    name: 'AC/DC Disconnect',
    location: '871 Gale Road',
    assignedRoutes: ['d5cc24b5-bccd-4724-8666-1d5de03465db'],
    status: false,
    lastInspection: '2023-08-20T10:37:35Z',
  },
  '377a0762-11d3-4dde-a0aa-86557bd53777': {
    name: 'Combiner',
    location: '367 Nova Pass',
    assignedRoutes: ['f6727e3e-4530-420d-8612-259772de19af'],
    status: false,
    lastInspection: '2023-07-12T13:29:02Z',
  },
  '59351390-591f-4552-9603-a07ac9cd0b07': {
    name: 'Batteries',
    location: '6154 Clyde Gallagher Parkway',
    assignedRoutes: ['e12109ab-b94c-4bc0-9466-ab82d2d88ab1'],
    status: true,
    lastInspection: '2023-06-06T15:42:42Z',
  },
  '1595ed74-a349-4e43-962e-d9ac40c73796': {
    name: 'Breaker Box',
    location: '70 Fallview Junction',
    assignedRoutes: ['4c0a7f43-af8d-41ce-9379-c58b975bd0bd'],
    status: false,
    lastInspection: '2023-12-06T07:13:54Z',
  },
  'd967851f-291b-4755-a2e2-9910f4253a15': {
    name: 'Fuse',
    location: '714 Hoffman Road',
    assignedRoutes: ['469f0ec4-d7c0-4ed9-bb4e-630131b00c4f'],
    status: true,
    lastInspection: '2023-11-20T07:43:07Z',
  },
  '3632e262-85b1-4cf1-8fc6-85c902daccc5': {
    name: 'DC Converter',
    location: '8275 Russell Drive',
    assignedRoutes: ['dbcbc4c5-c8f7-41e2-bb79-397ef7f786ac'],
    status: true,
    lastInspection: '2023-09-01T05:25:25Z',
  },
  '45e8a6e3-654b-4ae8-9f6f-7ed392e344ae': {
    name: 'Motor Chassis',
    location: '23 Victoria Terrace',
    assignedRoutes: ['7fdc38d3-633d-4aa2-b4eb-19ca585e6c4b'],
    status: true,
    lastInspection: '2023-12-01T08:00:49Z',
  },
  'a22baa25-d279-4bd8-a339-5de07ddfa554': {
    name: 'Motor Windings',
    location: '17044 Little Fleur Junction',
    assignedRoutes: ['84559d6a-cbcd-43d6-accd-df8ef7fdac99'],
    status: true,
    lastInspection: '2024-01-17T02:26:23Z',
  },
  'ed56bad3-014b-4918-ba3f-00e0dc642eea': {
    name: 'Rotor',
    location: '92333 Raven Center',
    assignedRoutes: ['b33ee4ee-c7d3-48fb-b289-04bc43317d50'],
    status: false,
    lastInspection: '2023-09-17T11:29:36Z',
  },
  'fc407265-7a1b-43f4-959d-f4259351b0ed': {
    name: 'Stator',
    location: '0251 Blackbird Court',
    assignedRoutes: ['6ff9b0d7-c42a-42a6-af3e-f690103d52d8'],
    status: true,
    lastInspection: '2023-08-01T07:29:01Z',
  },
  'fa26f017-e8f9-4130-8520-2eef31decc26': {
    name: 'Fan Bearing',
    location: '1 Schlimgen Center',
    assignedRoutes: ['bcd16214-b13c-49d7-8969-dc047c50b560'],
    status: false,
    lastInspection: '2023-09-15T03:31:12Z',
  },
  'a7a35507-b7f8-4249-94d8-438622153f31': {
    name: 'Terminal Junction Box',
    location: '430 Paget Way',
    assignedRoutes: ['ede14ceb-c332-4948-947e-3f5c4f368003'],
    status: true,
    lastInspection: '2023-10-28T18:35:50Z',
  },
  '0e123cf1-9bea-4875-85e9-d191b6e92b64': {
    name: 'Galvanic Isolation',
    location: '5 Goodland Parkway',
    assignedRoutes: ['ccc9b636-4689-417a-ba7b-ff4400f73259'],
    status: false,
    lastInspection: '2023-10-18T17:39:08Z',
  },
  '7da6c904-598c-4d9e-a72e-295509350f55': {
    name: 'Breaker',
    location: '669 Doe Crossing Place',
    assignedRoutes: ['35cb646c-6df4-4485-8f9f-99e8d2815a34'],
    status: false,
    lastInspection: '2023-03-30T00:16:24Z',
  },
  '2a6ab342-d794-4716-8c30-6198867cde4c': {
    name: 'Combiner Box',
    location: '73364 Derek Court',
    assignedRoutes: ['4f85f57a-6364-4b38-a2cc-c24dd87b5cfa'],
    status: true,
    lastInspection: '2023-04-21T23:34:47Z',
  },
  '22b9ee3b-bcfb-4b17-b650-b7ced3f3a62a': {
    name: 'Driver Coupling',
    location: '4498 Lunder Parkway',
    assignedRoutes: ['dd26ad1d-a950-416c-a8c1-3bd70d963565'],
    status: false,
    lastInspection: '2023-07-02T12:17:53Z',
  },
  'e8ddef50-31e9-48eb-a25c-fcba0e97d3be': {
    name: 'Motor Shaft',
    location: '94892 Daystar Trail',
    assignedRoutes: ['e8a26f81-44d4-4c4a-919c-f1b56ba25968'],
    status: false,
    lastInspection: '2023-05-19T14:29:09Z',
  },
  '10fe7570-5a31-4511-8ca7-e665233a49b2': {
    name: 'Transformer',
    location: '839 Haas Crossing',
    assignedRoutes: ['f58cb9ff-ef9b-4c30-ac61-8209506782be'],
    status: true,
    lastInspection: '2023-11-21T20:37:05Z',
  },
  '460a4620-1307-495e-a4d2-2b2d5fd0c66b': {
    name: 'Brush Commutator',
    location: '1 Lighthouse Bay Point',
    assignedRoutes: ['3f6467b4-d9d4-4405-b5cf-7963d966b7b6'],
    status: true,
    lastInspection: '2023-03-02T13:54:57Z',
  },
  '1106a8dd-0698-4b7f-bb4c-5ce7568b4e3e': {
    name: 'Vibration Dampener',
    location: '2296 Lakewood Way',
    assignedRoutes: ['1db26990-cd25-4721-a664-e64fd608de06'],
    status: true,
    lastInspection: '2023-09-04T12:15:13Z',
  },
  '4d284638-036a-4165-bf9a-c671510bd31a': {
    name: 'Transformer',
    location: '7864 Leroy Terrace',
    assignedRoutes: ['4a3e693b-f292-4f53-b08a-2af2894d4ac7'],
    status: false,
    lastInspection: '2024-01-05T10:54:27Z',
  },
  'f249d3b2-ec02-4731-ac64-b364b88dc20c': {
    name: 'Charge Controller',
    location: '7633 Hoepker Hill',
    assignedRoutes: ['e7af77f0-13e4-45f3-84ad-4ff19af452f6'],
    status: false,
    lastInspection: '2023-08-23T08:27:03Z',
  },
  'f82275b2-616d-4c0a-a307-1b2930f54516': {
    name: 'AC/DC Disconnect',
    location: '27 Burrows Terrace',
    assignedRoutes: ['384e6925-8345-4f27-91e2-daed81614b2d'],
    status: true,
    lastInspection: '2023-12-09T06:55:22Z',
  },
  'a25d993d-e3af-4f73-a9d4-1b56d55329ef': {
    name: 'Junction Box',
    location: '72932 3rd Plaza',
    assignedRoutes: ['f849b858-08b5-4b20-982d-97049dd871aa'],
    status: true,
    lastInspection: '2023-04-29T06:49:07Z',
  },
  'fde05f30-de0a-40e0-b7b9-9b3ef7346bbb': {
    name: 'Inverter',
    location: '18 Walton Point',
    assignedRoutes: ['43f56a8f-04e3-4931-bcbc-60388e827553'],
    status: true,
    lastInspection: '2023-09-13T07:26:11Z',
  },
  '3f9d97e2-15fa-42ff-9af4-6f5c690f4a71': {
    name: 'Stub Compensator',
    location: '23059 Larry Circle',
    assignedRoutes: ['104d762d-bda7-482d-a332-cdce8d8c4a0a'],
    status: true,
    lastInspection: '2023-10-28T21:11:03Z',
  },
  '3a102ed7-3c3c-42be-9d55-b2cdfd8493b7': {
    name: 'PF Correction',
    location: '4 Hudson Center',
    assignedRoutes: ['badd555e-c71d-44b8-a657-f5c0cea8b8e3'],
    status: true,
    lastInspection: '2023-10-28T02:10:46Z',
  },
  '803fa6e3-b5c6-4353-8d16-cf10fbf3f233': {
    name: 'Terminal Box',
    location: '6244 Dennis Pass',
    assignedRoutes: ['e8bca65d-7a78-4738-b66e-17744d2180fa'],
    status: true,
    lastInspection: '2023-11-09T22:59:59Z',
  },
  'd7b6e007-aad6-4a26-8733-3006503c211d': {
    name: 'Motor 3-Phase Winding',
    location: '92381 Arkansas Court',
    assignedRoutes: ['5bdbde2b-4654-46b4-bed8-4bcec3a50fc5'],
    status: false,
    lastInspection: '2023-08-08T03:30:12Z',
  },
  'cfd28402-48fd-4c79-aec7-63e810c3e7c0': {
    name: 'Motor Stator Assembly',
    location: '57 Dryden Crossing',
    assignedRoutes: ['24b31b2e-4466-4634-82f1-405c31d4ba0f'],
    status: true,
    lastInspection: '2024-01-06T01:23:55Z',
  },
  '3721c3ac-5502-4dd0-9398-af83ff014ef8': {
    name: 'Combiner Box',
    location: '54 Homewood Center',
    assignedRoutes: ['d54ca293-ad80-40d5-8d41-9e6d40a7b63a'],
    status: false,
    lastInspection: '2023-01-30T10:23:36Z',
  },
  '6f4b3589-a58a-4ba5-9aee-bccb5ce5a0d7': {
    name: 'DC/DC Converter',
    location: '446 Myrtle Center',
    assignedRoutes: ['499faf14-8e7e-435b-b759-3c274fc0023b'],
    status: true,
    lastInspection: '2023-06-10T23:47:51Z',
  },
  '5c649512-0e79-4bc2-b88b-aeb627f49511': {
    name: 'Fuse',
    location: '1961 Crescent Oaks Parkway',
    assignedRoutes: ['683633b3-15c3-46bf-9509-d641d630eebe'],
    status: true,
    lastInspection: '2023-10-11T08:40:47Z',
  },
  '14720bc7-9976-401b-94a8-595fce45977d': {
    name: 'Circuit Breaker',
    location: '152 Monument Road',
    assignedRoutes: ['cd6510d2-5f95-4488-8654-b7e0970f15c2'],
    status: false,
    lastInspection: '2023-12-13T07:18:12Z',
  },
  'f48b1c34-3c8d-4846-a269-5668677378cf': {
    name: 'Transformer',
    location: '15684 Schlimgen Center',
    assignedRoutes: ['37817be8-4ab7-42a8-aba0-f4cd10533bfd'],
    status: false,
    lastInspection: '2023-03-01T04:14:55Z',
  },
  '83b87a8b-1c00-4f8c-90db-0bce81b75b62': {
    name: 'Surge Protection',
    location: '3 Alpine Road',
    assignedRoutes: ['b55c1124-c83b-439e-bc39-84b912dfe6db'],
    status: false,
    lastInspection: '2023-02-03T08:40:51Z',
  },
  '86e75a46-cf9d-4c9b-b80c-c34d06f99417': {
    name: 'Battery',
    location: '1 Heffernan Trail',
    assignedRoutes: ['fa78acf7-718e-43eb-b77f-4c73580b5c0c'],
    status: true,
    lastInspection: '2023-04-15T06:57:52Z',
  },
  '89c5fb76-a2c2-4a00-8410-75a8d1d8c752': {
    name: 'Cooling Fan',
    location: '0389 Roxbury Point',
    assignedRoutes: ['d365fe73-1021-418f-8f95-fd661b6f1fd4'],
    status: true,
    lastInspection: '2023-09-22T20:47:45Z',
  },
  '17ec92ce-e9c9-4052-b8f4-97b4e2c644b2': {
    name: 'Hydraulic Pump regulator',
    location: '36 Ludington Hill',
    assignedRoutes: ['64701cee-4035-4e4d-9f02-31aa6a02ee64'],
    status: false,
    lastInspection: '2023-08-31T05:59:40Z',
  },
  'f8276fda-560d-4484-bfaf-878789d10e75': {
    name: 'Hydraulic Actuators',
    location: '020 Oakridge Crossing',
    assignedRoutes: ['a98cc10d-2a73-4910-ac35-03d3f832e823'],
    status: false,
    lastInspection: '2023-07-08T21:01:37Z',
  },
  'f50b62df-7774-4ed2-b0e2-1819ea98a3b3': {
    name: 'Accumulators',
    location: '0 Mifflin Hill',
    assignedRoutes: ['a2afc2b2-5b83-404b-869f-235878702c43'],
    status: true,
    lastInspection: '2023-09-20T13:40:45Z',
  },
};

export default assets;
