const words = [
  'breaker',
  'busbar',
  'combiner',
  'disconnect',
  'fuse',
  'terminal',
  'transformer',
  'generator',
  'switch gear',
  'powerline',
  'insulator',
  'capacitor',
  'motor',
  'control panel',
  'substation',
  'solar panel',
  'inverter',
  'junction box',
  'solar tracker',
  'charge controller',
  'thermal collector',
  'water heater',
  'solar pump',
  'energy storage system',
  'hydraulic pump',
  'hydraulic cylinder',
  'valve',
  'hydraulic motor',
  'hydraulic hose',
  'accumulator',
  'heat exchanger',
  'hydraulic reservoir',
  'hydraulic filter',
  'hydraulic power unit',
];

export default words;
