import React, { useMemo, useState } from 'react';
// utilities -->
import { sortHierarchyItems } from '../../lib/tree';
// enums -->
import { EntryAction } from '../../lib/enum/EntryActionEnum';
import { EntryType } from '../../lib/enum/EntryTypeEnum';
// mui -->
import { DataGrid } from '@mui/x-data-grid';
// components -->
import { ConfirmDeleteModal } from '../Modal';
import NoRowsOverlay from './NoRowsOverlay';
import getColumns from './AssetListColumns';
import ContextMenu from '../ContextMenu';

/**
 * This is a mock data for the soon to be assets list
 * Example Object:
 * {
 *   name: 'Cabinet 1',
 *   id: 'J2TYPM1',
 *   Location: 'Darwin, NT, Australia',
 *   AssignedRoutes: '',
 *   Status: 'In Progress',
 *   LastInspection: '08/09/2013',
  },
*/

/**
 * AssetsList is a functional component that renders a list of assets in a DataGrid.
 * Each asset can have various properties such as name, location, assigned routes,
 * status, and last inspection. Assets can be either files or folders.
 *
 * The AssetsList component provides context menu functionality for each asset. The
 * context menu offers various actions such as renaming or deleting the asset. If the
 * rename action is selected, the asset enters edit mode, allowing its name to be
 * changed. If the delete action is selected, a confirmation modal is displayed.
 *
 * @param {Object[]} [rows=[]] - An array of objects representing the assets to be displayed. Each object should have at least `id` and `name` properties.
 * @param {function} [onPathChange=null] - A function to be called when an asset is double-clicked. The double-clicked asset's id is passed as an argument.
 * @param {function} [onAction=null] - A function to be called when an action is selected from the context menu.
 *                                     This function is called with two arguments: the id of the asset on which the action
 *                                     was selected, and a string representing the action.
 *                                     If the rename action was selected, a third argument representing the new name is also passed.
 * @param {function} [onAddNewAsset=null] - A function to be called when the 'Add new asset' button is clicked.
 *
 * @returns {JSX.Element} A JSX component rendering a list of assets.
 */
export default function AssetsList({
  rows = [],
  onPathChange = () => {},
  onAction = () => {},
  onAddNewAsset = () => {},
}) {
  // item id for the next action to be performed
  const [focusItemId, setFocusItemId] = useState(null);
  // cursor position used for the context menu
  const [cursorPosition, setCursorPosition] = useState(null);
  // context menu variant depending on filetype
  const [menuVariant, setMenuVariant] = useState(EntryType.FOLDER);
  // tracks the current state of whether an item is being edited
  const [isEdit, setIsEdit] = useState(false);
  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] = useState(false);

  // Sort rows: folders first, then files
  const sortedRows = useMemo(() => sortHierarchyItems(rows), [rows]);

  const onContextMenuClose = (actionId = '') => {
    // close context menu on selection or click-away -->
    setCursorPosition(null);
    if (!actionId) return;

    // check if the rename action was selected
    switch (actionId) {
      case EntryAction.RENAME:
        setIsEdit(true);
        break;
      case EntryAction.DELETE:
        setIsConfirmDeleteModalOpen(true);
        break;
      default:
        onAction(focusItemId, actionId);
        setFocusItemId(null);
        break;
    }
  };

  const handleContextMenu = (event) => {
    if (!event || !event.currentTarget) return;

    // open context menu on right-click -->
    event.stopPropagation();
    event.preventDefault();
    // get the record id of the row -->
    const rowId = event.currentTarget.getAttribute('data-id');
    const { tag } = rows.find((row) => row.id === rowId) || {};

    setMenuVariant(tag);
    setFocusItemId(rowId);
    setCursorPosition({ top: event.clientY, left: event.clientX });
  };

  const handleRename = (newName = '') => {
    // pass new item name referenced by id
    if (newName) {
      onAction(focusItemId, EntryAction.RENAME, newName);
    }

    // reset states
    setIsEdit(false);
    setFocusItemId(null);
  };

  const handleDelete = (isConfirm) => {
    // if isConfirm=TRUE delete item referenced by id
    if (isConfirm) {
      onAction(focusItemId, EntryAction.DELETE);
    }

    // reset states
    setFocusItemId(null);
    setIsConfirmDeleteModalOpen(false);
  };

  const columns = getColumns(isEdit, focusItemId, handleRename);

  return (
    <div style={styles.container} onContextMenu={handleContextMenu}>
      <DataGrid
        rows={sortedRows}
        columns={columns}
        rowHeight={48}
        pageSizeOptions={[5]}
        checkboxSelection
        disableSelectionOnClick
        sx={styles.dataGrid}
        components={{
          NoRowsOverlay: () => <NoRowsOverlay onAddNewAsset={onAddNewAsset} />,
        }}
        onRowDoubleClick={(params) => {
          onPathChange(params.id);
        }}
        componentsProps={{
          row: {
            onContextMenu: handleContextMenu,
          },
        }}
      />
      <ContextMenu variant={menuVariant} position={cursorPosition} onClose={onContextMenuClose} />
      <ConfirmDeleteModal isOpen={isConfirmDeleteModalOpen} onSelect={handleDelete} />
    </div>
  );
}

const styles = {
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  dataGrid: {
    border: 0,
    '& .MuiDataGrid-columnHeaders': {
      borderRadius: 0,
      backgroundColor: '#FAFAFA',
    },
    '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus': {
      outline: 'none !important',
    },
    '& .MuiTablePagination-displayedRows': {
      margin: 0,
    },
    '& .MuiDataGrid-row:hover': {
      cursor: 'pointer',
    },
  },
};
