import React from 'react';
import { Overlay } from './';
import { CircularProgress } from '@common-ui/ui';

export default function LoadingOverlay({ open, iconStyle = {}, overlayStyle = {}, ...rest }) {
  const styles = {
    overlay: {
      ...overlayStyle,
      position: 'absolute',
    },
    icon: {
      ...iconStyle,
      color: 'white',
    },
  };

  return (
    <Overlay open={open} style={styles.overlay}>
      <CircularProgress style={styles.icon} {...rest} />
    </Overlay>
  );
}
