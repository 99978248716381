import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import routerPathEnum from '../../enums/routerPathEnum';
import { useNavigate } from 'react-router-dom';

function ErrorBoundaryWrapper({ children }) {
  const navigate = useNavigate();

  const fallbackRender = ({ error }) => {
    if (process.env.REACT_APP_ENV === 'development') {
      throw error; // let the error go through on localhost
    } else {
      navigate({
        pathname: `${routerPathEnum.FIVE_OH_FIVE}`,
        state: { error: error.message },
      });
    }
  };

  return <ErrorBoundary fallbackRender={fallbackRender}>{children}</ErrorBoundary>;
}

export default ErrorBoundaryWrapper;
