const routerPathEnum = Object.freeze({
  IMAGES: '/images',
  ASSETS: '/assets',
  LOGIN: '/login',
  ROOT: '/',
  LEGACY_LOGIN: '/legacy-login',
  LEGACY_COMPLETION: "/legacy-completion",
  LOGOUT: '/logout',
  LOADING: '/loading',
  REGISTER: '/register',
  REPORT: '/report',
  REPORTS: '/reports',
  IMAGE_EDITOR: '/imageeditor',
  FORGOT_PASSWORD: '/forgotpassword',
  FORGOT_PASSWORD_VERIFICATION: '/forgotpasswordverification',
  FORGOT_PASSWORD_CONFORMATION: '/changepasswordconfirmation',
  VERIFY_EMAIL: '/verify-email',
  FOUR_OH_FOUR: '/404',
  FIVE_OH_FIVE: '/505',
});

export default routerPathEnum;
