import React, { useCallback, useContext, useMemo } from 'react';
import { Accordion, AccordionSummary, Typography } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import Month from './Month';
import { sessionKeyStoreEnum } from '../../../enums/sessionKeyStoreEnum';
import { SessionContext } from '../../../context';

const {
  SELECTED_YEAR,
  SELECTED_MONTH,
  ACTIVE_HEADER,
  ACTIVE_QUERY,
  ACTIVE_CHILD,
  SEARCH_ID,
  PREVIEW_PAGE_COUNT,
  PREVIEW_PAGE_INDEX,
} = sessionKeyStoreEnum;

export default function Year({ count, name, query, child }) {
  const { sessionStore = {}, updateSessionStore } = useContext(SessionContext);

  const { selectedYear, selectedMonth } = sessionStore;

  const handleNextYear = useCallback(
    (e) => {
      e.stopPropagation();

      updateSessionStore({
        [SELECTED_YEAR]: name,
        [SELECTED_MONTH]: null,
        [ACTIVE_HEADER]: name,
        [ACTIVE_QUERY]: query,
        [ACTIVE_CHILD]: count,
        [SEARCH_ID]: Date.now(),
        [PREVIEW_PAGE_COUNT]: 1,
        [PREVIEW_PAGE_INDEX]: 0,
      });
    },
    [name, query, count],
  );

  const months = useMemo(() => {
    return Object.entries(child).map(([_, nextMonth], index) => (
      <Month
        key={index}
        index={index}
        name={nextMonth.name}
        query={nextMonth.query}
        count={nextMonth.count}
        isSelected={nextMonth.name === selectedMonth || child.length === 1}
      />
    ));
  }, [child, selectedMonth]);

  return (
    <Accordion expanded={name === selectedYear} onClick={handleNextYear} sx={container}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <div style={year}>
          <Typography>{name}</Typography>
          <Typography>{count}</Typography>
        </div>
      </AccordionSummary>
      {months}
    </Accordion>
  );
}

const container = {
  background: '#343434',
  position: 'relative',
  top: '-1px',
};

const year = {
  height: '100%',
  display: 'flex',
  flexGrow: 1,
  justifyContent: 'space-between',
  alignItems: 'center',
};
