import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import routerPathEnum from '../../enums/routerPathEnum';
import logo from '../../images/baseline.svg';
import { Button, Typography } from '@common-ui/ui';
import './styles.css';

const messageState = {
  '/404': {
    label: '404',
    description: 'Page Not Found',
    message: 'Looks like your measurement was off the charts!',
    // message: "We know that Fluke Corporation is headquartered in Everett, WA USA. Unfortunately we are unable to locate the page that you're looking for."
  },
  '/505': {
    label: '505',
    description: 'Server Error',
    message:
      'An unexpected error occurred and the issue has been logged. We apologize for any inconvenience.',
  },
};

function SplashFluke() {
  return (
    <div className="error-page__splash">
      <img src={logo} width="200px" />
    </div>
  );
}

export default function ErrorCode() {
  const navigate = useNavigate();
  const location = useLocation();
  const currentRoute = location.pathname;
  const error = messageState[`${currentRoute}`];

  return (
    <div className="error-page">
      <SplashFluke />
      <div className="error-page__notification-box">
        <div className="error-page__content">
          <div className="error-page__code">
            <Typography
              className="error-page__code-text"
              color="primary"
              fontSize="160px"
              margin="-50px"
            >
              {error.label}
            </Typography>

            <div className="error-page__description">
              <Typography
                id="error-page__description-text"
                color="grey.600"
                fontSize="36px"
                fontWeight="bold"
              >
                {error.description}
              </Typography>
            </div>
          </div>

          <div className="error-page__message-box">
            <Typography id="error-message" color="grey.500" fontSize="18px">
              {error.message}
            </Typography>
          </div>

          <div className="error-page__buttons">
            <Button variant="contained" onClick={navigate(-1, { replace: true })}>
              Return to Previous Page
            </Button>
            <Button
              variant="outlined"
              sx={{ backgroundColor: '#F6FAFF' }}
              onClick={() => navigate(routerPathEnum.ROOT, { replace: true })}
            >
              Go to Home Page
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
