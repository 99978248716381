const reportInfoDataKeys = Object.freeze({
  COMPANY_LOGO: 'company_logo',
  DATA: 'data',
  SITE_ID: 'site_id',
  REPORT_NAME: 'report_name',
  COMPANY: 'company',
  CREATED_BY: 'created_by',
  CREATED_ON: 'created_on',
  PERFORMED_BY: 'performed_by',
  PERFORMED_ON: 'performed_on',
  DESCRIPTION: 'description',
});

export default reportInfoDataKeys;
