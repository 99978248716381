import React, { useState, useContext } from 'react';
import { Box, Divider, Typography } from '@common-ui/ui';
import { ModalContext } from '../../../context';
import AddToNewReport from './AddToNewReport';
import AddToExistingReport from './AddToExistingReport';
/**
 * The AddToReportOptions component displays a modal that allows users to either create a new report or add to an existing report from the Image Editor screen.
 * The modal can display either the AddToNewReport or AddToExistingReport component, based on the state of the modal.
 * @param {Array} selectedImageIds - The selected image ids that will be added to a report.
 * @param {boolean} [showBothOptions=true] - A flag to indicate whether both 'Add to New Report' and 'Add to Existing Report' options should be shown. By default, it is true.
 *
 * @returns {JSX.Element} - The modal that includes either the AddToNewReport or AddToExistingReport component, or both.
 */
export default function AddToReportMix({ selectedImageIds = [], showBothOptions = true }) {
  // This is the modal context that will be used to get the title of the modal
  const { modal } = useContext(ModalContext);

  // By default, the modal will show the CreateNewReport component
  const [modalState, setModalState] = useState('CreateNewReport');

  return (
    <Box sx={styles.container}>
      <div style={styles.title}>
        <Typography align="center" variant="h5">
          {modal.title}
        </Typography>
      </div>
      <Box sx={styles.accordion}>
        <AddToNewReport
          modalState={modalState}
          setModalState={setModalState}
          selectedImageIds={selectedImageIds}
        />
        {showBothOptions && (
          <>
            <Divider>OR</Divider>
            <AddToExistingReport
              modalState={modalState}
              setModalState={setModalState}
              selectedImageIds={selectedImageIds}
            />
          </>
        )}
      </Box>
    </Box>
  );
}

const styles = {
  container: (theme) => ({
    width: '554px',
    height: '575px',
    opacity: 1,
    padding: theme.spacing(1),
    fontFamily: 'Roboto',
  }),

  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '12px',
  },

  accordion: (theme) => ({
    borderTop: `1px solid ${theme.palette.divider}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
  }),
};
