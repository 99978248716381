import { createTheme } from '@common-ui/ui';

// Please refer to this object:
// https://mui.com/customization/default-theme/?expand-path=$.palette
// any stylings that will be added need to overwrite the data from this object
const LightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#ffc20e',
    },
    secondary: {
      main: '#2B7CD3',
    },
  },
});

export default LightTheme;
