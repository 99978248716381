import React from 'react';
// mui -->
import { IconButton, InputAdornment } from '@common-ui/ui';
import { Search } from '@mui/icons-material';
// components -->
import AddNewAssetButton from '../Buttons/AddNewAsset';
import Filter from '../Filter';
import AssetTextField from '../AssetTextField';

/**
 * displays top action bar
 * @returns {JSX.Element}
 */
export default function ActionBar({ onAddNewAsset = null }) {
  return (
    <div style={styles.container}>
      <Filter />
      <div style={styles.formContainer}>
        <AssetTextField
          placeholder="Search Name, Date, or Asset ID..."
          size="small"
          sx={styles.input}
          startAdornment={
            <InputAdornment position="start">
              <IconButton edge="start">
                <Search />
              </IconButton>
            </InputAdornment>
          }
        />

        <AddNewAssetButton variant="secondary" onClick={onAddNewAsset} />
      </div>
    </div>
  );
}

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '1em',
  },
  formContainer: { display: 'flex', gap: '10px' },
  form: { display: 'flex', alignItems: 'center' },
  input: { width: 400 },
};
