import { v4 as uuidv4 } from 'uuid';
import { reportAPI, getCurrentAuthenticatedUser } from '@fluke/baseline-client-apis';
import { useContext } from 'react';
import { UserContext } from '../context';
import { DateFormats, getDate } from './getDateFormat';

export async function createReport(
  report = {
    report_name: '',
    company: '',
    site_id: '',
    performed_by: '',
    performed_on: '',
    created_on: '',
    created_by: '',
    summary: '',
  },
  new_report_id = uuidv4(),
) {
  const userData = await getCurrentAuthenticatedUser();
  return {
    report_info: '0.1',
    options: {
      id: new_report_id,
      finalized: false,
    },
    data: {
      report_name: report?.report_name,
      company: report?.company,
      site_id: report?.site_id,
      performed_by: report?.performed_by,
      performed_on: getDate(DateFormats.YMD, report.performed_on),
      created_on: getDate(DateFormats.YMD),
      created_by: userData?.userName,
    },
    images: [],
    image_options: {},
  };
}

export async function updateReport(reportInfo, image) {
  const { userSettings: { currentOrganization = '' } = {} } = useContext(UserContext);

  const { image_id, image_name, status, site_id, equipment, recommendation } = image || {};

  reportAPI
    .fetchReportById(reportInfo?.options?.id, currentOrganization)
    .then(({ images, image_options, ...rest }) => {
      if (typeof image_id == 'string') {
        if (!images.includes(image_id)) {
          images = [...images, image_id];

          const newImageOptionsEntry = {
            name: image_name,
            status: { ...status },
            site_id,
            equipment,
            recommendation,
          };

          image_options[image_id] = newImageOptionsEntry;
        }
      }

      reportInfo = {
        ...rest,
        images,
        image_options,
      };
    });

  return reportInfo;
}

export function deleteFromReportData(reportData, imageId) {
  const images = reportData.images.filter((item) => item !== imageId);
  const imageOptions = { ...reportData.image_options };
  delete imageOptions[imageId];
  return {
    ...reportData,
    images: [...images],
    image_options: {
      ...imageOptions,
    },
  };
}
