const MAX_FILE_SIZE = 1e6;

function hasExtensionBool(fileName, validExtensions) {
  /*
  regex builds an OR expression from the available extensions with escape characters
  Example: ['.jpg', '.png'] ==> /(\.jpg|\.png)$/
  */
  const regex = new RegExp('(' + validExtensions.join('|').replace(/\./g, '\\.') + ')$');
  return regex.test(fileName);
}

export async function checkFileBool(fileBlob, validExtensions) {
  return fileBlob.size <= MAX_FILE_SIZE && hasExtensionBool(fileBlob.name, validExtensions);
}

export async function checkImageURL(url) {
  try {
    const response = await fetch(url, {
      mode: 'cors',
    });

    // Check if the request was successful
    if (!response.ok) {
      console.error('Failed to fetch logo:', response.status);
      return false;
    }

    // Check if the response is an image
    const contentType = response.headers.get('content-type');

    if (!contentType || !contentType.startsWith('image/')) {
      console.warn('Company logo not found.');
      return false;
    }

    const blob = await response.blob();
    const blobSize = blob.size;

    if (blobSize < 1) {
      console.warn('Image size is too small:', blobSize);
      return false;
    }

    return true;
  } catch (error) {
    console.error('Error checking image URL:', error);
    return false;
  }
}
