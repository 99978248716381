import { apiEndpointEnum } from '../enums/apiEndpointEnum';
import httpRequestMethodEnum from '../enums/httpRequestMethodEnum';
import logger from '../utils/logger';

/**
 * Fetch latest uploader version details
 * @returns JSON response
 */
export const getLatestUploader = async () => {
  const url = `${process.env.REACT_APP_VISOR_API_BASE_URL}/${apiEndpointEnum.UPLOADER_VERSION}`;
  logger.info({
    url: url,
    function: getLatestUploader?.name,
    method: httpRequestMethodEnum.GET,
  });
  const res = await fetch(url);
  return await res.json();
};
