import React, { useEffect } from 'react';
import { Box, Typography } from '@common-ui/ui';
import { useNavigate } from 'react-router-dom';
//import { flushPendo } from '../../utils/analytics/pendoAnalytics';
//import routerPathEnum from '../../enums/routerPathEnum';
import { getBaselineJWT, imageAPI } from '@fluke/baseline-client-apis';


export default function LegacyCompletionScreen() {
  const navigate = useNavigate();

  useEffect(() => {
    /*
      4. Get the legacy BaselineJWT
      5. Send both BaselineJWTs to the Image Access - Change Ownership 
      6. Logout
      7. Send the user to the main login page again.
    */

    const timeout = async (delay) => {
      return new Promise( res => setTimeout(res, delay) );
    }

    const logoutAndSetupLogin = async () => {
      // Get the legacy BaselineJWT
      const current_jwt = await getBaselineJWT();
      const previous_jwt = localStorage.getItem('jwt_sso')

      if (process.env.REACT_APP_ENV === 'development' ||
          process.env.REACT_APP_ENV === 'test')
      {
        // USEFUL FOR Testing and Debugging, but won't show in Production.
        console.log(`from_jwt=${current_jwt}`);
        console.log(`to_jwt=${previous_jwt}`);
      }

      await imageAPI.transferOwnership(previous_jwt);

      await timeout(5000);

      // Logout
      sessionStorage.clear();
      localStorage.clear();
      navigate("/");
    }

    logoutAndSetupLogin();

  }, []);

  return (
    <Box sx={container}>
      <Typography variant="h4">Transferring Account Info...</Typography>
    </Box>
  );
}

const container = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  color: 'grey.100',
  backgroundColor: 'black',
};