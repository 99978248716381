import { formatDateTimeWithTimeZone } from '../../../utils/getDateFormat';

function getDetails(main = {}, reportInfo = {}) {
  if (!Object.keys(main)?.length || !Object.keys(reportInfo)?.length) return;

  const { image_id, date_taken_milliseconds, assessment } = main;

  const {
    site_id = '',
    equipment = '',
    recommendation = '',
    name = '',
  } = reportInfo?.image_options?.[image_id] || {};

  const { status_number, status_text, reason_code_number, reason_code_text } = assessment || {};

  const details = {
    image_id,
    name,
    date_taken: date_taken_milliseconds,
    site_id,
    equipment,
    recommendation,
    status_code: status_number,
    status_text: status_text,
    reason_code: reason_code_number,
    reason_text: reason_code_text,
  };

  return details;
}

function getMarkers(marker_info, units = 'C') {
  units = typeof units === 'string' && units.toUpperCase().startsWith('F') ? 'F' : 'C';

  const markers = marker_info?.map((marker) => ({
    label: marker?.markername,
    temperature: `${marker?.maxtemperature?.[units]} °${units}` || '--',
    delta: marker?.delta?.[units] || '--',
    reference: marker?.reference ? 'x' : '',
  }));

  return markers;
}

function getInfo(image_info, timezone = 'America/Los_Angeles', units = 'C') {
  units = typeof units === 'string' && units.toUpperCase().startsWith('F') ? 'F' : 'C';

  const tempUnits = {
    C: '°C',
    F: '°F',
  };

  let info = [];

  image_info &&
    image_info.forEach((row) => {
      const { type, value } = row;

      if (type === 'temperature') {
        info.push({
          name: row.name,
          value: value[units] !== null ? `${value[units]} ${tempUnits[units]}` : '--',
        });
        return;
      }

      if (type === 'temperature_range') {
        // 6/11/2024 - Guide Images don't provide Calibration Range
        //             Therefore, we need account for when the value is null
        const minTemp = value?.['FROM']?.[units];
        const maxTemp = value?.['TO']?.[units];
        info.push({
          name: row.name,
          value:
            minTemp != null && maxTemp != null
              ? `${minTemp} - ${maxTemp} ${tempUnits[units]}`
              : '--',
        });
        return;
      }

      if (type === 'milliseconds') {
        info.push({
          name: row.name,
          value: value ? formatDateTimeWithTimeZone(value, timezone) : '--',
        });
        return;
      }

      info.push({ name: row.name, value: value || '--' });
    });

  return info;
}

function getNotes(notes = []) {
  const noteEntries = notes.map((entry) => ({
    user: entry.user,
    date: formatDateTimeWithTimeZone(entry.date),
    text: entry.text,
  }));

  return noteEntries;
}

export default function getImageData(
  imageId = '',
  image = {},
  reportInfo = {},
  temperatureUnits = 'C',
) {
  if (!image || !imageId) return;

  if (!image) return;

  const { photonotes, preview, visual, imageJSON } = image || {};
  const { main, marker_info, image_info, notes } = imageJSON || {};

  const markers = getMarkers(marker_info, temperatureUnits);
  const info = getInfo(image_info, main?.user_timezone, temperatureUnits);
  const noteEntries = getNotes(notes);
  const details = getDetails(main, reportInfo);

  return {
    imageId,
    name: details?.name,
    details,
    images: [preview, visual],
    markers,
    info,
    photonotes,
    notes: noteEntries,
  };
}
