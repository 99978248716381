import React, { useMemo } from 'react';

// utilities
import { MarkerShapesStandard } from '@fluke/irviewer';

// components
import { Checkbox, List, ListItem, ListItemText, SvgIcon } from '@common-ui/ui';
import ButtonPopover from './ButtonPopover';

/**
 * Custom MUI Button <Icon /> per design for STANDARD MARKER dropdown
 *
 * @param {*} props - any style or action props inherited form parent
 *
 * @returns {JSX.Element} - Custom STANDARD MARKER button icon
 */
function SpotIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path
        d="M18.7692 4H5.23077C4.55103 4 4 4.55103 4 5.23077V18.7692C4 19.449 4.55103 20 5.23077 20H18.7692C19.449 20 20 19.449 20 18.7692V5.23077C20 4.55103 19.449 4 18.7692 4Z"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="12" cy="12" r="3" fill="currentColor" />
    </SvgIcon>
  );
}

/**
 * Button dropdown that displays the available STANDARD marker tools for IRVIEWER
 *
 * @param {*} onClick - callback when dropdown selection is made
 *
 * @returns {JSX.Element} - Dropdown button for STANDARD marker shapes
 */
export default function StandardMarkers({ selected = [], onChange = null }) {
  const handleOnChange = (value, event) => {
    const isChecked = event?.target?.checked;
    let updatedOptions = [];

    if (isChecked) {
      updatedOptions = [...selected, value];
    } else {
      updatedOptions = selected.filter((option) => option !== value);
    }

    onChange?.({ standardMarkers: updatedOptions });
  };

  const checkBoxItems = useMemo(() => Object.keys(MarkerShapesStandard), []);

  return (
    <ButtonPopover label="Spots" tooltip={'Standard Markers'} Icon={<SpotIcon />}>
      <List>
        {checkBoxItems.map((key) => (
          <ListItem key={`checkbox-${key}`} dense>
            <Checkbox
              size="small"
              checked={selected.includes(key)}
              onChange={(event) => handleOnChange(key, event)}
            />

            <ListItemText>{key}</ListItemText>
          </ListItem>
        ))}
      </List>
    </ButtonPopover>
  );
}
