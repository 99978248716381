import React, { useContext } from 'react';
import { ModalContext } from '../../../context';

// components
import AddToReportMix from './AddToReportMix';

// mui
import { Box, Modal, ThemeProvider } from '@common-ui/ui';
import DarkTheme from '../../../styles/DarkTheme';
import LightTheme from '../../../styles/LightTheme';

/**
 * The AddToReport component is a modal that displays AddToReportMix component. It allows users to add selected images to a new or existing report.
 * It uses the theme provided as a prop to set the appearance of the modal.
 * @param {Object} [props={}]
 * @param {Array} [props.selectedImageIds=[]] - The selected image ids that will be added to a report.
 * @param {boolean} [props.showBothOptions=true] - A flag to indicate whether both 'Add to New Report' and 'Add to Existing Report' options should be shown. By default, it's true.
 * @param {string} [props.theme='dark'] - The theme for the modal. It can be either 'dark' or 'light'. By default, it's 'dark'.
 *
 * @returns {JSX.Element} - The modal that includes the AddToReportMix component.
 */
export default function AddToReport({
  selectedImageIds = [],
  showBothOptions = true,
  theme = 'dark',
}) {
  const { closeModal } = useContext(ModalContext);

  return (
    <ThemeProvider theme={theme === 'dark' ? DarkTheme : LightTheme}>
      <Modal open onClose={closeModal}>
        <Box sx={styles.container}>
          <div style={styles.content}>
            <AddToReportMix selectedImageIds={selectedImageIds} showBothOptions={showBothOptions} />
          </div>
        </Box>
      </Modal>
    </ThemeProvider>
  );
}

const styles = {
  container: (theme) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '554px',
    height: '604px',
    boxShadow: 24,
    opacity: 1,
    padding: theme.spacing(3),
    background: theme.palette.background.default,
    color: theme.palette.text.default,
  }),
  content: {
    display: 'flex',
    justifyContent: 'center',
  },
};
