import React, { useState, useEffect, useRef } from 'react';
import Dropdown from './Dropdown';

const capitalizeEachFirstLetter = (word) => {
  return word
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};
/**
 * EditableCell is a functional component that renders a textbox instead of a
 * regular cell or list item. This is particularly useful for handling certain
 * states in a more sophisticated way, such as when a cell is in edit mode.
 *
 * If the `isEditActive` prop is true, an input field is rendered with the current
 * value. The input field automatically focuses and selects its text when it is
 * activated. Changes to the input field's value are saved to the state.
 *
 * When the input field loses focus or when the 'Enter' key is pressed, the
 * `handleRename` function is called with the current value as an argument.
 *
 * The EditableCell component uses React hooks for state and effect management.
 *
 * @param {Object} [params={}] - An object that contains the initial value of the cell.
 * @param {string} params.value - The initial value of the cell.
 * @param {boolean} [isEditActive=false] - A flag indicating whether the cell is currently being edited.
 * @param {function} [handleRename=null] - A function to be called when the cell's value is updated.
 *                                         This function is called with the new value as an argument.
 *
 * @returns {JSX.Element | string} A JSX component rendering an input field if the cell is being edited,
 *                                 or the cell's value as a string if it's not.
 */
export default function EditableCell({
  params = {},
  autocomplete = true,
  isEditActive = false,
  handleRename = () => {},
  searchEngine = null,
}) {
  const inputRef = useRef();

  const [value, setValue] = useState(params.value);
  const [options, setOptions] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    if (inputRef.current && isEditActive) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, [isEditActive]);

  useEffect(() => {
    if (params && params?.value) {
      setValue(params.value);
    }
  }, [params]);

  useEffect(() => {
    if (autocomplete && isEditActive && searchEngine) {
      // Fetch options based on the value from the search engine
      const searchResults = searchEngine(value.toLowerCase());
      const filtered = searchResults.slice(0, 5).map((item) => capitalizeEachFirstLetter(item));
      setOptions(filtered);
    }
  }, [isEditActive, value, searchEngine]);

  const handleOptionSelect = (selectedValue) => {
    setValue(selectedValue);
    handleRename(selectedValue);
  };

  const handleInputBlur = () => {
    setShowDropdown(false);
    handleRename(value);
  };

  const handleInputFocus = () => {
    setShowDropdown(true);
  };

  if (isEditActive) {
    return (
      <div onBlur={handleInputBlur} style={styles.container}>
        <input
          ref={inputRef}
          style={styles.input}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          onFocus={handleInputFocus}
          onClick={(e) => e.stopPropagation()}
          onKeyDown={(e) => {
            e.stopPropagation();
            if (e.key === 'Enter') {
              handleInputBlur(e);
            }
          }}
        />
        {autocomplete && (
          <Dropdown
            anchorEl={inputRef.current}
            options={options}
            enableDropdown={showDropdown}
            onSelect={handleOptionSelect}
          />
        )}
      </div>
    );
  }

  return params.value;
}

const styles = {
  container: {
    position: 'absolute',
    display: 'flex',
    zIndex: 9999,
  },
  input: {
    width: '100%',
  },
};
