export const sessionKeyStoreEnum = Object.freeze({
  SORTED: 'sorted',
  SORTED_BY: 'sorted_by',
  SEARCH_ID: 'searchId',
  PREVIEW_PAGE_INDEX: 'previewPageIndex',
  PREVIEW_PAGE_COUNT: 'previewPageCount',
  SELECTED_MONTH: 'selectedMonth',
  SELECTED_YEAR: 'selectedYear',
  ACTIVE_HEADER: 'activeHeader',
  ACTIVE_QUERY: 'activeQuery',
  ACTIVE_CHILD: 'activeChild',
  SIDEBAR_DATES: 'sidebarDates',
  SEARCH_TEXT: 'searchText',
});

export const sessionValueInitTable = new Object({
  [sessionKeyStoreEnum.SORTED]: 'desc',
  [sessionKeyStoreEnum.SORTED_BY]: 'date_taken',
  [sessionKeyStoreEnum.SEARCH_ID]: Date.now(),
  [sessionKeyStoreEnum.PREVIEW_PAGE_INDEX]: 0,
  [[sessionKeyStoreEnum.PREVIEW_PAGE_COUNT]]: 1,
  [sessionKeyStoreEnum.SELECTED_MONTH]: null,
  [sessionKeyStoreEnum.SELECTED_YEAR]: null,
  [sessionKeyStoreEnum.ACTIVE_HEADER]: null,
  [sessionKeyStoreEnum.ACTIVE_QUERY]: null,
  [sessionKeyStoreEnum.ACTIVE_CHILD]: null,
  [sessionKeyStoreEnum.SIDEBAR_DATES]: null,
  [sessionKeyStoreEnum.SEARCH_TEXT]: null,
});
