import React from 'react';
import { Snackbar, Alert, Typography } from '@common-ui/ui';
import snackBarStatusEnum from '../../enums/snackBarStatusEnum';

export default function AlertSnackBar({ alert, setAlert }) {
  const closeSnackbar = () => {
    setAlert(snackBarStatusEnum.DEFAULT);
  };

  return (
    <Snackbar open={alert?.visible} autoHideDuration={6000} onClose={closeSnackbar}>
      <Alert
        onClose={closeSnackbar}
        severity={alert?.severity}
        variant="filled"
        sx={{ color: 'white' }}
      >
        <Typography>{alert?.text}</Typography>
      </Alert>
    </Snackbar>
  );
}
