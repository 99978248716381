import { getCurrentAuthenticatedUser } from '@fluke/baseline-client-apis';

export const initializePendo = (id, email, username) =>
  // the Pendo agent must be initialized before it can start tracking usage analytics,
  // collecting feedback, and delivering guides

  window?.pendo?.initialize({ visitor: { id: id, email: email, full_name: username } });

export const identifyPendo = (id, email, username) =>
  // Identifying a visitor will send an event to Pendo and
  // begin loading eligible guides to the identified user

  window?.pendo?.identify({ visitor: { id: id, email: email, full_name: username } });

export const flushPendo = () => {
  // once the user returns to the login screen, we want to clear the pendo session
  // pendo.validateInstall() will show a random identifier from the pendo library
  window?.pendo?.clearSession();
  window?.pendo?.identify(null);
};

export default async function initializePendoSession() {
  // the initial function to handle a new pendo session
  const userData = await getCurrentAuthenticatedUser();
  const { userAccountId:sub, emailAddr:email, userName:name } = userData; 
  if (sub) {
    localStorage.setItem('Pendo', JSON.stringify({ sub, email, name}));
    initializePendo(sub, email, name);
    identifyPendo(sub, email, name);
  }
  
}
