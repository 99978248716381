import complete_selected from '../images/complete_selected.svg';
import problem_selected from '../images/problem_selected.svg';
import skip_selected from '../images/skip_selected.svg';
import watch_selected from '../images/watch_selected.svg';

const imageStatusIconEnum = Object.freeze({
  0: null,
  1: complete_selected,
  2: watch_selected,
  3: problem_selected,
  4: skip_selected,
});

export default imageStatusIconEnum;
