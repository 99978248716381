import React, { useMemo } from 'react';

// utils
import { getDisplayTemperature, getDisplayDelta } from '@fluke/irviewer';

// components
import { TableCell, TableRow } from '@mui/material';
import { Radio } from '@common-ui/ui';

export default function MarkerRow({
  id,
  marker = {},
  isReference = false,
  referenceTemp = 0,
  onNextReference = null,
  temperatureUnit = 'C',
}) {
  const { characteristics = {} } = marker;
  const {
    markerName = '',
    uniqueIDString,
    markerTempC_Max = 0,
    markerTempC_Average = 0,
  } = characteristics;

  const deltaTemperature = useMemo(() => {
    if (isReference) {
      return null;
    } else {
      return (markerTempC_Max ?? markerTempC_Average) - referenceTemp;
    }
  }, [isReference, markerTempC_Average, markerTempC_Max, referenceTemp]);

  const isVisible =
    useMemo(() => {
      const { characteristics = {} } = marker;
      return characteristics?.markerEnabledFlag === true;
    }, [marker]) ?? false;

  const markerTemperature =
    useMemo(() => {
      const tempCelsius = markerTempC_Max ?? markerTempC_Average;

      return getDisplayTemperature(tempCelsius, temperatureUnit);
    }, [markerTempC_Average, markerTempC_Max, temperatureUnit]) ?? 0;

  const handleOnNextReference = () => {
    onNextReference?.(uniqueIDString);
  };

  // styles
  const deltaTStyle = useMemo(() => {
    const colorMapStyle = {
      positive: { color: '#1EEEE1' },
      negative: { color: '#FF994F' },
      default: null,
    };

    if (deltaTemperature < 0) {
      return colorMapStyle.negative;
    } else if (deltaTemperature > 0) {
      return colorMapStyle.positive;
    }
  }, [deltaTemperature]);

  return (
    <TableRow id={`marker-row--${id}`} style={{ display: isVisible ? 'table-row' : 'none' }}>
      <TableCell>{markerName}</TableCell>
      <TableCell>{markerTemperature}</TableCell>
      <TableCell sx={deltaTStyle}>
        {deltaTemperature ? getDisplayDelta(deltaTemperature, temperatureUnit) : '---'}
      </TableCell>
      <TableCell>
        <Radio
          checked={isReference}
          onChange={() => handleOnNextReference(uniqueIDString)}
          size="small"
        />
      </TableCell>
    </TableRow>
  );
}
