import { v4 as uuidv4 } from 'uuid';
import { EntryType } from './enum/EntryTypeEnum';
import { ROOT } from './enum/RootEnum';

/**
 * getEntryInHierarchy is a helper function that retrieves a specific entry from a hierarchy
 * based on the entry's id.
 *
 * @param {string} entryId - The id of the entry to be retrieved.
 * @param {Object[]} [hierarchy=[]] - An array of objects representing the hierarchy. Each object should have at least an `id` property.
 *
 * @returns {Object} The entry that matches the provided id. If no match is found, returns `undefined`.
 */
export function getEntryInHierarchy(entryId = '', hierarchy = []) {
  const entry = hierarchy.find((item) => item.id === entryId);
  return entry;
}

/**
 * rootTrace is a helper function that constructs a trace from the root of the hierarchy
 * to a specific entry, based on the entry's id.
 *
 * @param {string} [entryId=''] - The id of the entry from which to trace back to the root.
 * @param {Object[]} [hierarchy=[]] - An array of objects representing the hierarchy. Each object should have at least an `id` and `parent` property.
 *
 * @returns {Object[]} An array representing the trace from the root to the specified entry. Each element in the array is an object that represents an entry in the hierarchy.
 *                     The first element is the root and the last element is the specified entry. If the specified entry does not exist in the hierarchy, returns an array containing only the root.
 */
export function rootTrace(entryId = '', hierarchy = []) {
  // trace path of entry to root

  let trace = [];

  const buildPath = (id = '') => {
    // traceback to root
    const entry = getEntryInHierarchy(id, hierarchy);

    // does the entry exist? -->
    if (entry) {
      trace.push(entry);
      if (entry?.parent) {
        buildPath(entry.parent);
      }
    } else {
      // if entry does not exist, reset trace to []
      trace = [];
      return;
    }
  };

  if (entryId) {
    buildPath(entryId);
  }

  return [...trace, ROOT].reverse();
}

/**
 * isRoot is a helper function that checks whether a specified entry in a hierarchy is a root.
 * It determines this by checking if the specified entry has a parent. If it doesn't have a parent, then it's considered a root.
 *
 * @param {string} [entryId=''] - The id of the entry to be checked.
 * @param {Object[]} [hierarchy=[]] - An array of objects representing the hierarchy. Each object should have at least an `id` and `parent` property.
 *
 * @returns {boolean} Returns `true` if the specified entry is a root, `false` otherwise. If the specified entry does not exist in the hierarchy, returns `false`.
 */
export function isRoot(entryId = '', hierarchy = []) {
  // root if no parent assigned
  const childEntry = getEntryInHierarchy(entryId, hierarchy);
  const isChildRoot = childEntry?.parent ? true : false;
  return isChildRoot;
}

/**
 * getParentInHierarchy is a helper function that finds the rootmost parent of a specified entry in a hierarchy.
 * It searches the hierarchy recursively until it finds an entry that doesn't have a parent.
 *
 * @param {string} [parentId=''] - The id of the entry whose rootmost parent is to be found.
 * @param {Object[]} [hierarchy=[]] - An array of objects representing the hierarchy. Each object should have at least an `id` and `parent` property.
 *
 * @returns {Object} The rootmost parent of the specified entry. If the specified entry does not exist in the hierarchy, or if it doesn't have a parent, returns the entry itself.
 *                   If the entry itself and its parent does not exist in the hierarchy, returns `undefined`.
 */
export function getParentInHierarchy(parentId = '', hierarchy = []) {
  // Find the direct parent
  const parent = hierarchy.find((item) => item?.id === parentId);

  // If the parent is not found or it doesn't have a parent itself, return it
  if (!parent || !parent.parent) {
    return parent;
  }

  // If the parent has a parent, recursively check it
  return getParentInHierarchy(parent.parent, hierarchy);
}

/**
 * getChildrenFromParent is a helper function that retrieves all direct children of a specified parent in a hierarchy.
 *
 * @param {string} [parentId=''] - The id of the parent entry whose children are to be retrieved.
 * @param {Object[]} [hierarchy=[]] - An array of objects representing the hierarchy. Each object should have at least an `id` and `parent` property.
 *
 * @returns {Object[]} An array of objects, each representing a direct child of the specified parent. If the specified parent has no children or does not exist in the hierarchy, returns an empty array.
 */
export function getChildrenFromParent(parentId = '', hierarchy = []) {
  return hierarchy.filter((item) => item?.parent === parentId);
}

/**
 * build a tree structure from a given adjacency list.
 * Each item in the adjacency list should have an id and optionally a parent id.
 * The function creates a hierarchical tree structure where each item has a
 * children array containing its child items.
 * Items with no parent are considered root items.
 *
 * @param {Array} adjacencyList - The array representing the adjacency list, where each item is an object
 * that contains an id and optionally a parent id.
 * @return {Array} - An array representing the root of the tree structure. Each item in the array
 * can have a children array which contains its child items.
 */
export function buildTreeFromAdjacency(adjacencyList = []) {
  const idx = {};
  const root = [];

  adjacencyList.forEach((item) => {
    // Create a new object with the same properties but without altering the original object
    const newItem = { ...item, children: [] };

    // Add it to the index
    idx[newItem.id] = newItem;

    // If it has a parent, add it to the parent's children array
    if (newItem.parent) {
      if (!idx[newItem.parent]) {
        idx[newItem.parent] = { children: [] };
      }
      idx[newItem.parent].children.push(newItem);
    } else {
      // If it doesn't have a parent, it's a root node
      root.push(newItem);
    }

    // Remove the parent property
    delete newItem.parent;
  });

  return [{ ...ROOT, children: root }];
}

/**
 * build the next items in a file hierarchy based on the selected item.
 * If the selected item is a folder, it returns the items within that folder.
 * If the selected item is a file, it returns the item and handled as a file
 * If no item is selected (i.e., the selected item is null or undefined), it returns items at the root level.
 *
 * @param {Object} selectedItem - The currently selected item.
 * @param {Array} hierarchy - The array representing the entire hierarchy of items.
 * @param {Object} entries - The object containing additional information about each item in the hierarchy.
 * @return {Array} - An array of the next items, with additional information if the item is a file.
 */
export function buildNextItems(nextId = '', hierarchy = [], files = {}) {
  // if not a folder, return
  if (nextId in files) return;

  const nextItems = getChildrenFromParent(nextId, hierarchy);

  return nextItems.map((item) => {
    return item.tag === EntryType.FILE
      ? {
          id: item.id,
          tag: EntryType.FILE,
          ...files[item.id],
        }
      : item;
  });
}

/**
 * getNextUniqueName is a helper function that generates a unique name for a new entry in a hierarchy.
 * If the specified name does not exist among the siblings, it returns the name as is.
 * If the name does exist, it appends a number to the name, incrementing the number until it finds a unique name.
 *
 * @param {string} [name=''] - The name to be checked for uniqueness.
 * @param {string} parentId - The id of the parent entry. If not provided, function will consider root level.
 * @param {Object[]} [hierarchy=[]] - An array of objects representing the hierarchy. Each object should have at least a `name` and `parent` property.
 *
 * @returns {string} A unique name. If the specified name is unique among the siblings, it returns the name as is. If the name is not unique, it appends a number to it to make it unique.
 */
export function getNextUniqueName(name = '', parentId = '', hierarchy = []) {
  // find all adjacent items with same parent
  const siblings = getChildrenFromParent(parentId, hierarchy);
  // build record of sibling names
  const siblingNames = siblings.map((sibling) => sibling.name);

  // check if name exists among siblings
  if (!siblingNames.includes(name)) {
    return name;
  } else {
    let index = 1;
    let newName = `${name} (${index})`;

    // find a unique name by incrementing index
    while (siblingNames.includes(newName)) {
      index++;
      newName = `${name} (${index})`;
    }

    return newName;
  }
}

/**
 * addEntryToParent is a helper function that adds a new entry to a specified parent in a hierarchy.
 * It generates a unique name for the new entry using the getNextUniqueName function.
 * The new entry is added at the end of the hierarchy array.
 *
 * @param {string} [parentId=''] - The id of the parent entry. If not provided, function will consider root level.
 * @param {string} [name=''] - The name of the new entry.
 * @param {Object[]} [hierarchy=[]] - An array of objects representing the hierarchy. Each object should have at least a `name`, `id` and `parent` property.
 * @param {string} [type=''] - The type of the new entry. This should be one of the constants defined in EntryType.
 *
 * @returns {Array} An array with two elements. The first element is the updated hierarchy array, and the second element is an object representing the newly added entry.
 */
export function addEntryToParent(parentId = '', name = '', hierarchy = [], type = '') {
  const createdId = uuidv4();
  const uniqueName = getNextUniqueName(name, parentId, hierarchy);

  const newEntry = {
    tag: type === EntryType.FOLDER ? EntryType.FOLDER : EntryType.FILE,
    name: uniqueName,
    id: createdId,
    parent: parentId,
  };

  // Add the new folder to the hierarchy
  const nextHierarchy = [...hierarchy, newEntry];
  return [nextHierarchy, newEntry];
}

/**
 * createNewAsset is a helper function that creates a new asset in the specified hierarchy and files archive.
 * It adds the new asset to the hierarchy using the addEntryToParent function, and creates a corresponding entry in the files archive.
 *
 * @param {string} [parentId=''] - The id of the parent entry. If not provided, the function will consider root level.
 * @param {string} [name=''] - The name of the new asset. If not provided, the name defaults to 'New Asset'.
 * @param {Object[]} [hierarchy=[]] - An array of objects representing the hierarchy. Each object should have at least a `name`, `id` and `parent` property.
 * @param {Object} [files={}] - An object representing the files archive. Each key should be an id, and each value should be an object representing the file.
 *
 * @returns {Array} An array with three elements. The first element is the updated hierarchy array, the second element is the updated files archive object, and the third element is the object representing the newly created file.
 */
export function createNewAsset(parentId = '', name = '', hierarchy = [], files = {}) {
  const createdName = name || 'New Asset';
  const [nextHierarchy, newEntry] = addEntryToParent(
    parentId,
    createdName,
    hierarchy,
    EntryType.FILE,
  );

  // Create a new file and add to files
  const createdFile = {
    [newEntry.id]: {
      name: newEntry.name,
      location: '',
      assignedRoutes: [],
      status: false,
      lastInspection: '',
    },
  };
  const nextFiles = { ...files, ...createdFile };
  return [nextHierarchy, nextFiles, createdFile];
}

/**
 * createNewFolder is a helper function that creates a new folder in the specified hierarchy.
 * It adds the new folder to the hierarchy using the addEntryToParent function.
 *
 * @param {string} [parentId=''] - The id of the parent entry. If not provided, the function will consider root level.
 * @param {string} [name=''] - The name of the new asset. If not provided, the name defaults to 'New Folder'.
 * @param {Object[]} [hierarchy=[]] - An array of objects representing the hierarchy. Each object should have at least a `name`, `id` and `parent` property.
 *
 * @returns {Array} An array with three elements. The first element is the updated hierarchy array and the second element is the object representing the newly created folder.
 */
export function createNewFolder(parentId = '', name = '', hierarchy = []) {
  const createdName = name || 'New Folder';
  // Create a new folder and add to directory tree
  const [nextHierarchy, newEntry] = addEntryToParent(
    parentId,
    createdName,
    hierarchy,
    EntryType.FOLDER,
  );

  return [nextHierarchy, newEntry];
}

/**
 * renameById is a helper function that renames a specified entry in a hierarchy and files archive.
 * It modifies both the hierarchy and the files archive, updating the name of the entry with the specified id.
 *
 * @param {string} [id=''] - The id of the entry to be renamed. If not provided, function will do nothing.
 * @param {string} newName - The new name for the entry.
 * @param {Object[]} [hierarchy=[]] - An array of objects representing the hierarchy. Each object should have at least a `name`, `id` and `parent` property.
 * @param {Object} [files={}] - An object representing the files archive. Each key should be an id, and each value should be an object representing the file.
 *
 * @returns {Array} An array with two elements. The first element is the updated hierarchy array, the second element is the updated files archive object.
 */
export function renameById(id = '', newName, hierarchy = [], files = {}) {
  if (!newName) return;

  // Clone the files and hierarchy
  let nextFiles = { ...files };
  const nextHierarchy = [...hierarchy];

  // Find and update the item in the hierarchy
  const itemIndex = nextHierarchy.findIndex((item) => item.id === id);
  if (itemIndex !== -1) {
    nextHierarchy[itemIndex].name = newName;
  }

  // Update the name in files object
  if (nextFiles[id]) {
    nextFiles[id].name = newName;
  }

  return [nextHierarchy, nextFiles];
}

/**
 * deleteById is a helper function that removes a specified entry from a hierarchy.
 * It filters the hierarchy array to exclude the entry with the specified id.
 *
 * @param {string} [deletedId=''] - The id of the entry to be deleted. If not provided, function will return the same hierarchy.
 * @param {Object[]} [hierarchy=[]] - An array of objects representing the hierarchy. Each object should have at least an `id` property.
 *
 * @returns {Array} An array with two elements. The first element is the updated hierarchy array, the second element is the id of the deleted entry.
 */
export function deleteById(deletedId = '', hierarchy = []) {
  const nextHierarchy = hierarchy.filter((entry) => entry.id !== deletedId);
  return [nextHierarchy, deletedId];
}

/**
 * hasIdBeenDeleted is a helper function that checks if a specified entry has been deleted from a hierarchy and files archive.
 * An entry is considered deleted if:
 *  1. The id no longer exists in the files archive.
 *  2. The id in the files archive does not exist in the hierarchy.
 *  3. The parent id of the entry does not exist in the hierarchy.
 *
 * @param {string} [id=''] - The id of the entry to be checked. If not provided, function will return true.
 * @param {Object[]} [hierarchy=[]] - An array of objects representing the hierarchy. Each object should have at least an `id` and `parent` property.
 * @param {Object} [files={}] - An object representing the files archive. Each key should be an id, and each value should be an object representing the file.
 *
 * @returns {boolean} A boolean value indicating whether the specified entry has been deleted. Returns true if the entry has been deleted, false otherwise.
 */
export function hasIdBeenDeleted(id = '', hierarchy = [], files = {}) {
  if (!(id in files)) {
    // file id no longer archived
    // most likely mismatch: hierarchy behind file archive
    return true;
  }

  const entryInHierarchy = getEntryInHierarchy(id);
  if (entryInHierarchy == null) {
    // file id no longer found in hierarchy
    // most likely handles root entries
    return true;
  }

  const { parent = '' } = entryInHierarchy;
  const parentInHierarchy = getParentInHierarchy(parent, hierarchy);
  if (parentInHierarchy == null) {
    // parent id of file no longer found in hierarchy
    // most likely handles non-root entries
    return true;
  }

  return false;
}

/**
 * sortHierarchyItems is a helper function that sorts an array of items representing a file hierarchy.
 * It filters out any items with a tag equal to excludeTag, then sorts the remaining items such that folders always come before files.
 *
 * @param {Object[]} [items=[]] - An array of objects representing items in a file hierarchy. Each object should have at least a `tag` property.
 * @param {string} [excludeTag=''] - A string representing the tag to exclude from the sorted array. If not provided, all items are included.
 *
 * @returns {Object[]} A sorted array of the items in the hierarchy, excluding any items with a tag equal to excludeTag. Folders are sorted to appear before files.
 */
export function sortHierarchyItems(items = [], excludeTag = '') {
  // Filter out the items to omit
  const filteredItems = items.filter((item) => item.tag !== excludeTag);

  return [...filteredItems].sort((a, b) => {
    if (a.tag === EntryType.FOLDER && b.tag === EntryType.FILE) {
      return -1;
    }
    if (a.tag === EntryType.FILE && b.tag === EntryType.FOLDER) {
      return 1;
    }
    return 0;
  });
}

/**
 * getAllFileIds is a helper function that returns all file ids in a hierarchy starting from a specified id.
 * It performs a depth-first search on the hierarchy, adding all file ids to an array.
 *
 * @param {string} [id=''] - The id of the entry to start the search from.
 * @param {Object[]} [hierarchy=[]] - An array of objects representing the hierarchy. Each object should have at least an `id`, `tag`, and `parent` property.
 *
 * @returns {string[]} An array of ids of all files found in the hierarchy starting from the specified id.
 */
export function getAllFileIds(id = '', hierarchy = []) {
  const ids = [];
  const stack = [id];

  while (stack.length > 0) {
    const currentId = stack.pop();
    if (currentId) {
      const entry = getEntryInHierarchy(currentId, hierarchy);
      if (entry.tag === EntryType.FILE) {
        ids.push(entry.id);
      } else {
        stack.push(...getChildrenFromParent(currentId, hierarchy).map((child) => child.id));
      }
    }
  }

  return ids;
}

/**
 * getDeletedEntries is a helper function that returns an array of ids of entries in a hierarchy that have been deleted.
 * An entry is considered deleted if its parent no longer exists in the hierarchy.
 * The function excludes root items from the returned array.
 *
 * @param {Object[]} [hierarchy=[]] - An array of objects representing the hierarchy. Each object should have at least an `id` and `parent` property.
 *
 * @returns {Object[]} An array of entries found in the hierarchy that have been deleted.
 */
export function getDeletedEntries(hierarchy = [], files = {}) {
  // return array of file id's without a parent and exclude root items
  let refsToParentId = new Set(hierarchy.map((entry) => entry.parent));
  let entryIdsSet = new Set(hierarchy.map((entry) => entry.id));
  let brokenIdsSet = new Set();

  refsToParentId.forEach((parentId) => {
    if (parentId && !entryIdsSet.has(parentId)) {
      brokenIdsSet.add(parentId);
    }
  });

  let brokenParentsSet = new Set(Array.from(brokenIdsSet));
  let brokenChildren = hierarchy.filter(({ parent }) => brokenParentsSet.has(parent));
  const brokenIds = brokenChildren.flatMap(({ id }) => getAllFileIds(id, hierarchy));

  const deletedFileEntries = brokenIds.map((id) => ({
    id,
    tag: EntryType.FILE,
    ...files[id],
  }));

  return deletedFileEntries;
}

/**
 * getAllFiles is a helper function that returns the FILE entries from a tree.
 * no duplicates returned.
 * @param {Object[]} [tree=[]] - An array of objects representing the tree. Each object should have at least an `id`, `tag`, and `children` property.
 *
 * @returns {Object[]} An array of FILE entries found in the tree.

 */
export function getAllFiles(tree = [], files = {}) {
  const uniqueIds = new Set();
  const allFiles = [];

  function traverse(branch) {
    if (branch.tag === EntryType.FOLDER && branch.children.length > 0) {
      branch.children.forEach((child) => traverse(child));
    } else if (branch.tag === EntryType.FILE && !uniqueIds.has(branch.id)) {
      allFiles.push({
        id: branch.id,
        tag: EntryType.FILE,
        ...files[branch.id],
      });
      uniqueIds.add(branch.id);
    }
  }

  tree.forEach((branch) => traverse(branch));

  return allFiles;
}
