import React from 'react';
import DownloadBaselineUploaderDialog from './DownloadBaselineUploaderDialog';
import NoImagesDialog from './NoImagesDialog';
import styled from '@emotion/styled';

const Container = styled.div`
  display: grid;
  grid-template-columns: 850px;
  grid-template-rows: repeat(2, 260px);
  gap: 1em;
  background: #121212;
  margin-top: 1em;
  justify-content: center;
`;

/**
 * Display a dialog when there are no images to display
 *
 * @returns {JSX.Element}
 */
export default function NoImagesOverlay() {
  return (
    <Container>
      <DownloadBaselineUploaderDialog />
      <NoImagesDialog />
    </Container>
  );
}
