import React, { useEffect, useState, useCallback } from 'react';
import { Avatar } from '@common-ui/ui';
import { AvatarOverlayControls, FileDragAndDrop, checkFileBool } from '.';
import { LoadingOverlay } from '../../../../components/Overlays';

const _validFileExtensions = ['.jpg', '.jpeg', '.bmp', '.png'];
const placeholder_orgLogo = '/placeholder_orgLogo.jpg';

function AvatarImage({ src, onDelete, onUpload, isLoading = false, disabled = false }) {
  const [localAvatar, setLocalAvatar] = useState(placeholder_orgLogo);
  const [hasAvatar, setHasAvatar] = useState(false);

  useEffect(() => {
    if (src) {
      setLocalAvatar(src);
      setHasAvatar(true);
    } else {
      setLocalAvatar(placeholder_orgLogo);
      setHasAvatar(false);
    }
  }, [src]);

  const styles = {
    avatarFrame: {
      width: '100%',
      minHeight: '130px',
      minWidth: '130px',
      borderRadius: '12px',
    },
    avatarBoundingBox: {
      position: 'relative',
      display: 'flex',
      overflow: 'hidden',
      zIndex: '2',
      borderRadius: '12px',
    },
    avatarContainer: {
      width: '130px',
      height: '130px',
      overflow: 'hidden',
      backgroundColor: 'white',
      borderRadius: 0,
    },
    avatarImage: {
      maxWidth: '100%',
      maxHeight: '100%',
      objectFit: 'cover',
    },
  };

  return (
    <div
      id="bounding-box-border"
      style={{
        ...styles.avatarFrame,
        border: `2px dashed ${hasAvatar ? 'transparent' : '#c3c3c3'}`,
      }}
    >
      <div className={`${hasAvatar === false && 'noprint'}`}>
        <div id="avatar-content-container" style={styles.avatarBoundingBox}>
          <Avatar id="reportAvatar" shape="square" style={styles.avatarContainer}>
            <img src={localAvatar} alt="org-avatar" style={styles.avatarImage} />
          </Avatar>

          <AvatarOverlayControls
            handleUpload={onUpload}
            handleDelete={onDelete}
            hasOrgLogo={hasAvatar}
            disabled={disabled}
          />

          <LoadingOverlay open={isLoading} />
        </div>
      </div>
    </div>
  );
}

export default function AvatarOrgImage({
  src = null,
  onChange = null,
  isLoading = false,
  disabled = false,
}) {
  const [isDragActive, setIsDragActive] = useState(false);

  const handleUpload = useCallback(
    async (files) => {
      const newAvatar = files[0];
      const filePassed = await checkFileBool(newAvatar, _validFileExtensions);

      if (filePassed && onChange) {
        onChange(newAvatar);
      }
    },
    [onChange],
  );

  const handleDelete = useCallback(() => {
    if (onChange) {
      onChange(null);
    }
  }, [onChange]);

  return (
    <FileDragAndDrop
      isDragActive={isDragActive}
      setIsDragActive={setIsDragActive}
      handleDrop={handleUpload}
      styleOverlay={{ borderRadius: '10px' }}
      disabled={disabled}
    >
      <AvatarImage
        src={src}
        onUpload={handleUpload}
        onDelete={handleDelete}
        isLoading={isLoading}
        disabled={disabled}
      />
    </FileDragAndDrop>
  );
}
