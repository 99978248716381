import React from 'react';
import arePropsEqual from './arePropsEqual';
import Sidebar from './Sidebar';

/**
 * Memoized sidebar component.
 *
 * This component is a memoized version of the Sidebar component, ensuring that
 * it only re-renders when its props change. It utilizes the `arePropsEqual`
 * function for determining prop equality.
 *
 * @component
 * @param {Object} props - Props to pass to the Sidebar component.
 * @returns {JSX.Element} Rendered MemoizedSidebar component.
 */
const MemoizedSidebar = React.memo(function NamedSidebar(props) {
  return <Sidebar {...props} />;
}, arePropsEqual);

export default MemoizedSidebar;
