import React, { useEffect } from 'react';
//import { flushPendo } from '../../utils/analytics/pendoAnalytics';
import { useNavigate } from 'react-router-dom';
import routerPathEnum from '../../enums/routerPathEnum';
import { signOutApp } from "@fluke/baseline-client-apis"

export default function LogoutScreen() {
  const navigate = useNavigate();

  useEffect(() => {

    //flushPendo()
    signOutApp().then(()=>{
      sessionStorage.clear();
      localStorage.clear();
      navigate(routerPathEnum.ROOT);
    });      
  }, []);

  return <></>;
}
