const imageStatusNamesEnum = Object.freeze({
  NoState: 0,
  Complete: 1,
  Watch: 2,
  Problem: 3,
  Skip: 4,
  0: 'Select A Reason',
  1: 'Complete',
  2: 'Watch',
  3: 'Problem',
  4: 'Skip',
});

export default imageStatusNamesEnum;
