import React from 'react';
import { Typography, Button } from '@common-ui/ui';
import { Download } from '@mui/icons-material';
import { DownloadBaselineUploaderEnum } from '../../../../enums/DownloadBaselineUploaderEnum';
import { getLatestUploader } from '../../../../apis/visorAPI';
import styled from '@emotion/styled';

const Container = styled.div`
  background: ${({ theme }) => theme.palette.grey[900]};
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const SubContainer = styled.div`
  display: flex;
  gap: 1em;
  width: 50%;
`;

/**
 * Display a dialog to download the Baseline Uploader
 *
 *  @param {None} None
 *  @returns {JSX.Element}
 */

export default function DownloadBaselineUploaderDialog() {
  const onClick = async () => {
    const { url } = await getLatestUploader();
    location.href = url;
  };

  return (
    <Container>
      <SubContainer>
        <img src="/B_tab_logo.png" width="68" height="68" />
        <div>
          <Typography variant="h6" color="white">
            {DownloadBaselineUploaderEnum.TITLE}
          </Typography>
          <Typography color="gray">{DownloadBaselineUploaderEnum.BODY}</Typography>
        </div>
      </SubContainer>
      <Button
        variant="contained"
        color="secondary"
        size="large"
        startIcon={<Download fontSize="large" />}
        onClick={onClick}
      >
        {DownloadBaselineUploaderEnum.UPLOAD_BUTTON}
      </Button>
    </Container>
  );
}
