import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import lodash from 'lodash';

// redux
import { useAppDispatch } from '../../store/configureStore';
import { useSelector } from 'react-redux';
import { setSelectedImageIds } from '../../store/slices/imagesSlice';
import { setActiveDraftReport } from '../../store/slices/reportSlice';

// context
import { UserContext } from '../../context';

// enums
import { reportInfoKeys, reportSectionKeys } from './enums';
import routerPathEnum from '../../enums/routerPathEnum';
import snackBarStatusEnum from '../../enums/snackBarStatusEnum';

// utilities
import ReportManager from './ReportManager';
import { checkImageURL } from './utils';
import buildSidebar from './utils/buildSidebar';
import { scrollToSection } from '@fluke/predator-report-lib/lib/utils';

// components
import { Document } from '@fluke/predator-report-lib';
import { SectionDiv } from '@fluke/predator-report-lib/lib/components';
import {
  SectionTitle,
  CoverPage,
  AssetFields,
  ImageRow,
  Photonotes,
  MarkerTable,
  InfoTable,
  Notes,
} from './components/Sections';
import Sidebar from './components/Sidebar';
import { LoadingOverlay } from '../../components/Overlays';
import SecondaryNavigation from './components/SecondaryNavigation';
import AlertSnackBar from '../../components/utility/AlertSnackBar';

export default function ReportDetail() {
  const [Report] = useState(new ReportManager());

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { userSettings: { temperatureUnits = 'F', currentOrganization = '' } = {} } =
    useContext(UserContext);

  const { id } = useParams();
  const reportData = useSelector((state) => state.reportData);

  // document state
  const [reportInfo, setReportInfo] = useState({});

  // state indicators
  const [isLoading, setIsLoading] = useState(true);
  const [snackbar, setSnackbar] = useState(snackBarStatusEnum.DEFAULT);

  // Document
  const [scale, setScale] = useState(1);
  const [imageSections, setImageSections] = useState([]);

  const [metadata, setMetadata] = useState({});

  const saveReportRef = useRef(
    lodash.debounce(() => {
      Report.save();
    }, 5000),
  );

  const updateReportInfo = (attrs = {}) => {
    setReportInfo((prev) => ({ ...prev, ...attrs }));
  };

  const handleImageEdit = (imageId) => {
    dispatch(setSelectedImageIds([imageId]));
    navigate(routerPathEnum.IMAGE_EDITOR);
  };

  // Document Methods -->

  const saveReport = saveReportRef.current;

  const loadReport = async (info = {}) => {
    await Report.loadReport(info);

    // set stat
    updateReportInfo(Report.getInfo());
    setImageSections(Report.getSections());
    setIsLoading(false);
  };

  const handleFieldUpdate = useCallback(
    (id, type, attrs = {}) => {
      if (!type) return;

      switch (type) {
        case reportInfoKeys.DATA:
          Report.updateInfoData(attrs);
          break;
        case reportInfoKeys.ASSET_INFO:
          Report.updateAssetInfo(id, attrs);
          break;
        case reportInfoKeys.COMPANY_LOGO:
          (async () => {
            await Report.saveLogo(attrs);
            updateReportInfo(Report.getInfo());
          })();
          break;
      }

      if (type !== reportInfoKeys.COMPANY_LOGO) {
        saveReport();
      }

      // update report info
      updateReportInfo(Report.getInfo());
    },
    [Report],
  );

  const handleFinalizeReport = () => {
    Report.setFinalized(true);
    saveReport();
    updateReportInfo(Report.getInfo());
  };

  const handleDelete = (imageId) => {
    Report.deleteImageById(imageId);
    const info = Report.getInfo();
    const sections = Report.buildSections();

    // set state
    updateReportInfo(info);
    setImageSections(sections);

    // save
    saveReport();
  };

  const handleNavClick = (id) => {
    scrollToSection(id, scale);
  };

  const sidebarItems = useMemo(() => {
    return buildSidebar(reportInfo);
  }, [reportInfo?.images, reportInfo?.image_options]);

  useEffect(() => {
    if (id === reportInfo?.options?.id) return;
    dispatch(setActiveDraftReport(id));
  }, [id]);

  useEffect(() => {
    const { reportInfo = {} } = reportData || {};

    const startDocument = async () => {
      let logoURL = reportInfo.company_logo;

      const isValidImage = await checkImageURL(logoURL);

      if (!isValidImage) {
        logoURL = null;
      }

      loadReport({ ...reportInfo, company_logo: logoURL });
    };

    if (reportInfo?.options?.id) {
      startDocument();
    }
  }, [reportData]);

  useEffect(() => {
    Report.setOrganization(currentOrganization);
    Report.setTemperatureUnits(temperatureUnits);
  }, [currentOrganization, temperatureUnits]);

  useEffect(() => {
    const { DATA, OPTIONS, COMPANY_LOGO } = reportInfoKeys;
    const {
      [DATA]: data = {},
      [OPTIONS]: options = {},
      [COMPANY_LOGO]: company_logo = '',
    } = reportInfo || {};

    setMetadata({
      ...data,
      finalized: options?.finalized ?? false,
      company_logo,
    });
  }, [
    reportInfo?.[reportInfoKeys.OPTIONS],
    reportInfo?.[reportInfoKeys.DATA],
    reportInfo?.[reportInfoKeys.COMPANY_LOGO],
  ]);

  useEffect(() => {
    return () => {
      // when page unmounts, save report
      Report.save();
    };
  }, []);

  const contentSections = [
    {
      name: reportSectionKeys.COVER,
      id: 'cover',
      level: 1,
      pageBreak: 'after',
      child: (
        <CoverPage
          data={reportInfo[reportInfoKeys.DATA]}
          logo={reportInfo[reportInfoKeys.COMPANY_LOGO]}
          disabled={reportInfo?.options?.finalized}
          onFieldUpdate={handleFieldUpdate}
        />
      ),
    },
    {
      name: reportSectionKeys.TABLE_OF_CONTENTS,
      id: 'toc',
      level: 1,
    },
    ...imageSections.flatMap((image = {}) => [
      // START OF IMAGE SECTION
      {
        name: image.name,
        id: image.imageId,
        level: 1,
        child: <SectionTitle id={image.imageId} title={image.name} />,
      },
      // ASSET FIELDS
      {
        name: reportSectionKeys.ASSET_FIELDS,
        id: `${image.imageId}#asset-fields`,
        level: 2,
        child: (
          <AssetFields
            imageId={image.imageId}
            value={image.details}
            disabled={reportInfo?.options?.finalized}
            onFieldUpdate={handleFieldUpdate}
          />
        ),
      },
      // IMAGE ROW
      {
        id: `${image.imageId}#thermal-image`,
        name: reportSectionKeys.IMAGE_TITLE,
        level: 2,
        child: (
          <ImageRow
            imageId={image.imageId}
            images={image.images}
            disabled={reportInfo?.options?.finalized}
            onEdit={handleImageEdit}
            onDelete={handleDelete}
          />
        ),
      },
      // TABLE ROW
      {
        id: `${image.imageId}#info-tables`,
        name: reportSectionKeys.DETAILS,
        level: 2,
        child: (
          <div style={styles.row}>
            <MarkerTable markers={image.markers} />
            <InfoTable info={image.info} />
          </div>
        ),
      },
      // PHOTONOTES
      {
        id: `${image.imageId}#photonotes`,
        name: reportSectionKeys.PHOTONOTES,
        level: 2,
        visible: image.photonotes.length > 0,
        child: <Photonotes name="Photonotes" images={image.photonotes} />,
      },
      // NOTES
      {
        id: `${image.imageId}#notes`,
        name: reportSectionKeys.NOTES,
        level: 2,
        pageBreak: 'after',
        visible: image.notes.length > 0,
        child: <Notes name="Notes" notes={image.notes} />,
      },
    ]),
  ]
    .filter(Boolean)
    .map((section) => ({
      ...section,
      child: <SectionDiv>{section.child}</SectionDiv>,
    }));

  return (
    <>
      <LoadingOverlay open={isLoading} overlayStyle={{ zIndex: '100' }} />
      <AlertSnackBar alert={snackbar} setAlert={setSnackbar} />

      <div id="reportDetail" style={styles.container}>
        <SecondaryNavigation metadata={metadata} onFinalize={handleFinalizeReport} />

        <div
          style={{
            ...styles.main,
            visibility: isLoading ? 'hidden' : 'visible',
          }}
        >
          <Sidebar
            items={sidebarItems}
            onClick={handleNavClick}
            onDelete={handleDelete}
            disabled={reportInfo?.options?.finalized}
          />

          <Document
            sections={contentSections}
            onScale={setScale}
            metadata={metadata}
            config={{
              layout: 'a4',
              differentFirstPage: true,
              margin: 0,
            }}
          />
        </div>
      </div>
    </>
  );
}

const styles = {
  container: {
    overflowY: 'hidden',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    gap: 0,
  },

  main: {
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
  },
  row: {
    display: 'flex',
    gap: '1.5em',
  },
};
