const pageSizeOptionsEnum = Object.freeze({
  10: '10',
  20: '20',
  50: '50',
  100: '100',
  250: '250',
  500: '500',
  1000: '1000',
});

export default pageSizeOptionsEnum;
