import React, { useEffect, useMemo, useState } from 'react';
import { getCurrentAuthenticatedUser } from '@fluke/baseline-client-apis';

// utilities
import { NotesManager, ValidSettings } from '@fluke/irviewer';
import { getDateTimeFromMillisecondsTZ } from '../../../../utils/getDateFormat';

// components
import {
  Box,
  TextField,
  Button,
  List,
  ListItem,
  ListItemText,
  Typography,
  Paper,
  IconButton,
} from '@common-ui/ui';
import { Delete } from '@mui/icons-material';

const sortByDate = (objArray = [], reverse = false) => {
  if (!Array.isArray(objArray)) {
    objArray = [objArray];
  }

  return objArray.sort((a, b) => (a.date - b.date) * (reverse ? -1 : 1));
};

export default function UserNotes({ value = [], onChange = () => {} }) {
  const [Notes] = useState(() => new NotesManager(value));

  const [clientNote, setClientNote] = useState('');
  const [currentUser, setCurrentUser] = useState({});

  const sortedNotes = useMemo(() => sortByDate(value, true), [value]);

  useEffect(() => {
    Notes.set(value);
  }, [value]);

  useEffect(() => {
    const getUser = async () => {
      const userData = await getCurrentAuthenticatedUser();
      if (userData != null) {
        setCurrentUser(userData);
      }
    };

    getUser();
  }, [getCurrentAuthenticatedUser]);

  const updateIRViewerNotes = () => {
    const notes = Notes.get();
    onChange({ [ValidSettings.TEXTNOTES]: notes });
  };

  const handleCreate = () => {
    const { userName: name, emailAddr: email } = currentUser;
    if (clientNote) {
      Notes.create({
        name,
        email,
        text: clientNote,
      });

      // reset the active text area and update the notes
      setClientNote('');
      updateIRViewerNotes();
    }
  };

  const handleDelete = async (noteDetails = {}) => {
    const { id = '' } = noteDetails;
    Notes.deleteById(id);
    updateIRViewerNotes();
  };

  const handleTextboxUpdate = (event) => {
    setClientNote(event.target.value);
  };

  return (
    <div style={styles.container}>
      <Box component={Paper} rounded="true" p={2}>
        <TextField
          fullWidth
          value={clientNote}
          onChange={handleTextboxUpdate}
          label="Add a new note..."
          placeholder="Add a new note..."
          multiline
          rows={4}
        />
        <Box display="flex" justifyContent="flex-end" mt={2}>
          <Button
            type="submit"
            variant="contained"
            sx={{ mr: 2 }}
            onClick={handleCreate}
            disabled={!clientNote}
          >
            Add
          </Button>
        </Box>
      </Box>

      {sortedNotes.length > 0 && (
        <Box component={Paper} p={2}>
          <div style={styles.noteHistory}>
            <List style={{ padding: 0 }}>
              {sortedNotes.map((note, index) => (
                <ListItem key={note?.id || index} alignItems="center" style={styles.listItem}>
                  <ListItemText
                    primary={`${note.author || ''} ${getDateTimeFromMillisecondsTZ(
                      note.date,
                      note?.timezone,
                    )}`}
                    secondary={
                      <Typography component="span" variant="body2" color="text.primary">
                        {note.text}
                      </Typography>
                    }
                  />
                  <IconButton onClick={() => handleDelete(note)}>
                    <Delete />
                  </IconButton>
                </ListItem>
              ))}
            </List>
          </div>
        </Box>
      )}
    </div>
  );
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
  },
  noteHistory: {
    padding: '4px',
    gap: '12px',
    margin: '10px 0',
    width: '100%',
    maxHeight: '250px',
    overflowY: 'auto',
    position: 'relative',
    top: '0.5em',
  },
  listItem: {
    padding: 0,
    borderBottom: '1px solid #FFFFFF1F',
  },
};
