import React from 'react';

// components
import { Box, IconButton, ListItem, ListItemText } from '@common-ui/ui';
import { Delete } from '@mui/icons-material';

/**
 * NavRow component.
 *
 * This component represents a navigation button with an optional thumbnail image.
 * It handles click and delete events and can display an icon and additional metadata.
 *
 * @component
 * @param {boolean} active - Boolean indicating if the item is currently active. Defaults to false.
 * @param {string} name - Name of the item. Defaults to an empty string.
 * @param {string} [thumbnail] - URL of the thumbnail image.
 * @param {string} [datetime] - Date and time associated with the item. Defaults to an empty string.
 * @param {Function} [onClick] - Function to handle click event. Receives the click event as an argument.
 * @param {Function} [onDelete] - Function to handle delete event. Receives the click event as an argument.
 * @param {JSX.Element} [Icon] - Optional icon component to display.
 * @param {Object} [style] - Optional inline styles to apply to the list item.
 * @param {number} [indent=1] - Indentation level for the item. Defaults to 1.
 * @returns {JSX.Element} Rendered NavRow component.
 */
export default function NavRow({
  active = false,
  name = '',
  thumbnail,
  datetime = '',
  Icon,
  style = {},
  indent = 1,
  onClick,
  onDelete,
}) {
  const handleClick = (event) => {
    event.stopPropagation();
    onClick?.(event);
  };

  const handleDelete = (event) => {
    event.stopPropagation();
    onDelete?.(event);
  };

  const styles = {
    container: (theme) => ({
      display: 'flex',
      gap: theme.spacing(1),
      alignItems: 'center',
      width: '100%',

      backgroundColor: active ? theme.palette.primary.light : 'transparent',
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
    }),

    listitem: (theme) => ({
      width: '100%',
      backgroundColor: active ? theme.palette.primary.light : 'transparent',
      padding: 0,
      paddingLeft: `${indent}em`,
      paddingRight: theme.spacing(1),
      gap: theme.spacing(1),
      cursor: 'pointer',
      alignContent: 'center',
      ...style,
    }),
  };

  return (
    <Box sx={styles.container} onClick={handleClick}>
      <ListItem sx={styles.listitem}>
        {thumbnail && (
          <span>
            <img src={thumbnail} width="50" />
          </span>
        )}
        <ListItemText primary={name} secondary={datetime} />
        {Icon && (
          <IconButton onClick={handleClick}>
            <Icon />
          </IconButton>
        )}
        {onDelete && (
          <IconButton onClick={handleDelete}>
            <Delete style={{ backgroundColor: 'transparent' }} />
          </IconButton>
        )}
      </ListItem>
    </Box>
  );
}
