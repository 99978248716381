import React from 'react';
import Pagination from './Pagination';
import EditImage from './EditImage';
import QuickReport from './QuickReport';
import Delete from './Delete';
import { Sort } from './Sort';
import styled from '@emotion/styled';

const Container = styled.div`
  grid-area: action;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 0.75em;
  gap: 0.75em;
  background: ${({ theme }) => theme.palette.grey[800]};
`;

const Content = styled.div`
  display: flex;
`;

/**
 * The ActionBar component for the Image Management screen
 * @returns {JSX.Element}
 */
export default function ActionBar() {
  return (
    <Container>
      <Pagination />
      <Content>
        <EditImage />
        <QuickReport />
        <Delete />
        <Sort />
      </Content>
    </Container>
  );
}
