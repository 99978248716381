import React, { useContext } from 'react';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { useSelector } from 'react-redux';
import ButtonTextIcon from '../../../components/buttons/ButtonTextIcon';
import { ModalContext } from '../../../context';
import ModalEnum from '../../../components/modals/enums/ModalEnum';

/**
 * Quick Report button for the Image Management screen
 * @returns {JSX.Element}
 */
export default function QuickReport() {
  const { selectedImageIds } = useSelector((state) => state.images);
  const { handleModal } = useContext(ModalContext);

  return (
    <ButtonTextIcon
      icon={<InsertDriveFileIcon />}
      disabled={!selectedImageIds.length}
      onClick={() => handleModal(ModalEnum.IMAGE_MANAGEMENT_REPORT_MODAL)}
      tooltip="Quick Report"
    >
      Quick Report
    </ButtonTextIcon>
  );
}
