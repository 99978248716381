import React, { useState } from 'react';
// mui -->
import { Button, IconButton, Modal, Typography, TextField } from '@mui/material';
import { Close } from '@mui/icons-material';

/**
 * CreateAssetModal is a functional component that renders a modal for creating a new asset.
 * The modal contains a text field where the user can input the name for the new asset.
 *
 * If the user closes the modal without creating the asset, the inputted name persists until
 * the next time the modal is opened. If the user creates the asset, the inputted name is
 * cleared from the state of the component.
 *
 * The CreateAssetModal uses React hooks for state management and renders common UI components
 * such as Modal, Typography, IconButton, TextField, and Button.
 *
 * @param {boolean} isOpen - A flag indicating whether the modal is currently open.
 * @param {function} onClose - A function to be called when the modal is closed. This function
 *                             is called with the inputted name as an argument, if any.
 *
 * @returns {JSX.Element} A JSX component rendering a modal for creating a new asset.
 */
export default function CreateAssetModal({ isOpen = false, onClose = () => {} }) {
  const [value, setValue] = useState('');

  const handleClose = (name) => {
    if (name) {
      // reset value if name is valid
      setValue('');
    }
    onClose(name);
  };

  return (
    <Modal open={isOpen} onClose={() => handleClose()}>
      <div style={styles.container}>
        <div style={styles.header}>
          <Typography style={styles.title}>Create New Asset</Typography>
          <IconButton onClick={() => handleClose()}>
            <Close style={styles.closeIcon} />
          </IconButton>
        </div>
        <TextField
          id="outlined-basic"
          label="Asset Name"
          variant="outlined"
          value={value}
          onChange={(event) => setValue(event.target.value)}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              handleClose(value);
            }
          }}
          style={{ width: '100%' }}
        />
        <div style={styles.buttons}>
          <Button variant="contained" style={styles.cancelButton} onClick={() => handleClose()}>
            Cancel
          </Button>
          <Button variant="contained" sx={styles.acceptButton} onClick={() => handleClose(value)}>
            Create
          </Button>
        </div>
      </div>
    </Modal>
  );
}

const styles = {
  title: {
    textAlign: 'left',
    width: '500px',
    fontSize: '22px',
  },

  container: {
    // mui -->
    boxShadow: 3,
    // css -->
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '600px',
    opacity: 1,
    padding: '24px',
    background: 'white',
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },

  // buttons -->
  acceptButton: {
    width: '8rem',
    color: 'white',
    backgroundColor: 'secondary.main',
    '&:hover': {
      backgroundColor: 'secondary.dark', // Change border color when button is focused
    },
  },
  cancelButton: {
    width: '8rem',
    color: 'grey',
    backgroundColor: 'white',
    borderColor: 'grey',
    '&:hover': {
      borderColor: 'grey', // Change border color on hover
    },
    '&.MuiFocused': {
      borderColor: 'grey', // Change border color when button is focused
    },
  },

  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  closeIcon: {
    justifySelf: 'flex-end',
  },

  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'end',
    height: 'calc(100% - 24px)',
    gap: '8px',
  },
  subtitle: {
    display: 'flex',
    alignItems: 'stretch',
    paddingTop: '16px',
    gap: '4px',
  },
};
