import React from 'react';
// enums -->
import { AssetFields } from '../../lib/enum/AssetFieldsEnum';
import { AssetProfileHeaders } from '../../lib/enum/AssetProfileHeadersEnum';
// mui -->
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@common-ui/ui';
import { ExpandMore } from '@mui/icons-material';
// components -->
import AssetTextField from '../AssetTextField';

/**
 * ProfileInfo is a functional component that renders a form of profile information
 * within an accordion component. The information includes Name, Location, Assigned Routes,
 * Status, and Last Inspection. The accordion component is expanded by default.
 *
 * @param {Object} payload - The asset containing the profile information.
 * @param {string} payload.name - The name of the entity.
 * @param {string} payload.location - The location of the entity.
 * @param {Array} payload.assignedRoutes - The array of routes assigned to the entity.
 * @param {boolean} payload.status - The status of the entity; true for active, false for inactive.
 * @param {Date} payload.lastInspection - The date of the last inspection of the entity.
 *
 * @returns {JSX.Element} A JSX component rendering an accordion with profile information.
 */

export default function ProfileInfo({ payload }) {
  return (
    <div style={styles.main}>
      <div style={styles.container}>
        {/* PROFILE INFO --> */}
        <Accordion defaultExpanded disableGutters sx={styles.accordionContainer}>
          <AccordionSummary expandIcon={<ExpandMore />} sx={styles.accordionSummary}>
            <Typography variant="h6">{AssetProfileHeaders.PROFILE_INFO}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div style={styles.accordionContent}>
              <div id="accordion-1-row-1" style={styles.row}>
                <AssetTextField
                  label={AssetFields.ASSET_ID.label}
                  value={payload[AssetFields.ASSET_ID.id]}
                />
                <AssetTextField
                  label={AssetFields.LOCATION.label}
                  value={payload[AssetFields.LOCATION.id]}
                />
              </div>
              <div id="accordion-1-row-2" style={styles.row}>
                <AssetTextField
                  select
                  label={AssetFields.ASSIGNED_ROUTES.label}
                  options={payload[AssetFields.ASSIGNED_ROUTES.id]}
                />
                <AssetTextField
                  select
                  label={AssetFields.STATUS.label}
                  value={payload[AssetFields.STATUS.id] ? 'Active' : 'Inactive'}
                />

                <AssetTextField
                  date
                  label={AssetFields.LAST_INSPECTION.label}
                  value={new Date(payload[AssetFields.LOCATION.id]).toLocaleString()}
                />
              </div>
              <div id="accordion-1-row-2" style={styles.row}>
                <AssetTextField
                  label={AssetFields.DESCRIPTION.label}
                  value={payload[AssetFields.DESCRIPTION.id]}
                  multiline
                  rows={5}
                />
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
        {/* OPTIONS --> */}
        <Accordion defaultExpanded disableGutters sx={styles.accordionContainer}>
          <AccordionSummary expandIcon={<ExpandMore />} sx={styles.accordionSummary}>
            <Typography variant="h6">{AssetProfileHeaders.OPTIONS}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div style={styles.accordionContent}>
              <div id="accordion-2-row-1" style={styles.row}>
                <AssetTextField
                  label={AssetFields.MANUFACTURER.label}
                  value={payload[AssetFields.MANUFACTURER.id]}
                />
                <AssetTextField
                  label={AssetFields.MODEL.label}
                  value={payload[AssetFields.MODEL.id]}
                />
                <AssetTextField
                  label={AssetFields.TYPE.label}
                  value={payload[AssetFields.TYPE.id]}
                />
              </div>
              <div id="accordion-2-row-1" style={styles.row}>
                <AssetTextField
                  label={AssetFields.SERIAL_NUMBER.label}
                  value={payload[AssetFields.SERIAL_NUMBER.id]}
                />
                <AssetTextField
                  label={AssetFields.ASSET_TAG_ID.label}
                  value={payload[AssetFields.ASSET_TAG_ID.id]}
                />
                <AssetTextField label={AssetFields.NSN.label} value={payload[AssetFields.NSN.id]} />
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>

      {/* TESTPOINTS --> */}
      <div style={styles.container}>
        <Accordion defaultExpanded disableGutters sx={styles.accordionContainer}>
          <AccordionSummary expandIcon={<ExpandMore />} sx={styles.accordionSummary}>
            <Typography variant="h6">{AssetProfileHeaders.TESTPOINTS}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div style={styles.accordionContent}>
              <div id="accordion-1-row-1" style={styles.row}>
                <AssetTextField
                  label={AssetFields.TESTPOINTS.label}
                  value={payload[AssetFields.TESTPOINTS.id]}
                />
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
}

const styles = {
  main: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  container: {
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    borderRadius: '4px',
    backgroundColor: 'white',
    padding: '16px',
  },
  accordionContainer: {
    boxShadow: 'none',
    '&.MuiAccordion-root:before': {
      border: 'none',
      backgroundColor: 'transparent',
    },
  },
  accordionSummary: {
    gap: '16px',
    padding: '0px',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    borderBottom: '1px solid #E0E0E0',
  },
  accordionContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    margin: 0,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    gap: '20px',
    justifyContent: 'start',
  },
};
