/**
 * Enums used in the Sort component
 */
const SortEnum = Object.freeze({
  ASCENDING: 'asc',
  DESCENDING: 'desc',
  SORT: 'Sort',
});

export default SortEnum;
