import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Tooltip } from '@mui/material';

export default function DoneEditing({ style }) {
  const navigate = useNavigate();

  const styles = {
    container: {
      display: 'flex',
      flex: 1,
    },
    button: {
      ...style,
    },
  };

  return (
    <Tooltip title="Finish editing">
      <div style={styles.container}>
        <Button
          onClick={() => navigate(-1)}
          variant="contained"
          color="secondary"
          sx={styles.button}
        >
          Done
        </Button>
      </div>
    </Tooltip>
  );
}
