import React from 'react';
// enums -->
import { EntryType } from '../../lib/enum/EntryTypeEnum';
import { EntryAction } from '../../lib/enum/EntryActionEnum';
// mui -->
import { Menu, MenuItem } from '@common-ui/ui';
// components -->
import menuItems from './ContextMenuItems';

/**
 * ContextMenu is a functional component that renders a context (right-click) menu at a
 * given position. This menu will close either when an item is clicked or when the menu
 * itself is clicked if an onClose function is provided.
 *
 * The ContextMenu component utilizes Menu and MenuItem components from '@mui/material'.
 *
 * @param {Object} position - The x and y coordinates where the context menu should open.
 * @param {function} [onClose=null] - Optional function to be called when the menu or a menu item
 *                                    is clicked. If this function is provided, it will receive
 *                                    the id of the clicked menu item as an argument.
 *
 * @returns {JSX.Element} A JSX component rendering a context menu with items mapped from
 *                        the imported 'menuItems'.
 */
export default function ContextMenu({ position, onClose = () => {}, variant = EntryType.FOLDER }) {
  let dropdownItems = menuItems;

  if (variant === EntryType.FILE) {
    dropdownItems = menuItems.filter(
      (item) => ![EntryAction.ADD_ASSET, EntryAction.ADD_FOLDER].includes(item.id),
    );
  }

  return (
    <Menu
      id="right-click-menu"
      anchorReference="anchorPosition"
      anchorPosition={position}
      keepMounted
      open={Boolean(position)}
      onClose={onClose}
    >
      {dropdownItems.map(({ id, name }) => (
        <MenuItem key={`menu-item_${id}`} onClick={() => onClose(id)}>
          {name}
        </MenuItem>
      ))}
    </Menu>
  );
}
