import React from 'react';
// mui -->
import { Box, Button, IconButton, Modal, Typography } from '@common-ui/ui';
import { Close } from '@mui/icons-material';

/**
 * ConfirmDeleteModal is a functional component that renders a modal for confirming the deletion
 * of an item from a hierarchy. The modal displays a message asking the user to confirm the
 * deletion and information about the deletion process.
 *
 * If the user confirms the deletion, the `onSelect` function is called with `true` as an argument.
 * If the user cancels the deletion or closes the modal, the `onSelect` function is called with
 * `false` as an argument.
 * *
 * @param {boolean} isOpen - A flag indicating whether the modal is currently open.
 * @param {function} onSelect - A function to be called when the user makes a selection.
 *                              This function is called with a boolean argument indicating the user's choice.
 *
 * @returns {JSX.Element} A JSX component rendering a modal for confirming deletion of an item.
 */
export default function ConfirmDeleteModal({ isOpen = false, onSelect = () => {} }) {
  const title = 'Confirm Delete';
  const description = [
    'Deleting this item will remove the asset or all associated assets from the Assets List.',
    'Any assets contained within the immediate sub-folders will also be removed.',
    'Assets are not permanently deleted but moved to "Deleted Assets".',
  ];

  return (
    <Modal open={isOpen} onClose={() => onSelect(false)}>
      <Box sx={styles.container}>
        <div style={styles.header}>
          <Typography style={styles.title}>{title}</Typography>
          <IconButton onClick={() => onSelect(false)}>
            <Close style={styles.closeIcon} />
          </IconButton>
        </div>

        <div>
          <Typography>{`${description[0]} ${description[1]} ${description[2]}`}</Typography>
        </div>

        <div style={styles.buttons}>
          <Button variant="contained" style={styles.cancelButton} onClick={() => onSelect(false)}>
            Cancel
          </Button>
          <Button variant="contained" sx={styles.acceptButton} onClick={() => onSelect(true)}>
            Delete
          </Button>
        </div>
      </Box>
    </Modal>
  );
}

const styles = {
  title: {
    textAlign: 'left',
    width: '500px',
    fontSize: '22px',
  },

  container: {
    // mui -->
    boxShadow: 3,
    // css -->
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '600px',
    opacity: 1,
    padding: '24px',
    background: 'white',
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },

  // buttons -->
  acceptButton: {
    width: '8rem',
    color: 'white',
    backgroundColor: 'secondary.main',
    '&:hover': {
      backgroundColor: 'secondary.dark', // Change border color when button is focused
    },
  },
  cancelButton: {
    width: '8rem',
    color: 'grey',
    backgroundColor: 'white',
    borderColor: 'grey',
    '&:hover': {
      borderColor: 'grey', // Change border color on hover
    },
    '&.MuiFocused': {
      borderColor: 'grey', // Change border color when button is focused
    },
  },

  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  closeIcon: {
    justifySelf: 'flex-end',
  },

  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'end',
    height: 'calc(100% - 24px)',
    gap: '8px',
  },
  subtitle: {
    display: 'flex',
    alignItems: 'stretch',
    paddingTop: '16px',
    gap: '4px',
  },
  iconInfo: {
    color: 'grey',
  },
};
