/**
 * Compare two arrays of items to determine if they are equal.
 *
 * This function performs a deep comparison of two arrays of items,
 * including their subitems, to check if they are identical in structure
 * and content.
 *
 * @param {Array} prevItems - The previous array of items.
 * @param {Array} nextItems - The next array of items.
 * @returns {boolean} True if the items and their subitems are equal, false otherwise.
 */
function compareItems(prevItems = [], nextItems = []) {
  // Check if item arrays are of equal length
  if (prevItems.length !== nextItems.length) {
    return false;
  }

  // Check each item and its subitems
  for (const [index, prevItem] of prevItems.entries()) {
    const nextItem = nextItems[index];

    // Check if item names are equal
    if (prevItem.name !== nextItem.name) {
      return false;
    }

    const prevSubitems = prevItem?.subitems || [];
    const nextSubitems = nextItem?.subitems || [];

    if (prevSubitems.length !== nextSubitems.length) {
      // If only one item has subitems, return false
      return false;
    }

    // If both have subitems, recursively compare them
    if (!compareItems(prevSubitems, nextSubitems)) {
      return false;
    }
  }

  return true;
}

/**
 * Compare two sets of props to determine if they are equal.
 *
 * This function uses a combination of deep comparison for items and
 * shallow comparison for functions and simple properties to determine
 * if two sets of props are equal.
 *
 * @param {Object} prev - The previous props.
 * @param {Object} next - The next props.
 * @returns {boolean} True if the props are equal, false otherwise.
 */
export default function arePropsEqual(prev, next) {
  return (
    compareItems(prev.items, next.items) &&
    Object.is(prev.onClick, next.onClick) &&
    Object.is(prev.onUpload, next.onUpload) &&
    Object.is(prev.onDelete, next.onDelete) &&
    prev.hasImages === next.hasImages
  );
}
