import React from 'react';

import { useNavigate } from 'react-router-dom';
import ButtonTextIcon from '../../../components/buttons/ButtonTextIcon';
import { Edit } from '@mui/icons-material';
import routerPathEnum from '../../../enums/routerPathEnum';
import { useSelector } from 'react-redux';

/**
 * Edit the selected images
 * @returns {JSX.Element}
 */
export default function EditImage() {
  const navigate = useNavigate();
  const { selectedImageIds = [] } = useSelector((state) => state.images);
  const disabled = selectedImageIds.length === 0;

  return (
    <ButtonTextIcon
      icon={<Edit />}
      disabled={disabled}
      onClick={() => navigate({ pathname: routerPathEnum.IMAGE_EDITOR })}
      tooltip="Edit Images"
    >
      Image Editor
    </ButtonTextIcon>
  );
}
