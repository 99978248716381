import React from 'react';

export default function Dropdown({ options = [], onSelect = () => {}, enableDropdown = false }) {
  if (!enableDropdown || options.length === 0) {
    return null;
  }
  const handleSelect = (e, option) => {
    e.stopPropagation();
    e.preventDefault();
    onSelect?.(option);
  };

  return (
    <ul style={styles.menu}>
      {options.map((option, index) => (
        <li key={index} style={styles.item} onMouseDown={(e) => handleSelect(e, option)}>
          {option}
        </li>
      ))}
    </ul>
  );
}

const styles = {
  menu: {
    position: 'absolute',
    top: '100%',
    left: 0,
    width: '100%',
    backgroundColor: '#fff',
    border: '1px solid #ccc',
    borderRadius: '4px',
    boxShadow: '0px 2px 4px rgba(0,0,0,0.2)',
    zIndex: 9999,
    padding: '0',
    margin: '0',
    listStyle: 'none',
  },
  item: {
    padding: '8px 12px',
    cursor: 'pointer',
    '&:hover': {
      background: '#efefef',
    },
  },
};
