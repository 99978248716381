import React, { useCallback } from 'react';

// utilities
import { ValidSettings } from '@fluke/irviewer';
import arePropsEqual from './arePropsEqual';

// components
import {
  ToolFusion,
  ToolPip,
  ToolAutoScale,
  ToolRotate,
  ToolMarkerVisible,
  ToolZoom,
} from './Tools';
import StandardMarkers from './StandardMarkers';
import UserdefinedMarkers from './UserdefinedMarkers';
import Annotations from './Annotations';

function Toolbar({
  settings = {},
  onChange = null,
  onActiveShapeUpdate = null,
  imageScale = 1,
  handleImageScale = null,
}) {
  const {
    [ValidSettings.ALPHA_LEVEL]: irAlphaLevel,
    [ValidSettings.PIP_MODE]: pipMode,
    [ValidSettings.PALETTE_SCALE_MODE]: paletteScaleMode = '',
    [ValidSettings.IR_ORIENTATION]: irOrientation,
    [ValidSettings.MARKERS_STANDARD]: standardMarkers,
    [ValidSettings.GLOBAL_MARKER_VISIBLE]: globalMarkerVisible,
  } = settings;

  const handleOnChange = useCallback(
    (nextSetting = {}) => {
      if (Object.keys(nextSetting).length > 0) {
        onChange?.(nextSetting);
      }
    },
    [onChange],
  );

  return (
    <div id="toolbar-container" style={styles.center}>
      <div id="toolbar" style={styles.toolbar}>
        <ToolFusion value={irAlphaLevel} onChange={handleOnChange} />
        <ToolPip value={pipMode} onChange={handleOnChange} />
        <ToolAutoScale paletteScaleMode={paletteScaleMode} onChange={handleOnChange} />
        <ToolRotate value={irOrientation} onChange={handleOnChange} />
        <Annotations onClick={onActiveShapeUpdate} />
        <UserdefinedMarkers onClick={onActiveShapeUpdate} />
        <StandardMarkers selected={standardMarkers} onChange={handleOnChange} />
        <ToolMarkerVisible value={globalMarkerVisible} onChange={handleOnChange} />
        <ToolZoom value={imageScale} onChange={handleImageScale} min={0.1} max={1} step={0.1} />
      </div>
    </div>
  );
}

const styles = {
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  toolbar: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  center: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  separator: {
    display: 'flex',
    height: '24px',
    width: '2px',
    borderLeft: '1px solid white',
  },
};

export default React.memo(Toolbar, arePropsEqual);
