import { AssetType } from './AssetTypeEnum';

export const AssetColumnHeaders = Object.freeze({
  TAG: {
    label: 'Tag',
    id: AssetType.TAG,
  },
  NAME: {
    label: 'Name',
    id: AssetType.NAME,
  },
  ASSET_ID: {
    label: 'Asset Id',
    id: AssetType.ASSET_ID,
  },
  LOCATION: {
    label: 'Location',
    id: AssetType.LOCATION,
  },
  ASSIGNED_ROUTES: {
    label: 'Assigned Routes',
    id: AssetType.ASSIGNED_ROUTES,
  },
  STATUS: {
    label: 'Status',
    id: AssetType.STATUS,
  },
  LAST_INSPECTION: {
    label: 'Last Inspection',
    id: AssetType.LAST_INSPECTION,
  },
  ACTION: {
    label: 'Action',
    id: 'action',
  },
});
