import React from 'react';
import { InputAdornment, TextField, Tooltip } from '@common-ui/ui';

const CHAR_TAU = 'τ';

function StringToDecimal(value, precision = 2) {
  return parseFloat(parseFloat(value).toFixed(precision));
}

function constrainToRange(value, min = 0, max = 1) {
  if (isNaN(value)) {
    value = max;
  }

  return Math.max(min, Math.min(max, value));
}

export default function FieldTransmission({ value = 1, onChange }) {
  const handleChange = (evt) => {
    const value = evt.target.value;

    // convert to Decimal and constrain [0, 1]
    let newClientTransmission = StringToDecimal(value, 2);
    newClientTransmission = constrainToRange(newClientTransmission, 0, 1);

    onChange?.({ capturedTransmissionFactor: newClientTransmission });
  };

  const transmissionTooltip = (
    <>
      Range between
      <br />
      {`0 < ${CHAR_TAU} < 1`}
    </>
  );

  return (
    <Tooltip title={transmissionTooltip}>
      <TextField
        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', step: 0.01 }}
        type="number"
        style={{ width: '100%' }}
        id="transmission_input"
        label="Transmission"
        value={value}
        onChange={handleChange}
        InputProps={{
          startAdornment: <InputAdornment position="start">{`${CHAR_TAU} =`}</InputAdornment>,
        }}
      />
    </Tooltip>
  );
}
