/**
 * entries is an array of objects that represent items (files and folders) in a file hierarchy. The items are structured as an adjacency list, where each entry object has an id, name, tag, and an optional parent id property.
 * In this adjacency list representation, each entry object represents a node in the hierarchy. The parent id property is used to establish the "edges" between the nodes, indicating the parent-child relationship in the hierarchy.
 *
 * @typedef {Object} Entry
 * @property {string} tag - The tag of the entry, which indicates the type of the entry. Can be either 'file' or 'folder'.
 * @property {string} name - The name of the entry.
 * @property {string} id - The unique identifier of the entry.
 * @property {string} [parent] - The unique identifier of the parent of the entry. This property is optional for root items.
 *
 * @type {Entry[]}
 */
const entries = [
  // root item -->
  {
    tag: 'folder',
    name: 'Company A',
    id: '2d85b20b-0844-49ed-97fb-3e89b95cbb15',
    parent: '',
  },
  {
    tag: 'folder',
    name: 'North Building',
    id: 'ecf0e760-429e-44c0-83cc-da0277c50abf',
    parent: '2d85b20b-0844-49ed-97fb-3e89b95cbb15',
  },
  {
    tag: 'folder',
    name: 'Zone_10',
    id: '28f1f610-2ee5-4f54-801b-d52df5adf19f',
    parent: 'ecf0e760-429e-44c0-83cc-da0277c50abf',
  },
  {
    tag: 'file',
    id: 'ed51d54e-5a25-4731-a357-bca4b7124d51',
    name: 'Battery Pack',
    parent: '28f1f610-2ee5-4f54-801b-d52df5adf19f',
  },
  {
    tag: 'file',
    id: '9f724b0f-7343-4a2e-89b7-b9ea4393ef03',
    name: 'DC/DC Converter',
    parent: '28f1f610-2ee5-4f54-801b-d52df5adf19f',
  },
  {
    tag: 'folder',
    name: 'Zone_11',
    id: '1185ca64-4963-465f-9767-fa695416adb8',
    parent: 'ecf0e760-429e-44c0-83cc-da0277c50abf',
  },
  {
    tag: 'file',
    id: 'fd046267-12e7-49ab-96de-39c8b12087f7',
    name: 'Inverter',
    parent: '1185ca64-4963-465f-9767-fa695416adb8',
  },
  {
    tag: 'file',
    id: '98ab6a90-faaf-424a-9a7b-8372e54c6288',
    name: 'AC/DC Disconnect',
    parent: '1185ca64-4963-465f-9767-fa695416adb8',
  },
  {
    tag: 'file',
    id: '377a0762-11d3-4dde-a0aa-86557bd53777',
    name: 'Combiner',
    parent: '1185ca64-4963-465f-9767-fa695416adb8',
  },
  {
    tag: 'file',
    id: '59351390-591f-4552-9603-a07ac9cd0b07',
    name: 'Batteries',
    parent: '2d85b20b-0844-49ed-97fb-3e89b95cbb15',
  },
  {
    tag: 'file',
    id: '1595ed74-a349-4e43-962e-d9ac40c73796',
    name: 'Breaker Box',
    parent: '2d85b20b-0844-49ed-97fb-3e89b95cbb15',
  },

  // root item -->
  {
    tag: 'folder',
    name: 'Company B',
    id: 'b2bd4fc6-f8fd-4f5b-813c-16aabe54108b',
    parent: '',
  },
  {
    tag: 'folder',
    name: 'Bangalore',
    id: '7a1a54ac-90e8-4e99-81f1-bd15f480deff',
    parent: 'b2bd4fc6-f8fd-4f5b-813c-16aabe54108b',
  },
  {
    tag: 'file',
    id: 'd967851f-291b-4755-a2e2-9910f4253a15',
    name: 'Fuse',
    parent: '7a1a54ac-90e8-4e99-81f1-bd15f480deff',
  },
  {
    tag: 'file',
    id: '3632e262-85b1-4cf1-8fc6-85c902daccc5',
    name: 'DC Converter',
    parent: '7a1a54ac-90e8-4e99-81f1-bd15f480deff',
  },
  {
    tag: 'file',
    id: '45e8a6e3-654b-4ae8-9f6f-7ed392e344ae',
    name: 'Motor Chassis',
    parent: '7a1a54ac-90e8-4e99-81f1-bd15f480deff',
  },
  {
    tag: 'folder',
    name: 'Fluke (US)',
    id: '92e66327-a8ae-4b27-9e7b-cfd74a589221',
    parent: 'b2bd4fc6-f8fd-4f5b-813c-16aabe54108b',
  },
  {
    tag: 'folder',
    name: '75th Street',
    id: '333e9e88-6908-4c48-87a2-95003a6fbc3e',
    parent: '92e66327-a8ae-4b27-9e7b-cfd74a589221',
  },
  {
    tag: 'file',
    id: 'a22baa25-d279-4bd8-a339-5de07ddfa554',
    name: 'Motor Windings',
    parent: '333e9e88-6908-4c48-87a2-95003a6fbc3e',
  },
  {
    tag: 'file',
    id: 'ed56bad3-014b-4918-ba3f-00e0dc642eea',
    name: 'Rotor',
    parent: '333e9e88-6908-4c48-87a2-95003a6fbc3e',
  },
  {
    tag: 'file',
    id: 'fc407265-7a1b-43f4-959d-f4259351b0ed',
    name: 'Stator',
    parent: '333e9e88-6908-4c48-87a2-95003a6fbc3e',
  },
  {
    tag: 'folder',
    name: 'EvGrWy',
    id: 'd0f18af1-1ae3-4989-80a5-129178194be0',
    parent: '92e66327-a8ae-4b27-9e7b-cfd74a589221',
  },
  {
    tag: 'file',
    id: 'fa26f017-e8f9-4130-8520-2eef31decc26',
    name: 'Fan Bearing',
    parent: 'd0f18af1-1ae3-4989-80a5-129178194be0',
  },
  {
    tag: 'file',
    id: 'a7a35507-b7f8-4249-94d8-438622153f31',
    name: 'Terminal Junction Box',
    parent: 'd0f18af1-1ae3-4989-80a5-129178194be0',
  },
  {
    tag: 'file',
    id: '0e123cf1-9bea-4875-85e9-d191b6e92b64',
    name: 'Galvanic Isolation',
    parent: 'd0f18af1-1ae3-4989-80a5-129178194be0',
  },
  {
    tag: 'file',
    id: '7da6c904-598c-4d9e-a72e-295509350f55',
    name: 'Breaker',
    parent: 'd0f18af1-1ae3-4989-80a5-129178194be0',
  },
  {
    tag: 'folder',
    name: 'Seaway',
    id: 'a6876a71-ee0e-468b-8ad0-509a4fe97c45',
    parent: '92e66327-a8ae-4b27-9e7b-cfd74a589221',
  },
  {
    tag: 'file',
    id: '2a6ab342-d794-4716-8c30-6198867cde4c',
    name: 'Combiner Box',
    parent: 'a6876a71-ee0e-468b-8ad0-509a4fe97c45',
  },
  {
    tag: 'file',
    id: '22b9ee3b-bcfb-4b17-b650-b7ced3f3a62a',
    name: 'Driver Coupling',
    parent: 'a6876a71-ee0e-468b-8ad0-509a4fe97c45',
  },
  {
    tag: 'file',
    id: 'b187bed7-1835-4190-81e7-1e625fcedde0',
    name: 'Breaker',
    parent: 'b2bd4fc6-f8fd-4f5b-813c-16aabe54108b',
  },
  {
    tag: 'file',
    id: 'c23c656b-a59a-4953-89f2-87ae38eff572',
    name: 'Charge Controller',
    parent: 'b2bd4fc6-f8fd-4f5b-813c-16aabe54108b',
  },
  {
    tag: 'folder',
    name: 'European Offices',
    id: 'eb402f62-0e58-4863-a99c-511f149be41b0',
    parent: 'b2bd4fc6-f8fd-4f5b-813c-16aabe54108b',
  },
  {
    tag: 'folder',
    name: 'France',
    id: '91364164-c4d3-481a-b6f4-06fd2d0f51a0',
    parent: 'eb402f62-0e58-4863-a99c-511f149be41b0',
  },
  {
    tag: 'folder',
    name: 'Romania',
    id: 'ff152f55-6c67-4d9d-8b70-de20246c786a',
    parent: 'eb402f62-0e58-4863-a99c-511f149be41b0',
  },

  // root item -->
  {
    tag: 'folder',
    name: 'Company C',
    id: '652cdf5e-e8cd-42f8-9086-149af80880e0',
    parent: '',
  },
  {
    tag: 'folder',
    name: 'Empty Folder',
    id: 'e24f2ca6-3241-4b04-8fe4-c1a243c66486',
    parent: '652cdf5e-e8cd-42f8-9086-149af80880e0',
  },
  {
    tag: 'file',
    id: '9f724b0f-7343-4a2e-89b7-b9ea4393ef03', // doop
    name: 'DC/DC Converter',
    parent: '652cdf5e-e8cd-42f8-9086-149af80880e0',
  },
  {
    tag: 'file',
    id: '3a102ed7-3c3c-42be-9d55-b2cdfd8493b7',
    name: 'PF Correction',
    parent: '652cdf5e-e8cd-42f8-9086-149af80880e0',
  },

  // root item -->
  {
    tag: 'folder',
    name: 'Location_nested',
    id: 'da7d09a9-11cb-4d6e-93d3-c90e193f14e2',
    parent: '',
  },
  {
    tag: 'file',
    id: '14720bc7-9976-401b-94a8-595fce45977d',
    name: 'Circuit Breaker',
    parent: 'da7d09a9-11cb-4d6e-93d3-c90e193f14e2',
  },
  {
    tag: 'folder',
    name: 'Level A',
    id: 'd3067fd2-0308-4d2e-b0f4-291e558b6fbd',
    parent: 'da7d09a9-11cb-4d6e-93d3-c90e193f14e2',
  },
  {
    tag: 'file',
    id: 'f48b1c34-3c8d-4846-a269-5668677378cf', // DOOP
    name: 'Transformer',
    parent: 'd3067fd2-0308-4d2e-b0f4-291e558b6fbd',
  },
  {
    tag: 'folder',
    name: 'Level B',
    id: '502aad5e-c723-4b0a-9111-1150360ec572',
    parent: 'd3067fd2-0308-4d2e-b0f4-291e558b6fbd',
  },
  {
    tag: 'file',
    id: '83b87a8b-1c00-4f8c-90db-0bce81b75b62', // DOOP
    name: 'Surge Protection',
    parent: '502aad5e-c723-4b0a-9111-1150360ec572',
  },
  {
    tag: 'folder',
    name: 'Level C',
    id: '86afabe7-41dd-42d8-9362-6357ed01524b',
    parent: '502aad5e-c723-4b0a-9111-1150360ec572',
  },
  {
    tag: 'file',
    id: '86e75a46-cf9d-4c9b-b80c-c34d06f99417', // DOOP
    name: 'Battery',
    parent: '962648a9-2fe1-4ed5-8d69-d8c09b23bb14',
  },

  // tailing files -->
  // root item -->
  {
    tag: 'file',
    id: '17ec92ce-e9c9-4052-b8f4-97b4e2c644b2',
    name: 'Hydraulic Pump regulator',
    parent: '',
  },
  // root item -->
  {
    tag: 'file',
    id: 'f8276fda-560d-4484-bfaf-878789d10e75',
    name: 'Hydraulic Actuators',
    parent: '',
  },
  // root item -->
  {
    tag: 'file',
    id: 'f50b62df-7774-4ed2-b0e2-1819ea98a3b3',
    name: 'Accumulators',
    parent: '',
  },
];

export default entries;
