import React from 'react';
// enums
import { reportSectionKeys } from '../../enums';

// components
import { Typography } from '@fluke/predator-report-lib/lib/components';
import { Delete, ModeEdit } from '@mui/icons-material';

function SectionImagesButtons(props = {}) {
  const { onEdit = () => {}, onDelete = () => {}, visible = true } = props;

  if (!visible) return null;

  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      gap: '16px',
    },
    button: {
      fontSize: '0.875rem',
      gap: '4px',
      textDecoration: 'none',
      color: '#2b7cd3',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      background: 'none',
      border: 'none',
      padding: 0,
      font: 'Roboto',
      outline: 'inherit',
      fontWeight: 600,
    },
    icon: {
      marginRight: '0.5rem',
      flex: 1,
      alignItems: 'center',
    },
  };

  return (
    <div id="button-row-images" style={styles.container}>
      <button style={styles.button} onClick={onEdit}>
        <ModeEdit />
        Edit
      </button>

      <button style={styles.button} onClick={onDelete}>
        <Delete />
        Remove
      </button>
    </div>
  );
}

export default function ImageSet({
  imageId,
  images,
  disabled,
  onEdit = () => {},
  onDelete = () => {},
}) {
  const handleImageEdit = () => {
    onEdit(imageId);
  };

  const handleImageDelete = () => {
    onDelete(imageId);
  };

  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    images: {
      width: '100%',
      display: 'grid',
      gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
      gap: '24px',
    },
    image: {
      width: '100%',
    },
    button: {
      color: '#2b7cd3',
    },
  };
  return (
    <div>
      <div style={styles.container}>
        <Typography variant="section">{reportSectionKeys.IMAGE_TITLE}</Typography>
        <SectionImagesButtons
          onEdit={handleImageEdit}
          onDelete={handleImageDelete}
          visible={!disabled}
        />
      </div>
      <div style={styles.images}>
        <img src={images[0]} style={styles.image} loading="lazy" />
        <img src={images[1]} style={styles.image} loading="lazy" />
      </div>
    </div>
  );
}
