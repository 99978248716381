import React from 'react';
import { Typography } from '@mui/material';

import {
  FieldBackgroundTemperature,
  FieldEmissivity,
  FieldPalette,
  FieldTransmission,
} from './Fields';
import Status from './Status';

function EditorTools({ settings = {}, paletteDisplayData = {}, onChange = null }) {
  const {
    paletteScheme,
    capturedEmissivity,
    capturedTransmissionFactor,
    capturedBackgroundTempC,
    minDisplayableTempC,
    maxDisplayableTempC,
    status,
    temperatureUnit,
  } = settings || {};

  return (
    <div style={styles.fields}>
      <FieldPalette
        value={paletteScheme}
        onChange={onChange}
        paletteDisplayData={paletteDisplayData}
      />
      <FieldEmissivity value={capturedEmissivity} onChange={onChange} />
      <FieldTransmission value={capturedTransmissionFactor} onChange={onChange} />
      <FieldBackgroundTemperature
        value={capturedBackgroundTempC}
        min={minDisplayableTempC}
        max={maxDisplayableTempC}
        temperatureUnit={temperatureUnit}
        onChange={onChange}
      />
      <Typography>Status</Typography>
      <Status value={status} onChange={onChange} />
    </div>
  );
}

const styles = {
  fields: {
    paddingTop: '12px',
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
  },
};

const arePropsEqual = (prevProps, nextProps) => {
  const prevSettings = prevProps.settings;
  const nextSettings = nextProps.settings;

  const keysToCheck = [
    'paletteScheme',
    'capturedEmissivity',
    'capturedTransmissionFactor',
    'capturedBackgroundTempC',
    'minDisplayableTempC',
    'maxDisplayableTempC',
    'temperatureUnit',
    'status',
  ];

  for (const key of keysToCheck) {
    if (prevSettings[key] !== nextSettings[key]) {
      // Change detected, return false to trigger rerender
      return false;
    }
  }

  return (
    prevProps.paletteDisplayData?.paletteNames === nextProps.paletteDisplayData?.paletteNames &&
    prevProps.onChange === nextProps.onChange
  );
};

export default React.memo(EditorTools, arePropsEqual);
