import React, { useState } from 'react';

// components
import { Box, Button, Modal, Typography } from '@common-ui/ui';
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import AlertSnackBar from '../utility/AlertSnackBar';

const MarkAsCompleteEnum = {
  TITLE: 'Mark report as complete?',
  BODY: 'This action will change the report status from draft to complete. Completed reports are read only and cannot be edited. Would you like to proceed?',
  ACCEPT: 'Mark As Complete',
  CANCEL: 'Cancel',
};

export default function MarkAsCompleteModal({ finalized = false, onAccept }) {
  const [open, setOpen] = useState(false);

  const handleAccept = () => {
    setOpen(false);
    onAccept?.();
  };

  return (
    <Box>
      <AlertSnackBar handleClose={() => setOpen(false)} autoHideDuration={6000} />
      <Button
        variant="outlined"
        color="info"
        id="markAsCompleteButton"
        startIcon={finalized ? <CheckBox /> : <CheckBoxOutlineBlank />}
        onClick={() => setOpen(true)}
        disabled={finalized}
      >
        {MarkAsCompleteEnum.ACCEPT}
      </Button>

      <Modal open={open} onClose={() => setOpen(false)}>
        <Box sx={styles.container}>
          <Typography sx={styles.title} variant="h6" component="h2">
            {MarkAsCompleteEnum.TITLE}
          </Typography>
          <Typography sx={styles.body}>{MarkAsCompleteEnum.BODY}</Typography>
          <Box sx={styles.buttons}>
            <Button sx={styles.cancel} onClick={() => setOpen(false)}>
              {MarkAsCompleteEnum.CANCEL}
            </Button>
            <Button onClick={handleAccept} color="info" variant="text">
              {MarkAsCompleteEnum.ACCEPT}
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}

const styles = {
  container: (theme) => ({
    width: 400,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    padding: theme.spacing(3),
    // modal
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // styling
    bgcolor: 'background.default',
    borderRadius: '4px',
    boxShadow: 24,
    color: theme.palette.text.primary,
  }),
  title: {
    fontWeight: 600,
  },
  buttons: (theme) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    gap: theme.spacing(1),
  }),
  cancel: (theme) => ({
    color: theme.palette.text.primary,
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.text.secondary,
      transition: '250ms',
    },
  }),
};
