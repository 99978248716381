import { configureStore } from '@reduxjs/toolkit';
import imagesReducer from './slices/imagesSlice';
import editorReducer from './slices/editorSlice';
import reportDataReducer from './slices/reportSlice';
import sessionReducer from './slices/sessionSlice';

const store = configureStore({
  reducer: {
    session: sessionReducer,
    images: imagesReducer,
    editorData: editorReducer,
    reportData: reportDataReducer,
  },
});

const useAppDispatch = () => store.dispatch;

export default store;
export { useAppDispatch };
