import React, { useState, useEffect } from 'react';
import { TextField, Tooltip } from '@common-ui/ui';
import { AssetFields } from '../../../../../Assets/lib/enum/AssetFieldsEnum';

/**
 * This is the component for the Asset Tag field in the image editor right sidebar.
 *
 * @param {string} id - The ID of the field.
 * @param {boolean} disabled - Whether the field is disabled.
 * @param {function} onChange - The function to update the asset tag ID.
 * @param {object} groupImageData - The group image data.
 *
 * @returns {JSX.Element}
 */
export default function FieldAssetTagId({ value = '', disabled = false, onChange }) {
  const [fieldValue, setFieldValue] = useState(value);

  useEffect(() => {
    setFieldValue(value);
  }, [value]);

  const handleChange = (evt) => {
    setFieldValue(evt.target.value);
  };

  const handleBlur = (e) => {
    onChange?.(e.target.value);
  };

  return (
    <Tooltip title={AssetFields.ASSET_TAG_ID.label}>
      <TextField
        id={AssetFields.ASSET_TAG_ID.id}
        label={AssetFields.ASSET_TAG_ID.label}
        value={fieldValue}
        placeholder={AssetFields.ASSET_TAG_ID.placeholder}
        disabled={disabled}
        InputLabelProps={{ shrink: true }}
        onChange={handleChange}
        onBlur={handleBlur}
      />
    </Tooltip>
  );
}
