export default function buildImagePreviews(imageList) {
  const getDate = (imageDate) => new Date(imageDate);

  const getMonth = (imageDate) =>
    getDate(imageDate).toLocaleString('default', { month: 'long' }).toString();

  const getYear = (imageDate) => getDate(imageDate).getFullYear();

  return (
    imageList &&
    imageList.reduce((accumulator, image) => {
      const key = `${getMonth(image?.sort_info?.date_taken)} ${getYear(
        image?.sort_info?.date_taken,
      )}`;
      if (!accumulator[key]) {
        accumulator[key] = [];
      }
      accumulator[key].push(image);
      return accumulator;
    }, {})
  );
}
