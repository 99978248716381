import React from 'react';
import { IconButton, FormControl, OutlinedInput, InputAdornment, Typography } from '@common-ui/ui';
import SearchIcon from '@mui/icons-material/Search';
import CreateReport from './CreateReport';
import { useSelector } from 'react-redux';
import { reportsEnum } from '../../enums/reportsEnum';

/**
 * This component displays the title and search bar for the reports screen.
 * It displays the number of reports and the search bar.
 * It also displays the button to create a new report.
 *
 * @returns {JSX.Element}
 */
export default function TitleAndSearch() {
  const { draftReports, completedReports } = useSelector((state) => state.reportData);
  return (
    <div style={styles.container}>
      <div style={styles.reportCounts}>
        <Typography variant="h5">
          {reportsEnum.REPORT} ({draftReports.length + completedReports.length})
        </Typography>
      </div>
      <div style={styles.formContainer}>
        <div style={styles.search}>
          {false && ( // this is currently hidden until the search functionality is implemented
            <FormControl sx={styles.form} variant="outlined">
              <OutlinedInput
                placeholder={reportsEnum.SEARCH_BAR_PLACEHOLDER}
                size="small"
                sx={styles.input}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton edge="end">
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          )}
          <CreateReport />
        </div>
      </div>
    </div>
  );
}

const styles = {
  container: {
    background: 'white',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '1em 1em 0 1em',
  },
  search: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
  },
  formContainer: { display: 'flex', gap: '10px' },
  form: { display: 'flex', alignItems: 'center' },
  input: { width: 400 },
};
