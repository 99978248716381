import React from 'react';
// enums -->
import { EntryAction } from '../../lib/enum/EntryActionEnum';
// mui -->
import { Delete, OpenInBrowser } from '@mui/icons-material';
// components -->
import SideNavButton from '../Buttons/SideNavButton';

export default function NavHeader({ onAction = () => {} }) {
  const items = [
    {
      label: 'All Assets',
      id: EntryAction.SHOW_ALL,
      Icon: () => <OpenInBrowser />,
    },
    {
      label: 'Deleted Assets',
      id: EntryAction.SHOW_DELETED,
      Icon: () => <Delete />,
    },
  ];

  return (
    <div style={styles.container}>
      {items.map(({ id, label, Icon }) => (
        <SideNavButton key={id} id={id} Icon={Icon} onClick={() => onAction('', id)}>
          {label}
        </SideNavButton>
      ))}
    </div>
  );
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '8px',
    alignItems: 'start',
    borderBottom: '1px solid #E0E0E0',
  },
};
