import React from 'react';
import { Backdrop, CircularProgress } from '@mui/material';
import styled from '@emotion/styled';

const Overlay = styled(Backdrop)`
  background: rgba(18, 18, 18, 0.5);
  z-index: 100;
  position: relative;
`;

/**
 * Overlay component that displays a loading spinner
 * relative to the parent element
 * @returns {JSX.Element}
 */
export default function RelativeOverlay() {
  return (
    <Overlay open={true}>
      <CircularProgress color="secondary" />
    </Overlay>
  );
}
