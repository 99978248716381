import React from 'react';
// mui -->
import { Button } from '@common-ui/ui';

export default function SideNavButton({
  children,
  onClick = () => {},
  Icon = <></>,
  style = {},
  ...rest
}) {
  return (
    <Button
      startIcon={<Icon />}
      variant="text"
      color="secondary"
      onClick={onClick}
      style={{ ...styles.button, ...style }}
      {...rest}
    >
      {children}
    </Button>
  );
}

const styles = {
  button: {
    textTransform: 'none',
    fontWeight: 'normal',
    color: '#7F7F7F',
  },
};
