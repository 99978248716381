import logger from '../../utils/logger';
import { imageAPI } from '@fluke/baseline-client-apis';
import { sessionKeyStoreEnum } from '../../enums/sessionKeyStoreEnum';

const {
  SELECTED_YEAR,
  SELECTED_MONTH,
  ACTIVE_HEADER,
  ACTIVE_QUERY,
  SIDEBAR_DATES,
  SEARCH_ID,
  SORTED,
  SORTED_BY,
} = sessionKeyStoreEnum;

export default function _fetchSidebarData(forceReset = false, updateSessionStore) {
  const resetSidebarData = (name, query, data) => {
    updateSessionStore({
      [SELECTED_YEAR]: name,
      [SELECTED_MONTH]: null,
      [ACTIVE_HEADER]: name,
      [ACTIVE_QUERY]: query,
      [SIDEBAR_DATES]: data,
      [SEARCH_ID]: Date.now(),
      [SORTED]: 'desc',
      [SORTED_BY]: 'date_taken',
    });
  };

  return async (dispatch, getState) => {
    const { currentOrganization } = getState().images.session;

    try {
      const response = await imageAPI.sidebarDatesService(currentOrganization);
      const [{ name, query }] = response;
      if (forceReset) {
        resetSidebarData(name, query, response);
      }
    } catch (error) {
      logger.error({ error, function: _fetchSidebarData.name });
    }
  };
}
