import React, { useState, useEffect } from 'react';
import { Typography, Button, Modal } from '@common-ui/ui';
import AlertSnackBar from '../utility/AlertSnackBar';
import standardMarkersEnums from '../../enums/standardMarkersEnums';
import snackBarStatusEnum from '../../enums/snackBarStatusEnum';
import { useAppDispatch } from '../../store/configureStore';
import { clearReportInfo } from '../../store/slices/reportSlice';

export default function DeleteModal({
  header,
  body,
  notification,
  apiHandler,
  setDeleteAllMarkersFlag,
  data,
  openModal = false,
  setOpenDeleteModal,
  virtualCanvas,
  setDeltaT,
  referenceTemperature,
  children,
}) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setSnackbar({ ...snackbar, visible: false });
    openModal && setOpenDeleteModal();
  };
  const [snackbar, setSnackbar] = useState({
    visible: false,
    severity: snackBarStatusEnum.SUCCESS,
    text: notification,
  });

  useEffect(() => {
    setOpen(openModal);
  }, [openModal]);

  const handleDeletion = async () => {
    const dispatch = useAppDispatch();
    await dispatch(clearReportInfo());
    handleClose();
    setSnackbar({ ...snackbar, visible: true });
    if (setDeleteAllMarkersFlag) setDeleteAllMarkersFlag(true);
    if (apiHandler) apiHandler(data);

    const isStandardMarkerIsReferanceTemperature =
      referenceTemperature &&
      referenceTemperature?.markerName &&
      Object.values(standardMarkersEnums).includes(referenceTemperature?.markerName);
    if (isStandardMarkerIsReferanceTemperature) {
      virtualCanvas?.current?.saveReferenceTemperature({});
      setDeltaT(null);
    }
  };

  return (
    <div>
      <AlertSnackBar setSnackbar={setSnackbar} snackbar={snackbar} />
      <Typography onClick={handleOpen} sx={title} variant="title">
        {children}
      </Typography>
      <Modal open={open} onClose={handleClose}>
        <div style={modal}>
          <Typography sx={text} variant="h6" component="h2" color="primary" pb={2}>
            {header}
          </Typography>
          <Typography sx={text} color="primary" pb={3}>
            {body}
          </Typography>
          <div>
            <Button onClick={handleDeletion} variant="contained">
              Delete
            </Button>
            <Button sx={cancelStyling} onClick={handleClose}>
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

const title = {
  display: 'flex',
  alignItems: 'center',
};

const modal = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  padding: '50px',
  background: '#222',
  border: '2px solid #000',
  boxShadow: 24,
};

const text = {
  color: 'white',
};

const cancelStyling = {
  color: 'primary',
  ml: 2,
};
