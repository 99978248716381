import React, { useState } from 'react';
import { Edit, VisibilityOutlined, MoreHoriz } from '@mui/icons-material';
import { Button, Typography, Menu, MenuItem } from '@mui/material';
import { IconButton } from '@common-ui/ui';
import { useNavigate } from 'react-router-dom';
import handleReportDeletion from '../../../utils/handleReportDeletion';
import { reportsEnum } from '../../../enums/reportsEnum';
import { DateFormats, getDate } from '../../../utils/getDateFormat';
import { useAppDispatch } from '../../../store/configureStore';
import { clearReportInfo } from '../../../store/slices/reportSlice';

/**
 * This is a template for the buttons in the grid
 *
 * @param {*} row - The selected row's json data
 * @param {*} id - The selected row's id
 * @param {*} icon - The icon to display on the button
 * @param {*} text - The text to display on the button
 *
 * @returns {JSX.Element}
 */
const GridButton = ({ row, id, icon, text }) => {
  const dispatch = useAppDispatch();
  const styles = {
    buttonText: { color: 'black' },
  };

  const navigate = useNavigate();

  const reportPathTraversal = async () => {
    // Clear the report info before adding new info
    // This is to prevent the previous report info from being displayed
    await dispatch(clearReportInfo());
    navigate({
      pathname: `/report/${id}`,
      state: { row },
    });
  };
  return (
    <Button startIcon={icon} onClick={reportPathTraversal}>
      <Typography variant="caption" sx={styles.buttonText}>
        {text}
      </Typography>
    </Button>
  );
};

/**
 * This is the menu for the delete button
 *
 * @param {*} id - The selected row's id
 * @returns {JSX.Element}
 */
const RowMenu = ({ id }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleDelete = () => {
    handleReportDeletion(id);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton onClick={handleClick}>
        <MoreHoriz fontSize="small" />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={handleDelete}>{reportsEnum.DELETE}</MenuItem>
      </Menu>
    </div>
  );
};

/**
 * This function renders the edit buttons for the grid
 * @param (*) row - The selected row's json data
 * @param {*} id - The selected row's id
 *
 * @returns {JSX.Element}
 */
const RenderEditButtons = ({ row, id }) => {
  const styles = {
    editButtons: { display: 'flex', flexGrow: 1, justifyContent: 'center' },
  };
  return (
    <div style={styles.editButtons}>
      <GridButton row={row} id={id} icon={<Edit />} text={reportsEnum.EDIT} />
      <RowMenu id={id} />
    </div>
  );
};

/**
 * This function renders the review buttons for the grid
 * @param (*) row - The selected row's json data
 * @param {*} id - The selected row's id
 * @returns {JSX.Element}
 */
const RenderReviewButtons = ({ row, id }) => (
  <GridButton row={row} id={id} icon={<VisibilityOutlined />} text={reportsEnum.REVIEW} />
);

/**
 * This object contains the column headers for the reports grid
 *
 * @returns {Object}
 */
export const GridColumns = Object.freeze([
  {
    field: reportsEnum.REPORT_NAME_FIELD,
    headerName: reportsEnum.REPORT_NAME_HEADER,
    editable: false,
    flex: 1,
    minWidth: 100,
  },
  {
    field: reportsEnum.PERFORMED_ON_FIELD,
    headerName: reportsEnum.PERFORMED_ON_HEADER,
    editable: false,
    flex: 1,
    minWidth: 100,
    valueFormatter: (params) => getDate(DateFormats.LOCAL, params.value),
  },
  {
    field: reportsEnum.COMPANY_FIELD,
    headerName: reportsEnum.COMPANY_HEADER,
    editable: false,
    flex: 1,
    minWidth: 100,
  },
  {
    field: reportsEnum.SITE_ID_FIELD,
    headerName: reportsEnum.LOCATION_HEADER,
    editable: false,
    flex: 1,
    minWidth: 100,
  },
  {
    field: reportsEnum.PERFORMED_BY_FIELD,
    headerName: reportsEnum.PERFORMED_BY_HEADER,
    editable: false,
    flex: 1,
    minWidth: 100,
    valueFormatter: (params) => params.value,
  },
  {
    field: reportsEnum.ACTIONS_FIELD,
    headerName: reportsEnum.ACTIONS_HEADER,
    editable: false,
    width: 120,
    renderCell: (params) =>
      params.row.finalized === false ? (
        <RenderEditButtons row={params.row} id={params.id} />
      ) : (
        <RenderReviewButtons row={params.row} id={params.id} />
      ),
  },
]);

export default GridColumns;
