import React from 'react';
import { Typography } from '@common-ui/ui';
import { useNavigate, useLocation } from 'react-router-dom';

/**
 * Each Tab is a link to the page it is associated with
 * The selected tab is highlighted in yellow
 * While the unselected tab is white
 *
 * @param {string} text - The text displayed in the tab
 * @param {string} path - The path the tab links to
 * @returns {JSX.Element} - A tab
 */

export default function Tab({ text, path }) {
  const navigate = useNavigate();
  let location = useLocation();

  const selected = () => location.pathname === path ?? false;

  const styles = {
    text: {
      ':hover': {
        cursor: 'pointer',
        color: 'black',
        background: 'var(--fluke-yellow)',
      },
      background: selected() ? 'var(--fluke-yellow)' : 'none',
      color: selected() ? 'black' : 'white',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '9em',
    },
  };

  // adding a button variant to keep the Tab styles consistent with the rest of the buttons
  // this also clean up our styles object a bit
  return (
    <Typography variant="button" sx={styles.text} onClick={() => navigate(path)}>
      {text}
    </Typography>
  );
}
