import { imageArchive } from '@fluke/baseline-client-apis';

/**
 * Fetch image data by\ url
 * @param {*} urls
 * @returns
 */
export async function getImageSrcByUrl({ url, name }) {
  if (!url) return;

  try {
    // Fetch the image for the specified URL
    const image = await imageArchive.fetchImageArchiveByUrl(url, name);
    return image; // Return the fetched image
  } catch (error) {
    console.error('Error fetching image:', error);
    throw error; // Rethrow the error for further handling
  }
}

/**
 * Fetches image data from url
 * @param {*} urls
 * @returns
 */
export async function getAllReportImages(urls) {
  try {
    const imagePromises = urls.map(getImageSrcByUrl);
    // Fetch images for each URL in parallel using getImageByUrl
    const images = await Promise.all(imagePromises);
    return images;
  } catch (error) {
    console.error('Error fetching images:', error);
    throw error; // Rethrow the error for further handling
  }
}
