import React from 'react';
import { Box, Tooltip, Typography } from '@common-ui/ui';

/**
 * ToolButton is a component that renders a button used in the Image Editor Toolbar.
 * Each button can have an icon and a label, and can respond to click events.
 * The button can also be styled based on its active or disabled state.
 *
 * @param {Object} param - The props passed into the component.
 * @param {string} param.id - The id of the button.
 * @param {string} param.tooltip - The tooltip text to display on hover.
 * @param {string} param.label - The label of the button.
 * @param {Function} param.Icon - The icon component to display in the button.
 * @param {Function} param.onClick - The function to execute when the button is clicked.
 * @param {boolean} param.active - The active state of the button.
 * @param {boolean} param.disabled - The disabled state of the button.
 *
 * @returns {JSX.Element} - The rendered button component.
 */

export default function ToolButton({ id, tooltip, label, Icon = '', onClick, active, disabled }) {
  const handleOnClick = () => {
    if (typeof onClick === 'function') onClick();
  };

  function ButtonLabel({ children, label }) {
    return (
      <div style={{ ...styles.column, width: label && '65px' }}>
        {children}
        {label && <Typography variant={'subtitle2'}>{label}</Typography>}
      </div>
    );
  }

  const styles = {
    button: {
      color: active ? 'primary.main' : '',
      height: '45px',
      width: '45px',
      margin: '8px',
      cursor: 'pointer',
      justifyContent: 'center',
      display: 'flex',
      alignItems: 'center',
      '&:hover': {
        outline: (theme) => `1px solid ${theme.palette.primary.main}`,
        color: 'primary.main',
      },
    },
    column: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  };

  return (
    <ButtonLabel label={label}>
      <Tooltip title={tooltip}>
        <span>
          <Box id={id} sx={styles.button} onClick={handleOnClick} disabled={disabled}>
            <Icon />
          </Box>
        </span>
      </Tooltip>
    </ButtonLabel>
  );
}
