import React, { createContext, useMemo } from 'react';
import { sessionValueInitTable } from '../enums/sessionKeyStoreEnum';

import useLocalStorage from './useLocalStorage';

const LOCAL_STORAGE_KEY = 'sessionData';

// Create the SessionStoreContext
export const SessionContext = createContext();

// Create the SessionStoreProvider component
export default function SessionProvider({ children }) {
  const { data, update, remove, fetchStorage, flush } = useLocalStorage(
    sessionValueInitTable,
    LOCAL_STORAGE_KEY,
  );

  const values = useMemo(
    () => ({
      fetchSessionStore: fetchStorage,
      sessionStore: data,
      updateSessionStore: update,
      deleteFromSessionStore: remove,
      flushSessionStore: flush,
    }),
    [fetchStorage, data, update, remove, flush],
  );

  return <SessionContext.Provider value={values}>{children}</SessionContext.Provider>;
}
