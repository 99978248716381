import React from 'react';
// mui -->
import { Button } from '@common-ui/ui';
import { AddCircleOutline } from '@mui/icons-material';

export default function AddNewAssetButton({ variant = 'primary', onClick = () => {}, ...rest }) {
  let label = variant === 'primary' ? 'Add Asset' : 'Add New Asset';
  return (
    <Button
      startIcon={<AddCircleOutline />}
      variant="contained"
      color="secondary"
      onClick={() => onClick?.()}
      {...rest}
    >
      {label}
    </Button>
  );
}
