import React, { useEffect, useState } from 'react';
import { Typography, Accordion, AccordionSummary, AccordionDetails } from '@common-ui/ui';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export function AccordionItem({ children, label = '', panelIndex, expandedPanels, onChange }) {
  const isExpanded = expandedPanels.includes(panelIndex);

  return (
    <Accordion expanded={isExpanded} onChange={onChange(panelIndex)}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} id={`panel${panelIndex}a-header`}>
        <Typography>{label}</Typography>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
}

export function AccordionGroup({ children }) {
  const [expandedPanels, setExpandedPanels] = useState([]);

  const handleAccordionChange = (panelIndex) => (event, isExpanded) => {
    if (isExpanded) {
      setExpandedPanels((prevExpandedPanels) => [...prevExpandedPanels, panelIndex]);
    } else {
      setExpandedPanels((prevExpandedPanels) =>
        prevExpandedPanels.filter((panel) => panel !== panelIndex),
      );
    }
  };

  useEffect(() => {
    const initialExpandedPanels = React.Children.toArray(children)
      .map((child, index) => (child.props?.expanded ? index : null))
      .filter((index) => index !== null);

    setExpandedPanels(initialExpandedPanels);
  }, []);

  return (
    <div>
      {React.Children.map(children, (child, index) =>
        React.cloneElement(child, {
          panelIndex: index,
          expandedPanels,
          onChange: handleAccordionChange,
        }),
      )}
    </div>
  );
}
