import { createSlice } from '@reduxjs/toolkit';
import { reportAPI } from '@fluke/baseline-client-apis';

import logger from '../../utils/logger';
import { imageAPI } from '@fluke/baseline-client-apis';
import { setSession } from './sessionSlice';
import { reportsEnum } from '../../enums/reportsEnum';

const reportSlice = createSlice({
  name: 'reportData',
  initialState: {
    selectedImagesForReport: [],
    reportInfo: {},
    allReports: {},
    draftReports: [],
    completedReports: [],
    session: {},
  },
  reducers: {
    deleteReportFromReportList: (state, { payload }) => {
      const allReportsIndex = state.allReports?.draft
        .map((report) => report.report_id)
        .indexOf(payload);
      if (allReportsIndex !== -1) state.allReports?.draft.splice(allReportsIndex, 1);

      const draftReportsIndex = state.draftReports
        .map((report) => report.report_id)
        .indexOf(payload);
      if (draftReportsIndex !== -1) state.draftReports.splice(draftReportsIndex, 1);
    },

    setReportInfo: (state, { payload }) => {
      state.reportInfo = payload;
    },

    setAllReports: (state, { payload }) => {
      state.allReports = payload;

      function dateComparator(v1, v2) {
        const dateA = new Date(v1);
        const dateB = new Date(v2);
        return dateA - dateB;
      }

      function sortReportsByDate(reports = [], field, descending = true) {
        return reports
          .slice()
          .sort((a, b) => dateComparator(a[field], b[field]) * (descending ? -1 : 1));
      }

      const sortedDrafts = sortReportsByDate(payload?.draft, reportsEnum.SORTED_BY_PROPERTY);
      const sortedFinalized = sortReportsByDate(payload?.finalized, reportsEnum.SORTED_BY_PROPERTY);

      state.draftReports = sortedDrafts ?? [];
      state.completedReports = sortedFinalized ?? [];
    },
  },
  extraReducers: (builder) => {
    // listens to updates from the session slice
    builder.addCase(setSession, (state, action) => {
      state.session = { ...state.session, ...action.payload };
    });
  },
});

export const { deleteReportFromReportList, setReportInfo, setAllReports } = reportSlice.actions;
export default reportSlice.reducer;

/**
 * @param { string } reportId
 */
export function deleteFromReportListByReportId(reportId) {
  return async (dispatch) => {
    try {
      dispatch(deleteReportFromReportList(reportId));
      if (reportId) {
        await reportAPI.deleteById(reportId);
      }
    } catch (error) {
      logger.error({ error, function: deleteFromReportListByReportId.name });
    }
  };
}

export function getAllReports() {
  return async (dispatch, getState) => {
    const { currentOrganization } = getState().reportData.session;

    const reports = (await reportAPI.fetchAllReports(currentOrganization)) ?? {};

    dispatch(setAllReports(reports));
  };
}

export function setActiveDraftReport(reportId) {
  return async (dispatch, getState) => {
    let reportInfo = null;
    try {
      const { currentOrganization } = getState().reportData.session;
      if (reportId) {
        reportInfo = await reportAPI.fetchReportById(reportId, currentOrganization);
        dispatch(setReportInfo(reportInfo));
      }
    } catch (error) {
      logger.error({ error, function: setActiveDraftReport.name });
    }

    return reportInfo;
  };
}

export function clearReportInfo() {
  return async (dispatch) => {
    dispatch(setReportInfo({}));
  };
}

export function storeReportInfo(reportInfo) {
  return async (dispatch, getState) => {
    try {
      if (reportInfo === undefined) {
        reportInfo = getState().reportData.reportInfo;
      }
      const { currentOrganization } = getState().reportData.session;
      const response = await reportAPI.saveReportInfo(reportInfo, currentOrganization);

      // TODO: conditional update to store upon response?
      dispatch(setReportInfo(reportInfo));
      dispatch(getAllReports());

      return response;
    } catch (error) {
      logger.error({ error, function: storeReportInfo.name });
      return error;
    }
  };
}

/**
 * This functions adds images to a report by creating a new reportInfo
 * object to be sent back to Redux
 *
 * @param {*} reportInfo The report metadata sent from the report modal
 * @param {*} imageList A list of images to be added to the report
 * @returns {*} The new reportInfo object
 */
export function addImagesToReport(reportInfo, imageList) {
  const { data: reportInfoData } = reportInfo;
  return async (_, getState) => {
    const { currentOrganization } = getState().reportData.session;
    let newReportInfo = { ...reportInfo };

    await imageAPI.fetchByMultipleIds(imageList, currentOrganization).then((data) => {
      data.forEach(({ image_id, sort_info, status }) => {
        if (!reportInfo.images.includes(image_id)) {
          newReportInfo = {
            ...newReportInfo,
            images: [...newReportInfo.images, image_id],
            image_options: {
              ...newReportInfo.image_options,
              [image_id]: {
                name: sort_info.origin_path || '',
                status: status || {},
                site_id: reportInfoData.site_id || '',

                // adding a default value if in the future we want to add equipment to the modal
                equipment: reportInfoData.equipment || '',
                // adding a default value if in the future we want to add recommendation to the modal
                recommendation: reportInfoData.recommendation || '',
              },
            },
          };
        }
      });
    });
    return newReportInfo;
  };
}
