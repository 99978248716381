import React from 'react';

// utilities
import { getInfoTable } from '@fluke/irviewer';

// components
import { TableContainer, Paper, Table, TableHead, TableRow, TableCell } from '@mui/material';

export default function IRViewerInfo({ imageInfo = {}, temperatureUnit = 'C' }) {
  const infoTabe = getInfoTable(imageInfo, temperatureUnit);

  return (
    <div className="irViewerInfoTableParent">
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 640 }} size="small">
          <TableHead>
            {infoTabe.map(({ label, value }, index) => (
              <TableRow key={`info-row-${index}-${label}`}>
                <TableCell align="left">{label}</TableCell>
                <TableCell align="right">{`${value}`}</TableCell>
              </TableRow>
            ))}
          </TableHead>
        </Table>
      </TableContainer>
    </div>
  );
}
