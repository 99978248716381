const deleteModalEnum = Object.freeze({
  REPORT_HEADER: 'Delete Report',
  REPORTS_HEADER: 'Delete Reports',
  REPORT_BODY: 'Are you sure you want to delete this report?',
  REPORTS_BODY: 'Are you sure you want to delete these reports?',
  REPORT_NOTIFICATION: 'Report Deleted',
  REPORTS_NOTIFICATION: 'Reports Deleted',

  IMAGE_HEADER: 'Delete Image',
  IMAGES_HEADER: 'Delete Images',
  IMAGE_BODY: 'Are you sure you want to delete this image?',
  IMAGES_BODY: 'Are you sure you want to delete these images?',
  IMAGE_NOTIFICATION: 'Image Deleted',
  IMAGES_NOTIFICATION: 'Images Deleted',

  MARKERS_HEADER: 'Delete Markers',
  MARKERS_BODY: 'Are you sure you want to delete these markers?',
  MARKERS_NOTIFICATION: 'Markers Deleted',
});

export default deleteModalEnum;
