import React, { useContext } from 'react';
import Year from './Year';
import { SessionContext } from '../../../context';

export default function Dates() {
  let { sessionStore } = useContext(SessionContext);
  let sidebarDates = sessionStore?.sidebarDates ?? [];
  

  if (!sidebarDates || sidebarDates.length === 0) {
    sidebarDates = [
      {
        count: 0,
        name: 'All Images',
      },
      { count: 0, name: 'Last Uploaded' }, // or any other appropriate fallback UI
    ];
  }

  return sidebarDates.map((year, index) => (
    <Year
      key={index}
      count={year.count}
      name={year.name}
      query={year.query}
      child={year.children || year.count}
    />
  ));
}
