import React, { useEffect, useMemo, useState } from 'react';

// utils
import { checkStatusObject, getStatusCodes } from '@fluke/irviewer';

// components
import { Box, MenuItem, TextField } from '@common-ui/ui';
import { Complete, Watch, Problem, Skip } from './StatusButtons';

const statusIcon = [
  { label: 'Complete', Icon: Complete },
  { label: 'Watch', Icon: Watch },
  { label: 'Problem', Icon: Problem },
  { label: 'Skip', Icon: Skip },
];

export default function Status({ value = {}, onChange = null }) {
  // const dispatch = useAppDispatch();

  // const { activeImageData } = useSelector((state) => state.images);

  // const dropdownRef = useRef(null);
  const [statusIndex, setStatusIndex] = useState(null);
  const [statusCode, setStatusCode] = useState(null);

  const handleUpdate = (event) => {
    const nextCode = parseInt(event.target.value);
    const nextStatus = checkStatusObject({ code: nextCode });

    onChange?.({ status: nextStatus });

    if (nextCode) {
      setStatusCode(nextCode);
    }
  };

  const handleButtonClick = (index) => {
    setStatusIndex(index + 1);

    // dropdownRef.current.focus();
  };

  const statusCodesList = useMemo(() => getStatusCodes(statusIndex), [statusIndex]);

  useEffect(() => {
    const { code = 0 } = value;
    setStatusIndex(Math.floor(code / 100));
    setStatusCode(code);
  }, [value]);

  return (
    <>
      <Box style={styles.statusRow}>
        {statusIcon.map(({ label, Icon }, index) => (
          <div key={`button-status-${index}-${label}`} style={styles.statusIconBox}>
            <Icon
              label={label}
              active={statusIndex === index + 1}
              onClick={() => handleButtonClick(index)}
            />
          </div>
        ))}
      </Box>

      {Boolean(statusIndex) && (
        <>
          <TextField
            // inputRef={dropdownRef}
            select
            label={'Select a reason'}
            placeholder={'Select a reason'}
            value={statusCode ?? 0}
            onChange={handleUpdate}
            style={{ width: '100%' }}
          >
            {statusCodesList.map(({ code, description }, index) => (
              <MenuItem key={index} value={code}>
                {description}
              </MenuItem>
            ))}
          </TextField>
        </>
      )}
    </>
  );
}

const styles = {
  statusRow: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  statusIconBox: {
    // this width also serves as a spacer (instead of a flex gap)
    width: '100px',
  },
};
