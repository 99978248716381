import React, { useCallback, useMemo } from 'react';

// components
import { MenuItem, TextField, Typography } from '@common-ui/ui';

// constants
const PALETTE_DEFAULT = {
  id: 'PALSCHEME_IRONBOW',
  label: 'Ironbow',
  background: 'linear-gradient(white, yellow, red, purple, black)',
};
const DISPLAY_DATA_DEFAULT = {
  paletteNames: [PALETTE_DEFAULT.id],
  palettePrettyNamesMap: { [PALETTE_DEFAULT.id]: PALETTE_DEFAULT.label },
  paletteAliasesMap: { [PALETTE_DEFAULT.id]: PALETTE_DEFAULT.id },
  paletteGradients: { [PALETTE_DEFAULT.id]: PALETTE_DEFAULT.background },
};

export default function IRImagePaletteDropDown({
  value = PALETTE_DEFAULT.id,
  paletteDisplayData = DISPLAY_DATA_DEFAULT,
  onChange = null,
}) {
  const handleChange = useCallback(
    (event) => {
      const nextValue = event.target.value;
      onChange?.({ paletteScheme: nextValue });
    },
    [onChange],
  );

  const validValue = useMemo(() => {
    if (value == null || !paletteDisplayData.paletteNames.includes(value))
      return PALETTE_DEFAULT.id;

    return value;
  }, [value]);

  const validDisplayData = useMemo(() => {
    if (paletteDisplayData == null) return DISPLAY_DATA_DEFAULT;

    const invalid = Object.values(paletteDisplayData).filter(
      (p) => p == null || Object.keys(p).length == 0,
    );
    if (invalid.length == 0) return paletteDisplayData;

    return DISPLAY_DATA_DEFAULT;
  }, [paletteDisplayData]);

  const sortedPaletteNames = useMemo(
    // Dependent upon default constructor value for paletteDisplayData
    () => {
      let prettyNames = validDisplayData?.palettePrettyNamesMap || {};
      let names = validDisplayData?.paletteNames || [];

      const keys = names;
      const values = keys.map((key, index) => ({
        i: index,
        value: prettyNames[key],
      }));

      const sortedByValue = values.sort((v1, v2) =>
        v1.value.toLowerCase().localeCompare(v2.value.toLowerCase()),
      );

      const sortedKeys = Object.values(sortedByValue).map((value) => keys[value.i]);

      return sortedKeys;
    },
    [validDisplayData],
  );

  const getBackgroundImage = useCallback(
    // Dependent upon default constructor value for paletteDisplayData
    (key) => {
      const { paletteAliasesMap, paletteGradients } = validDisplayData;
      const backgroundImage = paletteGradients[paletteAliasesMap[key]];

      return {
        backgroundImage: backgroundImage,
      };
    },
    [validDisplayData],
  );

  return (
    <TextField
      style={styles.palette}
      select
      label="Palette"
      value={validValue}
      defaultValue={0}
      onChange={handleChange}
      SelectProps={styles.palette}
    >
      {sortedPaletteNames.map((key, index) => (
        <MenuItem key={index} value={key}>
          <div style={styles.menuItem}>
            <Typography>{validDisplayData.palettePrettyNamesMap[key]}</Typography>
            <div style={{ ...styles.icon, ...getBackgroundImage(key) }} />
          </div>
        </MenuItem>
      ))}
    </TextField>
  );
}

const styles = {
  palette: {
    style: {
      maxHeight: '25em',
    },
  },
  icon: {
    border: '2px solid #d8d8d8',
    width: '2em',
    height: '2em',
    borderRadius: '50%',
  },
  menuItem: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
};
