// api
import { imageAPI } from '@fluke/baseline-client-apis';
import { handleImageSave } from '@fluke/irviewer';

/**
 * This function handles saving the group of images.
 *
 * @param {*} groupImageData the group of images to save
 * @param {*} currentOrganization the current organization
 */
export default async function handleSaveImages(groupImageData = {}, currentOrganization = '') {
  const saveCallback = (id, file) => {
    imageAPI
      .storeUpdatedThermalImage(
        id,
        {
          origin_path: groupImageData[id]?.sort_info?.origin_path ?? '',
          asset_field: groupImageData[id]?.asset_info?.assetTagId ?? '',
          is2u: new Blob([file], { type: 'application/octet-stream' }),
        },
        currentOrganization,
      )
      .catch((error) => console.error(`Failed to update thermal image for ID ${id}:`, error));
  };

  handleImageSave(groupImageData, saveCallback);
}
