import React, { useCallback, useMemo } from 'react';
import { ValidSettings, getPaletteScaleMode, isAutoScaleOn } from '@fluke/irviewer';
import { FlashAuto } from '@mui/icons-material';
import ToolButton from '../../../../../components/buttons/ToolButton';

export default function ToolAutoScale({ paletteScaleMode, onChange }) {
  const boolAutoScale = useMemo(() => isAutoScaleOn(paletteScaleMode), [paletteScaleMode]);

  const handleOnClick = useCallback(() => {
    const nextMode = getPaletteScaleMode(!boolAutoScale);
    onChange?.({ [ValidSettings.PALETTE_SCALE_MODE]: nextMode });
  }, [boolAutoScale]);

  return (
    <div style={styles.flexBox}>
      <ToolButton
        id={'tool-autoscale'}
        tooltip={`Autoscale ${boolAutoScale ? '(active)' : ''}`}
        label="Autoscale"
        onClick={handleOnClick}
        active={boolAutoScale}
        Icon={(props) => <FlashAuto {...props} />}
      />
    </div>
  );
}

const styles = {
  flexBox: {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
  },
};
