import React from 'react';
// mui -->
import { Box, ListItemText } from '@common-ui/ui';
import { Add } from '@mui/icons-material';
// components -->
import HierarchyIcon from './HierarchyIcon';
import EditableCell from '../EditTableCell';

/**
 * SidebarItem is a functional component that renders an item in a sidebar.
 * The item can be either a file or a folder. Folders can be expanded or collapsed
 * to show or hide their children respectively.
 *
 * The SidebarItem component provides functionality to rename the item. If the item
 * is in edit mode, an editable cell is displayed to allow changing the item's name.
 *
 * @param {string} [name=''] - The name of the item.
 * @param {boolean} [isFolder=true] - A flag indicating whether the item is a folder. If `true`, the item is a folder; otherwise, it's a file.
 * @param {boolean} [isOpen=false] - A flag indicating whether the folder is open. Only effective when `isFolder` is `true`.
 * @param {function} [onOpen=null] - A function to be called when the item is clicked. Only effective when `isFolder` is `true`.
 * @param {function} [onOpenSecondary=null] - A function to be called when the item's icon is clicked. Only effective when `isFolder` is `true`.
 * @param {boolean} [isEditActive=false] - A flag indicating whether the item is in edit mode.
 * @param {function} [onEditComplete=null] - A function to be called when renaming is complete. This function is called with the new name as an argument.
 * @param {function} [onAction=null] - A function to be called when the 'Add' button is clicked.
 *
 * @returns {JSX.Element} A JSX component rendering a sidebar item.
 */
export default function SidebarItem({
  name = '',
  isFolder = true,
  isOpen = false,
  isEditActive = false,
  onOpen = () => {},
  onOpenSecondary = () => {},
  onEditComplete = () => {},
  onAction = () => {},
}) {
  const styles = {
    item: {
      flex: 1,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'left',
      justifyContent: 'start',
      gap: '8px',
      // decorations -->
      borderLeft: isFolder ? `4px solid` : 'none',
      borderColor: isFolder && isOpen ? 'primary.main' : 'transparent',
      '&:hover': {
        borderColor: isFolder ? 'primary.main' : 'inherit',
        '& .addIcon': {
          // Apply the hover style to the child Add icon
          color: 'grey',
        },
      },
    },

    buttonAdd: {
      color: 'transparent',
      '&.addIcon': {
        // Add a class to the Add icon
        color: 'transparent',
      },
    },

    text: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  };

  return (
    <Box sx={styles.item}>
      <HierarchyIcon isFolder={isFolder} isOpen={isOpen} onClick={() => onOpenSecondary()} />
      <ListItemText sx={styles.text} onClick={() => isFolder && onOpen()}>
        <EditableCell
          params={{ value: name }}
          isEditActive={isEditActive}
          handleRename={onEditComplete}
          autocomplete={false}
        />
      </ListItemText>
      {!isEditActive && (
        <Add onClick={() => onAction()} sx={styles.buttonAdd} className="addIcon" />
      )}
    </Box>
  );
}
