import React from 'react';
// mui -->
import { Typography } from '@common-ui/ui';
import CableIcon from '@mui/icons-material/Cable';
// components -->
import AddNewAssetButton from '../Buttons/AddNewAsset';

/**
 * Default OVERLAY when there are no assets to display as rows
 * @returns {JSX.Element}
 */
export default function NoRowsOverlay({ onAddNewAsset = () => {} }) {
  return (
    <div style={styles.container}>
      <div style={styles.iconContainer}>
        <CableIcon color="secondary" sx={styles.icon} />
      </div>
      <Typography variant="h6" color="secondary" m={1}>
        You have not created any assets
      </Typography>
      <Typography variant="body1" color="secondary" m={1}>
        To create new assets, select add new asset
      </Typography>
      <AddNewAssetButton onClick={onAddNewAsset} style={styles.button} />
    </div>
  );
}

const styles = {
  container: { textAlign: 'center', marginTop: '4em' },
  iconContainer: {
    width: '5em',
    height: '5em',
    borderRadius: '50%',
    background: '#D4E9FF',
    margin: 'auto',
    display: 'flex',
  },
  icon: {
    width: '2em',
    height: '2em',
    alignSelf: 'center',
    margin: 'auto',
  },
  button: {
    zIndex: '100',
  },
};
