import React from 'react';

import { AbcRounded, Edit, NorthEast } from '@mui/icons-material';
import ButtonPopover from './ButtonPopover';
import { List, ListItemText, ListItemIcon, ListItemButton, Divider } from '@common-ui/ui';

/**
 * Button dropdown that displays the available Annotation tools for IRVIEWER
 *
 * @param {*} onClick - callback when dropdown selection is made
 *
 * @returns {JSX.Element} - Dropdown button for Annotation shapes
 */
export default function Annotations({ onClick = null }) {
  const handleMarkerClick = (nextTool) => {
    onClick?.(nextTool);
  };

  const annotationTools = [
    {
      id: 'annotation-Arrow',
      value: 'annotation-Arrow',
      label: 'Arrow',
      Icon: (props) => <NorthEast {...props} />,
      disabled: false,
    },
    {
      id: 'annotation-Text',
      value: 'annotation-Text',
      label: 'Textbox',
      Icon: (props) => <AbcRounded {...props} />,
      disabled: false,
    },
  ];

  return (
    <ButtonPopover label="Annotate" tooltip={'Annotations'} Icon={<Edit />}>
      <List dense>
        <ListItemButton
          sx={styles.button}
          key={`marker-tool-marker-done`}
          onClick={() => handleMarkerClick(null)}
        >
          <ListItemText primary={'Done'} />
        </ListItemButton>
        <Divider component="li" />
        {annotationTools.map(({ id, value, label, Icon }) => (
          <ListItemButton
            sx={styles.button}
            key={`marker-tool-${id}`}
            onClick={() => handleMarkerClick(value)}
          >
            <ListItemIcon>
              <Icon />
            </ListItemIcon>
            <ListItemText primary={label} />
          </ListItemButton>
        ))}
      </List>
    </ButtonPopover>
  );
}

const styles = {
  button: { '&:hover': { backgroundColor: 'primary.main', '> *': { color: 'black' } } },
};
