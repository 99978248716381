import React, { useEffect, useState } from 'react';

// enums
import { ValidSettings } from '@fluke/irviewer';

// components
import { Box, Paper, Typography } from '@common-ui/ui';
import ImageModal from '../Modals';
import { TextField } from '@mui/material';

function ImageWithHoverBorder({ src, ...rest }) {
  return (
    <Box
      src={src}
      component="img"
      sx={{
        border: '2px solid transparent', // Set initial border to transparent
        transition: 'border-color 0.3s ease', // Add transition effect to the border
        '&:hover': {
          borderColor: '#ffc20e', // Set the desired border color on hover
          transform: 'scale(1.1)', // Scale the image by 10% on hover
        },
      }}
      {...rest}
    />
  );
}

export default function PhotoNotes({ images = [], ocrText = [], onChange }) {
  const [selectedModalImage, setSelectedModalImage] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [localOCR, setLocalOCR] = useState(ocrText);

  useEffect(() => {
    // Update the state only if the arrays are different
    if (JSON.stringify(localOCR) !== JSON.stringify(ocrText)) {
      setLocalOCR(ocrText);
    }
  }, [ocrText]);

  const handleImageClick = (selectedImageIndex) => {
    setSelectedModalImage(selectedImageIndex);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    onChange?.({ [ValidSettings.OCR_TEXT]: localOCR });
    setModalOpen(false);
  };

  return images && images.length ? (
    <Box component={Paper} p={2} sx={styles.container}>
      <div style={styles.photnoteGrid}>
        {images.map((item, index) => (
          <div key={index}>
            <ImageWithHoverBorder
              key={index}
              src={item}
              loading="lazy"
              style={styles.image}
              onClick={() => handleImageClick(index)}
            />
            <TextField value={localOCR[index] || ''} disabled size="small" />
          </div>
        ))}
      </div>
      {images && (
        <ImageModal
          selectedImage={selectedModalImage}
          images={images}
          open={modalOpen}
          ocrText={localOCR}
          setOCRText={setLocalOCR}
          onClose={handleCloseModal}
        />
      )}
    </Box>
  ) : (
    <Typography component={Paper} p={2} sx={styles.photonotesText}>
      Photo notes do not exist for this image.
    </Typography>
  );
}

const styles = {
  container: {
    padding: '12px',
    margin: '10px 0',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    gap: 1,
  },
  photnoteGrid: {
    margin: '0 auto',
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridGap: '10px',
  },
  image: {
    width: '100%',
    maxWidth: '100%',
    height: 'auto',
    objectFit: 'cover',
    cursor: 'pointer',
  },
  photonotesText: {
    display: 'flex',
    justifyContent: 'center',
    padding: '5rem',
  },
};
