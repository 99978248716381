import { useEffect } from 'react';
import { useLocation } from 'react-router';

export const DocumentPaths = Object.freeze({
  REPORTS: 'reports',
  REPORT: 'report',
  IMAGE_EDITOR: 'imageeditor',
  IMAGES: 'images',
  ASSETS: 'assets',
});

export const DocumentTitles = Object.freeze({
  REPORTS: 'Reports | Baseline',
  REPORT: 'Report | Baseline',
  IMAGE_EDITOR: 'Image Editor | Baseline',
  IMAGES: 'Images | Baseline',
  ASSETS: 'Assets | Baseline',
  DEFAULT: 'Fluke | Baseline',
});

export const DocumentTitleMapper = {
  [DocumentPaths.REPORTS]: [DocumentTitles.REPORTS],
  [DocumentPaths.REPORT]: [DocumentTitles.REPORT],
  [DocumentPaths.IMAGE_EDITOR]: [DocumentTitles.IMAGE_EDITOR],
  [DocumentPaths.IMAGES]: [DocumentTitles.IMAGES],
  [DocumentPaths.ASSETS]: [DocumentTitles.ASSETS],
};

export default function useDocumentTitle() {
  const { pathname } = useLocation();
  const path = pathname.split('/')[1];
  useEffect(() => {
    document.title = DocumentTitles.DEFAULT;
    if (path in DocumentTitleMapper) {
      document.title = DocumentTitleMapper[path];
    }
  }, [pathname]);
}
