import React from 'react';
// enums
import { reportSectionKeys, tableMarkerKeys } from '../../enums';
// components
import { DataTable, SectionDiv, Typography } from '@fluke/predator-report-lib/lib/components';

export default function MarkerTable({ markers = [] }) {
  const config = {
    header: [
      tableMarkerKeys.MARKER,
      tableMarkerKeys.TEMPERATURE,
      tableMarkerKeys.DELTA,
      tableMarkerKeys.REFERENCE,
    ],

    rows: markers,
  };

  return (
    <SectionDiv>
      <Typography variant="section">{reportSectionKeys.MARKERS}</Typography>
      <DataTable data={config} />
    </SectionDiv>
  );
}
