import { createSlice } from '@reduxjs/toolkit';
import { setSession } from './sessionSlice';
import buildImagePreviews from '../../utils/buildImagePreviews';

export { default as fetchImageDataById } from '../services/_fetchImageDataById';
export { default as fetchImageIRData } from '../services/_fetchImageIRData';
export { default as fetchSidebarData } from '../services/_fetchSidebarData';
export { default as setSelectedImage } from '../services/_setSelectedImage';

const imagesSlice = createSlice({
  name: 'images',
  initialState: {
    selectedMonth: null,
    selectedYear: null,
    activeHeader: null,
    activeQuery: null,
    activeChild: null,
    isLoading: false,
    imageList: null,
    imagePreviews: {},
    activeImageData: {},
    selectedImageIRData: [],
    sidebarDates: [],
    selectedImages: [],
    selectedImageIds: JSON.parse(sessionStorage.getItem('selectedImageIds')) || [],
    wasImageUpdated: false,
    session: {},
    ocrText: null,
  },
  reducers: {
    setActiveSidebar: (state, { payload }) => {
      state.activeHeader = payload.name;
      state.activeQuery = payload.query;
      state.activeChild = payload.child;
      state.selectedYear = payload.year;
      state.selectedMonth = payload.month;
      state.searchId = String(payload.searchId);
      state.sidebarDates = JSON.stringify(payload.sidebarDates);
      state.ocrText = String(payload.ocrText);
    },
    setImageList: (state, { payload }) => {
      state.imageList = payload;
    },
    setImagePreviews: (state, { payload }) => {
      state.imagePreviews = payload;
    },
    setIsLoading: (state, { payload }) => {
      state.isLoading = payload;
    },
    setActiveImageData: (state, { payload }) => {
      state.activeImageData = payload;
    },
    addSelectedImageIRData: (state, { payload }) => {
      let view = new Uint8Array(payload);
      state.selectedImageIRData = view;
    },
    setSelectedImages: (state, { payload }) => {
      state.selectedImages = payload.data;
    },
    setSelectedImageIds: (state, { payload }) => {
      state.selectedImageIds = payload;
      sessionStorage.setItem('selectedImageIds', JSON.stringify(payload));
    },
    setStatusAndReason: (state, { payload }) => {
      state.activeImageData = payload;
    },
    clearSelectedImages: (state) => {
      state.selectedImageIds = [];
      sessionStorage.setItem('selectedImageIds', JSON.stringify([]));
    },
    setWasImageUpdated: (state, { payload }) => {
      state.wasImageUpdated = payload;
    },
  },
  extraReducers: (builder) => {
    // listens to updates from the session slice
    builder.addCase(setSession, (state, action) => {
      state.session = { ...state.session, ...action.payload };
    });
  },
});

export const {
  setActiveSidebar,
  setIsLoading,
  setImageList,
  setImagePreviews,
  setActiveImageData,
  addSelectedImageIRData,
  setSelectedImages,
  setSelectedImageIds,
  setStatusAndReason,
  clearSelectedImages,
  setWasImageUpdated,
} = imagesSlice.actions;

export default imagesSlice.reducer;

export function buildImagePreviewsObject(payload) {
  return async (dispatch) => {
    const previews = buildImagePreviews(payload);
    dispatch(setImagePreviews(previews));
  };
}

export function handleSelectedImageIds(payload) {
  return async (dispatch, getState) => {
    const { selectedImageIds } = getState().images;
    if (selectedImageIds.includes(payload)) {
      dispatch(setSelectedImageIds(selectedImageIds.filter((id) => id !== payload)));
    } else {
      dispatch(setSelectedImageIds([...selectedImageIds, payload]));
    }
  };
}
